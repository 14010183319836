import { Component, OnInit } from '@angular/core';
import { BillingService } from '../../../services-http/billing/billing.service';
import { FormBuilder,Validators } from '@angular/forms';
import { debounceTime } from "rxjs/operators";


@Component({
  selector: 'app-check-points',
  templateUrl: './check-points.component.html',
  styleUrls: ['./check-points.component.scss']
})
export class CheckPointsComponent implements OnInit {

  info:any;
  close_list:any=false;
  details:any=[];

  constructor(private bill:BillingService, private fb: FormBuilder,) { }

  checkpoints = this.fb.group({
    // login_typeid: ['',Validators.required],
    u: ['']
  });


  ngOnInit() {
    this.checkpoints.get("u").valueChanges.pipe(debounceTime(1500)).subscribe(x => {
      if(x){
        this.search();
      }
    });
  }

  
  search(){
    this.info=[];
    this.bill.checkPoints(this.checkpoints.value)
      .subscribe(res=>{
        this.info = res;

        this.close_list = true;
    });
  }

  detail(i:any){
    this.details=[]

    this.details.push(i);
    this.close_list=false;
  }
}
