import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services-http/auth/auth.service';
import { SweetAlertService } from '../../services-components/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { ModalDirective} from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-welcome-home',
  templateUrl: './welcome-home.component.html',
  styleUrls: ['./welcome-home.component.scss']
})
export class WelcomeHomeComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('basicModalReg') basicModalReg: ModalDirective;
  dialogIndex:any=0;

  users = JSON.parse(localStorage.getItem('currentUser'));

  // user = {};
  showPassword:boolean=false;

  redirect = false;
  recoveryEmail:any = new FormControl();
  usertype:any = new FormControl();
  replyOTP;
  OTP:number;
  lackform:any = "";
  searchListShow:boolean = false;
  coopInfo
  searchText:any;
  searchResult:any = [];
  searchitems:any={};
  optionsSelect:any = [];
  carousel:any=[];
  signUpNav:boolean=false;

 

  agentSelect = [
    { value: '1', label: 'CAC Agent/branch' },
    { value: '2', label: 'GAM Agent/branch' },
    { value: '4', label: 'Regular Coop Agent/branch' }
  ];

  createNewPasswordForm = new FormGroup({
    email: new FormControl(''),
    newPassword: new FormControl(''),
    confirmPassword: new FormControl(''),
    code: new FormControl(''),
  });

  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });


  validatingForm = new FormGroup({
    formRegisterCode               : new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    formRegisterUserType           : new FormControl('', [Validators.required]),
    formRegisterAgentType          : new FormControl(''),
    formRegisterMobileNum          : new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(11)] ),
    formRegisterAddress            : new FormControl('', [Validators.required]),
    formCompleteName               : new FormControl('', [Validators.required]),
    formFirstname                  : new FormControl('', [Validators.required]),
    formMiddlename                 : new FormControl('', [Validators.required]),
    formLastname                   : new FormControl('', [Validators.required]),
    formRegisterEmail              : new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")] ),
    formRegisterNewPassword        : new FormControl('', [Validators.required]),
    formRegisterConfirmNewPassword : new FormControl('', [Validators.required]),
    privacy                        : new FormControl(0),
    coop_branch                    : new FormControl('')
  });

 
  constructor(
    private authService: AuthService,
    private sweetAlertService: SweetAlertService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }


  ngOnInit() {

    this.validatingForm.get("formRegisterUserType").valueChanges.subscribe(x => {
      this.validatingForm.get("formFirstname").clearValidators();
      this.validatingForm.get("formMiddlename").clearValidators();
      this.validatingForm.get("formLastname").clearValidators();
      this.validatingForm.get("formRegisterAgentType").clearValidators();
      
      if(this.validatingForm.get('formRegisterCode').value!="" ){
        this.validatingForm.patchValue({formCompleteName: '',formRegisterCode: '',formRegisterAgentType:''  })
        this.closeSearchList();
        this.validatingForm.get("formRegisterCode").enable();
        this.validatingForm.get("formCompleteName").enable();
      }
      if(x==7||x==8||x==9||x==10||x==11||x==12||x==13||x==15){
        this.validatingForm.get("formRegisterCode").disable();
        this.validatingForm.get("formCompleteName").disable();

        this.validatingForm.patchValue({formRegisterCode: '3020' });
        this.validatingForm.patchValue({formCompleteName: 'CLIMBS LIFE AND GENERAL INSURANCE COOPERATIVE' });
      }
      if(x==5){
        this.validatingForm.get("formCompleteName").disable();
        this.validatingForm.get("formRegisterAgentType").setValidators([Validators.required]);
      }
      this.validatingForm.updateValueAndValidity();

    });

    this.validatingForm.get("formRegisterAgentType").valueChanges.subscribe(x => {
      if(this.validatingForm.get('formRegisterCode').value!="" ){
        this.validatingForm.patchValue({formCompleteName: '',formRegisterCode: '' })
        this.closeSearchList();
      }
    });

    this.validatingForm.get("formRegisterCode").valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      if(res){
        this.search();
      }
    });
   
    

    this.loginType();


    // this.authService.getCarousel().subscribe(res=>{
    //   this.carousel=res;
    // });



  }


  login() {
    this.authService.login(this.loginForm.value).subscribe(
      data => {
        this.spinner.hide();
        if (data['usertype'] == '1') {
          this.router.navigateByUrl('/admin/homepage');
        } else if (data['usertype'] == '3') {
          this.router.navigateByUrl('/gam/homepage');
        } else if (data['usertype'] == '4') {
          this.router.navigateByUrl('/cac/homepage');
        } else if (data['usertype'] == '5') {
          this.router.navigateByUrl('/agent/homepage');
        } else if (data['usertype'] == '6') {
          this.router.navigateByUrl('/coop/homepage');
        } else if (data['usertype'] == '7') {
          this.router.navigateByUrl('/endorsement-officer/homepage');
        } else if (data['usertype'] == '8') {
          this.router.navigateByUrl('/DQC-officer/homepage');
        } else if (data['usertype'] == '9') {
          this.router.navigateByUrl('/quotation-approval-officer/homepage');
        } else if (data['usertype'] == '10') {
          this.router.navigateByUrl('/cashin-approval-officer/homepage');
        } else if (data['usertype'] == '11') {
          this.router.navigateByUrl('/claims-officer/homepage');
        } else if (data['usertype'] == '12') {
          this.router.navigateByUrl('/user-management-officer/homepage');
        } else if (data['usertype'] == '13') {
          this.router.navigateByUrl('/renewalOfficer/homepage');
        } else if (data['usertype'] == '14') {
          this.router.navigateByUrl('/cashier/homepage');
        } else if (data['usertype'] == '15') {
          this.router.navigateByUrl('/reporter/homepage');
        } else if (data['usertype'] == '16') {
          this.router.navigateByUrl('/climbs-branch/homepage');
        } 
        else{
          alert("User type not available. Contact support!");
        }
      },error =>{         
        this.spinner.hide();
        let err=error.error;
        let msg='';
  
        Object.keys(err).forEach(function(key){
          msg+=err[key]+'<br/>';
        })
  
        Swal.fire(
          'Oppss!',
          msg,
          'error'
        );
      });
    this.spinner.show();
  }


  signUp(validate) {
    this.spinner.show();

    let data = {
      coop_maincode        :this.validatingForm.get('formRegisterCode').value,
      usertype             :this.validatingForm.get('formRegisterUserType').value,
      name                 :this.validatingForm.get('formCompleteName').value,
      username             :this.validatingForm.get('formRegisterEmail').value,
      password             :this.validatingForm.get('formRegisterNewPassword').value,
      password_confirmation:this.validatingForm.get('formRegisterConfirmNewPassword').value,
      contact_no           :this.validatingForm.get('formRegisterMobileNum').value,
      address              :this.validatingForm.get('formRegisterAddress').value,
      firstname            :this.validatingForm.get('formFirstname').value,
      middlename           :this.validatingForm.get('formMiddlename').value,
      lastname             :this.validatingForm.get('formLastname').value,
      agentType            :this.validatingForm.get('formRegisterAgentType').value,
      coop_branch          :this.validatingForm.get('coop_branch').value,
      login_id             :this.searchitems.login_id,
      Area                 :this.searchitems.Area
    }
      this.authService.signUp(data, validate).subscribe( res => {
        this.spinner.hide();

        if(validate==false){
          this.dialogIndex=0;
          this.validatingForm.reset();
          Swal.fire(
            'Success',
            res['msg'],
            'success'
          );
        }else if(validate==true) {
          this.replyOTP= res['msg'];
          this.dialogIndex=4
        }
        
      },error=>{
        this.spinner.hide();

        let err=error.error;
        let msg='';

        Object.keys(err).forEach(function(key){
          msg+=err[key]+'<br/>';
        })

        Swal.fire(
          'Oppss!',
          msg,
          'error'
        );
      });

  }




  forgotPasswordSend(){
    this.spinner.show();

    let recoveryEmail = {
      username: this.recoveryEmail.value,
      usertype: this.usertype.value,
      update:false
    }

    this.authService.forgotPasswordSend(recoveryEmail).subscribe(res =>{
      this.spinner.hide();
      
      this.createNewPasswordForm.get("email").disable();
      this.createNewPasswordForm.patchValue({email: this.recoveryEmail.value}); 
      this.dialogIndex=3;
      Swal.fire(
        'Info',
        res['msg'],
        'info'
      );
    },error=>{
      this.spinner.hide();
      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Oppss!',
        msg,
        'error'
      );
    });  
  }

  


  updatePassword(){
    this.spinner.show();

    let data = {
      username: this.createNewPasswordForm.get('email').value,
      password: this.createNewPasswordForm.get('newPassword').value,
      password_confirmation: this.createNewPasswordForm.get('confirmPassword').value,
      verification_code: this.createNewPasswordForm.get('code').value,
      usertype: this.usertype.value,
      update: true
    }

    this.authService.forgotPasswordSend(data).subscribe(res=>{
      this.spinner.hide();

      this.createNewPasswordForm.reset();
      this.dialogIndex=0;
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
      
    },error=>{
      this.spinner.hide();

      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Oppss',
        msg,
        'error'
      );
    });
     
  }

  
  errorMSG(){
    Swal.fire('Info','Wrong OTP number.!','error');
  }


  closeSearchList(){
    this.searchListShow = false;
  }

  searchItem(searhItem){
    this.searchitems =searhItem;
    this.validatingForm.patchValue({formCompleteName: this.searchitems.Name})
    this.searchListShow = false;
  }

  search(){
    this.spinner.show();

    this.authService.searchCoop({ coopNo :this.validatingForm.value.formRegisterCode, agentType:this.validatingForm.get('formRegisterAgentType').value }, this.validatingForm.value.formRegisterUserType ).subscribe(res => {
      this.searchResult=res;  
      this.spinner.hide();
      this.searchListShow=true;
    },error=>{
      this.spinner.hide();
    });
  }


  loginType(){
    this.authService.loginType().subscribe(res=>{
      this.optionsSelect=res;
    });
  }



}
