import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductionHistoryService {
  url = environment.url;

  constructor(private http: HttpClient) { }

  getHistory(filter ,iloginId){
    return this.http.post(this.url + '/getHistory/'+iloginId, filter ).pipe(map(res => {
      return res;
    }));
  }

  export(filter ,iloginId){
    return this.http.post(this.url + '/export/'+iloginId, filter ).pipe(map(res => {
      return res;
    }));
  }

  exportAdmin(filter ,iloginId){
    return this.http.post(this.url + '/exportAdmin/'+iloginId, filter ).pipe(map(res => {
      return res;
    }));
  }

  adminGetHistory(data){
    return this.http.post(this.url + '/api/getAdminHistory',data ).pipe(map(res => {
      return res;
    }));
  }


  above_retention_limit(data){
    return this.http.post(this.url + '/above_retention_limit',data ).pipe(map(res => {
      return res;
    }));
  }

  // navigatePage(link,data){
  //   return this.http.post(link,data ).pipe(map(res => {
  //     return res;
  //   }));
  // } 

  get_transaction(data){
    return this.http.post(this.url + '/get_transaction',data ).pipe(map(res => {
      return res;
    }));
  }

  send_otp(data){
    return this.http.post(this.url + '/send_otp',data  ).pipe(map(res => {
      return res;
    }));
  }

  submit_amount(data){
    return this.http.post(this.url + '/submit_amount',data  ).pipe(map(res => {
      return res;
    }));
  }

  get_points(data){
    return this.http.post(this.url + '/get_points',data  ).pipe(map(res => {
      return res;
    }));
  }

  get_agents(login_id){
    return this.http.get(this.url + '/get_agent_fund_transfer/'+login_id  ).pipe(map(res => {
      return res;
    }));
  }

  get_branch(data){
    return this.http.post(this.url + '/get_branch',data  ).pipe(map(res => {
      return res;
    }));
  }

  
  get_agents_coop(data){
    return this.http.post(this.url + '/api/getAgent',data  ).pipe(map(res => {
      return res;
    }));
  }

  getProductlist(data){
    return this.http.post(this.url + '/api/getProductlist',data ).pipe(map(res => {
      return res;
    }));
  }

  chartLabel(data, loginId){
    return this.http.post(this.url + '/chartLabel/'+loginId, data ).pipe(map(res => {
      return res;
    }));
  }

  chartData(data, loginId){
    return this.http.post(this.url + '/chartData/'+loginId, data ).pipe(map(res => {
      return res;
    }));
  }


  save_cashIn(cashIn){
    return this.http.post(this.url + '/save_cashIn', cashIn ).pipe(map(res => {
      return res;
    }));
  }

  get_channel_branch(value){
    return this.http.get(this.url + '/get_channel_branch/'+value ).pipe(map(res => {
      return res;
    }));
  }

  get_channel_type(){
    return this.http.get(this.url + '/get_channel_type' ).pipe(map(res => {
      return res;
    }));
  }

  cash_In_approval(data){
    return this.http.post(this.url + '/api/cash_In_approval',data).pipe(map(res => {
      return res;
    }));
  }


  credit_payment_approval(data){
    return this.http.post(this.url + '/api/credit_payment_approval',data).pipe(map(res => {
      return res;
    }));
  }


  approve_cashin( data){
    return this.http.post(this.url + '/approve_cashin',data ).pipe(map(res => {
      return res;
    }));
  }

  get_cashIn_pending(iloginId){
    return this.http.get(this.url + '/get_cashIn_pending/'+iloginId ).pipe(map(res => {
      return res;
    }));
  }

  
  get_branch_report(coop_no){
    return this.http.get(this.url + '/policyIssue/get_branch_report/' + coop_no);
  }


  endorsement(data){
    return this.http.post(this.url + '/policyIssue/endorsement', data);
  }


  searchPolicy(policy){
    return this.http.post(this.url + '/policyIssue/searchPolicy', policy);
  }

  save(formdata){
    return this.http.post(this.url + '/policyIssue/save' , formdata );
  }
  

  // cancel_endorsement(id){
  //   return this.http.get(this.url + '/policyIssue/cancel_endorsement/' +id );
  // }

  endorsementPrint(id){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    
    return this.http.get(this.url + '/endorsementPrint/' +id, httpOptions );
  }

  update(data, id){
    return this.http.post(this.url + '/policyIssue/update/' +id ,data );

  }

  // edit(id){
  //   return this.http.get(this.url + '/policyIssue/edit/' +id );
  // }

  email_endorsement(email, endorse_id){
    return this.http.get(this.url + '/policyIssue/email_endorsement?email='+email+'&endorse_id='+endorse_id );
  }


  cashInUsingRemittanceOrBank(data){
    return this.http.post(this.url + '/policyIssue/cashInUsingRemittanceOrBank' ,data );

  }

  display_cashIn_image(data){
    return this.http.post(this.url + '/api/display_cashIn_image',data );

  }


  getQuotationDetails(quoteId){
    return this.http.get(this.url + '/policyIssue/quotationDetails/'+quoteId );

  }


  disapprove_cashIn(data){
    return this.http.post(this.url + '/disapprove_cashIn',data);
  }


  getAgentProductionReport(filter ,iloginId){
    return this.http.post(this.url + '/getAgentProductionReport/'+iloginId, filter);
  }


  print_paid_bill(bill_id){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };

    return this.http.get(this.url + '/api/print_paid_bill/'+bill_id, httpOptions);

  }


  cashInReport(data){
    return this.http.post(this.url + '/api/cashInReport',data);
  }

  get_coop(data){
    return this.http.post(this.url + '/api/getCoop',data);

  }



}
