import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { CreateInsurtechAnnouncementService } from '../../../services-http/create-insurtech-announcement/create-insurtech-announcement.service'
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-create-insurtech-announcement',
  templateUrl: './create-insurtech-announcement.component.html',
  styleUrls: ['./create-insurtech-announcement.component.scss']
})
export class CreateInsurtechAnnouncementComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private announce:CreateInsurtechAnnouncementService,
    private spinner: NgxSpinnerService

    
    ) { }

  announceForm = this.fb.group({
    title: ['',Validators.required],
    description: ['',Validators.required],
    date_expire: ['',Validators.required],
  });

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };


  ngOnInit() {
  }

  createAnnouncement(){
    this.announce.createAnnouncement(this.announceForm.value).subscribe(res=>{
      // console.log(res)
      this.announceForm.reset();
      this.spinner.hide();
      Swal.fire(
        'Success',
        'Successfully sent announcement!',
        'success'
      );
    },error=>{
      this.spinner.hide();

      Swal.fire(
        'failed',
        'Something went wrong!',
        'error'
      );
    }
    )
    this.spinner.show();

    
  }

}
