import { Component, OnInit,ViewChild } from '@angular/core';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { ModalDirective } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { CacProductService } from '../../../services-http/cac-product/cac-product.service';


@Component({
  selector: 'app-above-retention-limit',
  templateUrl: './above-retention-limit.component.html',
  styleUrls: ['./above-retention-limit.component.scss']
})
export class AboveRetentionLimitComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('frame') frame: ModalDirective;


  history:any =[];
  data:any = [];
  searchText:any= '';
  email:any='';
  quoteId:any;

   //initialize date in filter list
   mydate = new Date();
   enddate = this.mydate.toISOString().substring(0,10);
   customize_date = new Date("2019-01-01");
   startdate = this.customize_date.toISOString().substring(0,10);
   export:any=false;  
   agents:any =[];
   productlist:any =[];
   policyno:any;
 
   filterForm = this.fb.group({
     date_from: [this.startdate,Validators.required],
     date_to  : [this.enddate,Validators.required],
     agent    : ['agent_login_id', Validators.required],
     product  : ['variantid', Validators.required]
   });

   public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };

  constructor(
    private prodhistory:ProductionHistoryService , 
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private issuedpolicy:IssuePolicyService,
    private cac:CacProductService
   ) { }

  ngOnInit() {

    this.above_retention_limit(1,false);
  }



  above_retention_limit(pageno,xport){
    var agent = this.filterForm.value.agent.split('/');
    var product = this.filterForm.value.product.split('/');

    this.spinner.show();
    let data = {
      date_from : this.filterForm.value.date_from,
      date_to   : this.filterForm.value.date_to,
      agent     : agent[0],
      product   : product[0],
      searchText: this.searchText,
      page      : pageno,
      export    : xport
    }

    this.prodhistory.above_retention_limit(data).subscribe(res=>{  
      this.spinner.hide();
      
      if(xport){
        window.open(''+res);
      }else{
        this.history = res;

      }
    },error=>{
    this.spinner.hide();
    });
  }


  downloadPolicy(value){
    this.spinner.show();
    let data = {
      quotId: value.quote_referenceid,
      email: 0,
      isDownload:1
    }

    this.cac.sendEmailPAdownload(data).subscribe((res:Blob)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "policy "+value.policyno+".pdf";
      link.click();
      
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Oppsss',
        'Something went wrong.',
        'error'
      );
    });
  }


  update_status(){
    this.spinner.show();

    this.cac.update_retension_status(this.policyno).subscribe((res)=>{
      this.spinner.hide();

      let index = this.history.findIndex(x=>x.policyno==this.policyno);

      this.history.splice(index, 1);

      this.frame.hide();

      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
    },
    
    error=>{
      this.spinner.hide();
      Swal.fire(
        'Oppsss',
        'Something went wrong.',
        'error'
      );
    });

  }

  sumhelper(value){
    let sum:any=0;

    value.forEach(row => {
      sum+= +row.premium;
    });
    return sum;
  }


 







}

