import { Component, OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { AuthService } from '../../../services-http/auth/auth.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NotificationService } from '../../../services-http/notification/notification.service'
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-cac',
  templateUrl: './cac.component.html',
  styleUrls: ['./cac.component.scss']
})
export class CacComponent implements OnInit, AfterViewInit {
  @ViewChild('frame') frame: ModalDirective;
  @ViewChild('basicModal') basicModal: ModalDirective;

  user:any = JSON.parse(localStorage.getItem('currentUser'));
  userType:any;
  coopName:any;
  envi = environment

  constructor(
    private authService: AuthService,
    private router: Router,
    public notification:NotificationService
  ) {
     
  }


  ngOnInit() {
    this.notification.getNotification(this.user.iloginId);

  }


  navigate(p){

    if(p.variantid==3){
      return this.router.navigateByUrl('/cac/policy-issuance/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==1){
      return this.router.navigateByUrl('/cac/standard-fire/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==2){
      return this.router.navigateByUrl('/cac/comprehensive/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==56||p.variantid==60){
      return this.router.navigateByUrl('/cac/fire-issue/'+p.variantid+'/quoteId/0/'+this.user.iloginId);
    }

    else if(p.variantid==69||p.variantid==70){
      return this.router.navigateByUrl('/cac/surity-bond/'+p.variantid+'/quoteId/0/'+this.user.iloginId);
    }
    


    this.router.navigateByUrl('/cac/CAC-products/'+p.variantid+'/quoteId/0/'+this.user.iloginId);

  }
  

  ngAfterViewInit() {

    if(this.user.checkunpayed){
      this.basicModal.show();
    }
  }


  logout() {
    this.authService.logout();
  }
  
   
  gotoProfileView() {
    this.router.navigateByUrl('/cac/cac-profile/' + this.user['userId'] + '/view');
  }

  notificationRead(notification){
    this.notification.notificationRead(notification).subscribe(res =>{
      this.notification.getNotification(this.user.iloginId);
    });
  }

  refresh(){
    this.notification.getNotification(this.user.iloginId);
  }

}
