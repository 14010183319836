import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})    
export class ProductService {
  url = environment.url;

  constructor(
    private http: HttpClient,
  ) { }

  // loadProducts() {
  //   return this.http.get(this.url + '/products');
  // }

  // loadProduct(id) {
  //   return this.http.get(this.url + '/products/' + id);
  // }

  // saveProduct(credentials) {
  //   return this.http.post(this.url + '/products', credentials);
  // }

  // updateProduct(id, credentials) {
  //   return this.http.put(this.url + '/products/' + id, credentials);
  // }

  // deleteProduct(id) {
  //   return this.http.delete(this.url + '/products/' + id);
  // }

  getVariants(){
    return this.http.get(this.url + '/product/getVariants');
  }

  updateProductStatus(data){
    return this.http.post(this.url + '/product/updateProductStatus',data);

  }


  
 
}
