import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment.prod'

@Component({
  selector: 'app-coop-faq',
  templateUrl: './coop-faq.component.html',
  styleUrls: ['./coop-faq.component.scss']
})
export class CoopFAQComponent implements OnInit {
  url = environment.url;

  constructor() { }

  ngOnInit() {
  }


}
