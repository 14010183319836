import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AboutUsService } from '../../../services-http/about-us/about-us.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-admin-contact-us',
  templateUrl: './admin-contact-us.component.html',
  styleUrls: ['./admin-contact-us.component.scss']
})
export class AdminContactUsComponent implements OnInit {

  selectControl = new FormControl('');
  name = new FormControl();
  email_to = new FormControl();
  subject = new FormControl();
  message = new FormControl();
  addresses:any =[];
  user = JSON.parse(localStorage.getItem('currentUser'));
  loading:boolean = false;



  local_landline:string = "local 106 – Human Resources and Administration Department <br> local 202 – Accounting Department <br> local 206 – IT Department <br> local 110 – Claims Department <br> local 124 – Northern Mindanao Area Office <br> local 108 – Marketing and Sales Division <br> local 207 – Non-Life Division <br> local 203 – Life Division "

  options = [
    { value: '1', office: 'Head Office', address: "CLIMBS Building, Upper Zone 5, National Highway Bulua, Cagayan de Oro City 9000", telefax: "(063) (88) 856-1355, (063) (8822) 738-738", email:"head_office@climbs.coop", local: this.local_landline },
    { value: '2', office: 'Luzon Branch Office', address: "5th-6th Floor, Units 501, 505, 604 & 605 EU State Tower 30 Quezon Avenue, Quezon City 1100", telefax: "(063) (2) 511-7078, (063) (2) 257-0388, (063) (2) 521-8792",email:"metro_manila@climbs.coop" },
    { value: '3', office: 'Baguio Area Office', address: "Room 402, Lyman Ogilby Centrum Magsaysay Avenue, Baguio City 2600", telefax: "(063) (74) 422-6720", email:"metro_baguio@climbs.coop" },
    { value: '4', office: 'Naga Area Office', address: "223 Ramaida Centrum, Elias Angeles St. Santa Cruz, Naga City 4400", telefax: "",email:"" },
    { value: '5', office: 'Cabanatuan Satellite Office', address: "2nd Floor, Room 3, Santarina Bldg. Maharlika Highway, Bernardo District, Cabanatuan City 3100, Cagayan de Oro City 9000", telefax: "",email:"" },
    { value: '6', office: 'Santiago Satillite Office', address: "No. 7 Purok 6, Maharlika Highway Brgy. Villasis, Santiago City, Isabela 3311", telefax: "",email:"" },
    { value: '7', office: 'Lucena Satellite Office', address: "186 Bonifacio Cor. Lacandual St. Brgy. VII, Lucena City, Quezon Province 4301", telefax: "",email:"" },
    { value: '8', office: 'Cebu Area Office', address: "6th Floor Cebu CFI Building Capitol Compound, Escario St., Cebu City 6000", telefax: "(063) (32) 255-2234",email:"metro_cebu@climbs.coop" },
    { value: '9', office: 'Iloilo Area Office', address: "Door 26, Ground Floor, Zerrudo Commercial Complex E. Lopez St., Jaro, Iloilo City 5000", telefax: "(063) (33) 320-0625",email:"metro_iloilo@climbs.coop" },
    { value: '10', office: 'Bacolod Satellite Office', address: "2nd Floo, Door 6, LCCFCMPC Bldg. Gatuslao St. Bacolod City 6100", telefax: "",email:"" },
    { value: '11', office: 'Dumaguete Satellite Office', address: "San Jose Extension, Taclobo Dumaguete City 6200", telefax: "",email:"" },
    { value: '12', office: 'Davao Area Office', address: "Door 27, A&B, CAM Building Monteverde-Alvarez Streets, Davao City 8000", telefax: " (063) (82) 284-6712, (063) (82) 305-1398",email:"metro_davao@climbs.coop" },
    { value: '13', office: 'Digos Satellite Office', address: "General Lim St., Digos City 8002", telefax: "",email:"" },
    { value: '14', office: 'Tagum Satellite Office', address: "Door 1, 2nd Floor, Tagum Coop Budgetel, Dalisay Road Tagum City, Davao del Norte 8100", telefax: "",email:"" },
    { value: '15', office: 'Southern Mindanao Extension Office', address: "Public Market, Pobalicion Maco Compestela Valley Province 8806", telefax: "",email:"" },
    { value: '16', office: 'General Santos Satellite Office', address: "Door 2, Lautengco Bldg. Roxas East, General Santos City City 9500", telefax: "",email:"" },
    { value: '17', office: 'Butuan Satellite Office', address: "Aguilar Bldg. Pili Drive, Butuan City", telefax: "",email:"" },
    { value: '18', office: 'Dipolog Satellite Office', address: "Purok Buli, Minaog, Dipolog City 7100", telefax: "",email:"" },

  ];

  constructor( 
    private aboutUs:AboutUsService
  ) { }


  ngOnInit() {
    this.subject.setValue("coop portal - " + this.user.name);
    this.email_to.setValue(this.addresses.email);
  }


  address_details(index){
    this.addresses = this.options[index];
    this.email_to.setValue(this.addresses.email);
    // this.name.setValue(this.addresses.name);
  }


  send_email(){
    this.loading = true;

    let data ={
      name       : this.name.value,
      email_to   : this.email_to.value,
      subject    : this.subject.value,
      message    : this.message.value,
      user_email : this.user.username,
      contact    : this.user.contact_no
    }

    this.aboutUs.send_email(data).subscribe(res =>{
      this.loading = false; 

      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
    },error=>{
      this.loading = false; 

      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })
  
      Swal.fire(
        'Error',
        msg,
        'error'
      );
    });
  }



  
}
