import { Component, OnInit } from '@angular/core';
import { ClaimsService } from '../../../services-http/claims/claims.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router,ActivatedRoute } from '@angular/router'



@Component({
  selector: 'app-coop-claims-monitoring',
  templateUrl: './coop-claims-monitoring.component.html',
  styleUrls: ['./coop-claims-monitoring.component.scss']
})
export class CoopClaimsMonitoringComponent implements OnInit {
  constructor(
    private claim:ClaimsService, 
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,

  ) { 

    this.route.parent.url.subscribe((urlPath) => {
      this.url = urlPath[urlPath.length - 1].path;
    });
  }
  claims:any = [];
  url:any;
  loading:boolean  = false;
  loadings:boolean = true;
  timeout:boolean  = false;
  searchRemittance:any;
  coopnum = JSON.parse(localStorage.getItem('currentUser'));
  downloadFile:any

  ngOnInit() {
    this.getClaims();
  }

  getClaims(){
    setTimeout(() => this.spinner.show(), 0);
    
    let coopnum = this.coopnum.usertype==1?'coop_code':this.coopnum.coop_maincode;

    this.claim.monitoringClaims(coopnum).subscribe(res => {
      this.claims = res;
      this.spinner.hide();

    },error=>{
      this.spinner.hide();

    })
  }

  filedownload(claims_id){
    this.claim.filedownload(claims_id).subscribe(res => {
      this.downloadFile = res

      for (let index = 0; index < this.downloadFile.length; index++) {
        window.open(this.downloadFile[index]);   
      }
    })
  }

  removeClaims(claims_id){
    let index = this.claims.findIndex(x => x.id === claims_id);
    this.spinner.show();

    this.claim.removeClaims(claims_id).subscribe(res => {

      this.spinner.hide();
      this.claims.splice(index,1);
      
    },error=>{
      this.spinner.hide();

    });
  }



}
