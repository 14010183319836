import { Component, OnInit } from '@angular/core';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { IbisaForcastService } from '../../../services-http/ibisa-forcast/ibisa-forcast.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-ibisa-forecast',
  templateUrl: './ibisa-forecast.component.html',
  styleUrls: ['./ibisa-forecast.component.scss']
})
export class IbisaForecastComponent implements OnInit {

  constructor(private ibisa:IbisaForcastService,private spinner: NgxSpinnerService,) { }

  filter = new FormGroup({
    id: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required),
  });

  province_id:any;
  provinces:any = [];
  chart = {
    labels: [],
    datasets: []
  }

  options = {
    responsive: false,
    maintainAspectRatio: false
  };

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };


  ngOnInit() {
    this.province();

    this.filter.get("id").valueChanges
      .subscribe(x => {
        if(x){
          this.province_id=x;
        }
    });
  }

  province(){
    this.ibisa.provinces().subscribe((res:any)=>{
      this.provinces=res;
    },error=>{
      Swal.fire(
        'Oppsss',
        'Something went wrong.',
        'error'
      );
    });
  }


  forcast_by_province(){
    this.spinner.show()
    this.ibisa.forcast_by_province(this.filter.value)
      .subscribe((res:any)=>{
        this.spinner.hide();

        if(res.length ==0){
          Swal.fire(
            'Oppsss',
            'No data found in this selection.',
            'error'
          );
          return
        }

        let data:any;
        let label:any=[]; 
        let val:any =[];
        let other:any=[];
        let o:any=[];
        let red:any=[];
        let y:any=[];
        let datachart:any=[];

        // other trigger warning
        let s_prov:any= this.provinces.find(x=>x.id == this.province_id).triggers[this.filter.value.type];

        // push percepatation warning
        res.forEach(r => {
          label.push([r.date]);

          val.push(r.value)
          o.push(s_prov.orange_trigger);
          red.push(s_prov.red_trigger);
          y.push(s_prov.yellow_trigger);

          data = {
            fill: false,
            borderColor: "#007bff",
            label: this.filter.value.type=='rain'? 'Percepation':'Wind Speed',
            data: val
          }
        });

        datachart.push(data);

        for (let i = 0; i < 3; i++) {
          other[i] = {
            fill: false,
            borderColor: i==0?"#fc9803" :i==1?"#fc0303": "#fcf003",
            label: i==0?"Orange Trigger" :i==1?"Red Trigger": "Yellow Trigger",
            data: i==0? o: i==1? red: y
          }
          datachart.push(other[i]);
        }
        
        this.chart = {
          labels: label,
          datasets: datachart
        }

    },error=>{
      Swal.fire(
        'Oppsss',
        'Something went wrong.',
        'error'
      );
    });
  }
   
    
  
}
