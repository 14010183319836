import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services-http/user/user.service';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-coop-api-user',
  templateUrl: './coop-api-user.component.html',
  styleUrls: ['./coop-api-user.component.scss']
})
export class CoopApiUserComponent implements OnInit {

  constructor(
    private user:UserService,
    private spinner: NgxSpinnerService
    ) { }

  data:any = [];

  ngOnInit() {
    setTimeout(() => this.spinner.show(), 0); 

    this.user.getCoopAPIUser().subscribe(res=>{
      this.data = res;
      this.spinner.hide()
    });
  }

  changeStatus(id, status){
    this.user.changeStatus(id, status).subscribe(res=>{
      let i = this.data.findIndex(x => x.id == id);
      // this.data[i].status = (status =="Active"? "Inactive": "Active" )
      this.data[i].status = res['data']['status'];
      this.data[i].dateUpdated = res['data']['dateUpdated']['date'];


      Swal.fire(
        'SUCCESS',
         res['msg'],
        'success'
      );
    },error=>{
      Swal.fire(
        'ERROR',
         "Something went wrong!",
        'error'
      );
    }
    );
  }

}
