import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service'
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-cac-production-report-chart',
  templateUrl: './cac-production-report-chart.component.html',
  styleUrls: ['./cac-production-report-chart.component.scss']
})
export class CacProductionReportChartComponent implements OnInit {

  constructor(
    private prodhistory:ProductionHistoryService, 
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
    
    ) { }

  mydate = new Date();
  enddate = this.mydate.toISOString().substring(0,10);
  customize_date = new Date("2019-01-01");
  startdate = this.customize_date.toISOString().substring(0,10);
  
  agents:any =[];
  productlist:any = [];


  filterForm = this.fb.group({
    date_from: [this.startdate,Validators.required],
    date_to  : [this.enddate,Validators.required],
    agent    : ['agent_login_id',Validators.required],
    product  : ['variantid',Validators.required]

  });

  public chartDatasets: any = [
    { data: ['0','0','0'], label: 'data sets' }
  ];

  public chartLabels:any = [];
  public chartColors;
  public chartOptions;
  users:any = JSON.parse(localStorage.getItem('currentUser'));

 
  
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  

  get_agents(){
    
    let data ={
      loginId: this.users.iloginId
    }
     
    this.prodhistory.get_agents_coop(data).subscribe(res=>{  
      this.agents = res;
    });
  }

  getProduct(){
   
    let data ={
      loginId: this.users.iloginId
    }
    this.prodhistory.getProductlist(data).subscribe(res=>{  
      this.productlist = res;
    });
  }
 

  chartLabel(data, loginId){

    this.prodhistory.chartLabel(data, loginId).subscribe(res=>{
      this.chartLabels = res;
      

    });
  }

  chartData(data, loginId){

    this.prodhistory.chartData(data, loginId).subscribe(res=>{
      this.chartDatasets = res;
      this.spinner.hide();

    });
  }

  generate(){
    this.spinner.show();

  
    var agent   = this.filterForm.value.agent.split('/');

    var product = this.filterForm.value.product.split('/');

    let data = {
      date_from : this.filterForm.value.date_from,
      date_to   : this.filterForm.value.date_to,
      agent     : agent[0],
      product   : product[0]
    }

    this.chartLabel(data , this.users.iloginId);
    this.chartData(data , this.users.iloginId);
  }


  reset(){

    this.filterForm.reset();
  }

  ngOnInit() {

    this.get_agents();
    this.getProduct();
   
  }

}
