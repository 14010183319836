import { Component, OnInit } from '@angular/core';
import { GroupClientService } from '../../../services-http/group-client/group-client.service';


@Component({
  selector: 'app-admin-cac-chart',
  templateUrl: './admin-cac-chart.component.html',
  styleUrls: ['./admin-cac-chart.component.scss']
})
export class AdminCacChartComponent implements OnInit {

  constructor(private groupClientService: GroupClientService) { }

  chartDatasets: Array<any> = [{ data: [], label: '2019' }, { data: [], label: '2018' }];
  chartDatasets2: Array<any> = [{ data: [], label: '2019' }, { data: [], label: '2018' }];
  

  chartLabels: Array<any> =[];
  chartLabels2: Array<any> = [];
  nonlifeProductsLine:any =[];
  lifeProductsLine:any =[];
  userData:any;
  show:boolean=true;

  public chartColors: Array<any> = [
    { backgroundColor: 'rgba(255, 99, 132, 0.2)',  borderColor: 'rgba(255, 99, 132, 1)'},
    { backgroundColor: 'rgba(105, 159, 177, 0.2)', borderColor: 'rgba(105, 159, 177, 1)'}
  ];

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('currentUser'));

    this.lifeProductLine();
    this.nonlifeProductLine();
   }



  lifeProductLine(){
    this.groupClientService.lifeProductLine( this.userData.coop_maincode).subscribe(res=>{
    
      this.lifeProductsLine = res;
          
      for (let i = 0; i < this.lifeProductsLine.length; i++) {
        this.chartLabels2.push(this.lifeProductsLine[i].productshortname);
        this.chartDatasets2[0].data.push(this.lifeProductsLine[i].year2019);
        this.chartDatasets2[1].data.push(this.lifeProductsLine[i].year2018);
      }
      console.log(this.chartLabels2)
      console.log(this.chartDatasets2[0])
      console.log(this.chartDatasets2[1])


    })
  }
    
  nonlifeProductLine(){
    this.groupClientService.nonlifeProductLine( this.userData.coop_maincode).subscribe(res=>{
     
      this.nonlifeProductsLine = res;
  
      for (let i = 0; i < this.nonlifeProductsLine.length; i++) {
        this.chartLabels.push(this.nonlifeProductsLine[i].line_name);
        this.chartDatasets[0].data.push(this.nonlifeProductsLine[i].year2019);
        this.chartDatasets[1].data.push(this.nonlifeProductsLine[i].year2018);
      }

      console.log(this.chartLabels)
      console.log(this.chartDatasets[0])
      console.log(this.chartDatasets[1])
    })
  }


  chartClicked(e: any): void { }

  chartHovered(e: any): void { }

  shows(val){

    if (val == 0) { this.show = false; }

    else{ this.show = true; }

  }

}
