import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class IbisaForcastService {
  url = environment.ibisaAPI; 


  constructor(private http: HttpClient) { }

  provinces(){
    let headers = new HttpHeaders({'X-API-KEY': environment.token_ibisa});

    return this.http.get(this.url + '/provinces',{headers});
  
  }

  forcast_by_province(data){
    let headers = new HttpHeaders({'X-API-KEY': environment.token_ibisa});

    return this.http.get(this.url + '/provinces/'+data.id+'/weatherConditions?type='+data.type+'&to='+data.to+'&from='+data.from+'',{headers});
  
  }
}
