import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentApprovalService {
  url = environment.url;
  constructor(private http: HttpClient) { }

  getAgentApproval(){
    return this.http.get(this.url + '/getAgentApproval');
  }

  action(approval, id){
    return this.http.post(this.url + '/action/'+id, approval);
  }

  coopAgentApproval(coopnum){
    return this.http.get(this.url + '/getCoopAgentApproval/'+coopnum);
  }

  // removeTokenApp(loginId){
  //   return this.http.get(this.url + '/agentApproval/logoutApp/'+loginId);

  // }

  // logout(loginId){
  //   return this.http.get(this.url + '/api/auth/logout/'+loginId);

  // }

}
