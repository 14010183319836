import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NotificationService } from '../../services-http/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    public notification: NotificationService
  ) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let users = JSON.parse(localStorage.getItem('currentUser'));
    let roles = route.data.userType;
    this.notification.getNotification(users.iloginId);

    if(roles == users.usertype ){
      return true;
    }

    this.router.navigateByUrl('/page-not-found');
    return false;
  }

}
