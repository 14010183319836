import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PcrService {
  url = environment.url;

  constructor(private http: HttpClient) { }

  getPcrDetailedReport(data){
    return this.http.post(this.url + '/getPcrDetailedReport', data).pipe(map(res => {
      return res;
    }));
    
  }

  getPcrCollectionReport(){
    return this.http.get(this.url + '/getPcrCollectionReport').pipe(map(res => {
      return res;
    }));
    
  }

  // getPcrTransactionReport(){
  //   return this.http.get(this.url + '/getPcrTransactionReport').pipe(map(res => {
  //     return res;
  //   }));
    
  // }

  getPcrReconReport(data){
    return this.http.post(this.url + '/getPcrReconReport', data).pipe(map(res => {
      return res;
    }));
    
  }

  // getPcrPaymentCenterOption(){
  //   return this.http.get(this.url + '/getPcrPaymentCenterOption').pipe(map(res => {
  //     return res;
  //   }));
    
  // }

  recon_now(data){
    return this.http.post(this.url + '/recon_now', data).pipe(map(res => {
      return res;
    }));
  }

  // print_recon(data){
  //   return this.http.post(this.url + '/print_recon', data).pipe(map(res => {
  //     return res;
  //   }));
  // }

  
  recon_details(data){
    return this.http.post(this.url + '/recon_details', data).pipe(map(res => {
      return res;
    }));
  }

  recon_print(data){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };

    return this.http.post(this.url + '/recon_details', data,httpOptions).pipe(map(res => {
      return res;
    }));
  }


 
}
