import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsertypeService {
  url = environment.url;

  constructor(
    private http: HttpClient,
  ) { }

  loadUserTypes() {
    return this.http.get(this.url + '/usertypes').pipe(map(res => {
      return res;
    }));
  }

  loadUserType(id) {

  }

  saveUserType(credentials) {

  }

  updateUserType(id, credentials) {

  }

  deleteUserType(id) {

  }
}
