import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url = environment.url;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  login(credentials) {
    return this.http.post(this.url + '/api/auth/login', credentials)
      .pipe(map(user => {
        if (user && user['access_token']) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          // this.router.navigateByUrl('/admin/dashboard')
        }
        return user;
      }));
  }

  signUp(credentials,validate) {
    return this.http.post(this.url + '/api/auth/signUp/'+validate, credentials)
      .pipe(map(user => {
        return user;
      }));
  }

  loginByVerificationCode(credentials) {
    return this.http.post(this.url + '/api/auth/loginViaVerificationCode', credentials)
      .pipe(map(user => {
        if (user && user['access_token']) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          // this.router.navigateByUrl('/admin/dashboard')
        }
        return user;
      }));
  }

  logout() {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      this.http.get(this.url + '/api/auth/logout/'+user.userId).subscribe(res=>{
        localStorage.removeItem('currentUser');
        this.router.navigateByUrl('/welcome/home');
      });
      
  }

  forgotPasswordSend(recoveryEmail){
    return this.http.post(this.url + '/api/auth/forgotPassword', recoveryEmail);
  }

  // updatePassword(data){
  //   return this.http.post(this.url + '/api/auth/updatePassword',data)
  // }

  send_otp(data){
    return this.http.post(this.url + '/api/auth/send_otp', data)
  }

  searchCoop(search, coopCode){
    return this.http.post(this.url + '/api/search/'+ coopCode, search )
  }


  loginType(){
    return this.http.get(this.url + '/api/loginType');
  }

  getCarousel(){
    return this.http.get(this.url + '/api/getCarousel');
  }

  groupaccess(){
    return this.http.get(this.url + '/groupAccess');
  }

  addupdateGroupAccess(data, usertype){
    return this.http.post(this.url + '/addupdateGroupAccess/'+ usertype, data);
  }

}
