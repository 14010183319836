import { Component,ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuotationService } from '../../../services-http/quotation/quotation.service';
import { ModalDirective  } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CacProductService} from '../../../services-http/cac-product/cac-product.service';
 

@Component({
  selector: 'app-admin-quotation-approval',
  templateUrl: './admin-quotation-approval.component.html',
  styleUrls: ['./admin-quotation-approval.component.scss']
})
export class AdminQuotationApprovalComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;

  monthSelected:any='MONTH(createdDate)';
  searchText:any=''
  user = JSON.parse(localStorage.getItem('currentUser'));
  reps: any = [];
  data:any= [];
  id:any;
  mydate = new Date();
  pending_details:any = [];
  url:any='';
  urls=environment.url;
  furl;
  yearSelected:any = '';

  

  optionsSelect:any = [
    { value: '2,7,4,10,5,6', label: 'Select all status' },
    { value: '3', label: 'Pending' },
    { value: '7', label: 'Issued'},
    { value: '4,10', label: 'Approved'},
    { value: '5', label: 'Disapproved'},
    { value: '6', label: 'Canceled'},
    { value: '11', label: 'Pending for Review'},
  ];


  selected:any='2,7,4,10,5,6';

  monthOption:any = [
    { value: 'MONTH(createdDate)', label: 'All month' } ,
    { value: '1', label: 'January' } ,
    { value: '2', label: 'February' } ,
    { value: '3', label: 'March' } ,
    { value: '4', label: 'April' } ,
    { value: '5', label: 'May' } ,
    { value: '6', label: 'June' } ,
    { value: '7', label: 'July' } ,
    { value: '8', label: 'August' } ,
    { value: '9', label: 'September' } ,
    { value: '10', label: 'October' } ,
    { value: '11', label: 'November' } ,
    { value: '12', label: 'December' }
  ];

  yearOption:any = [
    { value:'', label:'All year' },
    { value:'2019', label:'2019' },
    { value:'2020', label:'2020' },
    { value:'2021', label:'2021' },
    { value:'2022', label:'2022' },
    { value:'2023', label:'2023' },
    { value:'2024', label:'2024' },
    { value:'2025', label:'2025' },
    { value:'2026', label:'2026' },
    { value:'2027', label:'2027' }


  ]

  searchForm = new FormGroup({
    search: new FormControl('')
  });


  constructor(
    private quotation: QuotationService, 
    private router: Router, 
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private cac:CacProductService
   ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.url = urlPath[urlPath.length - 1].path;
      console.log(this.url)
    });
  }
  

  ngOnInit(){
    this.pending_quotation(1, false);


    this.searchForm.get('search').valueChanges.pipe(debounceTime(2000)).subscribe(res => {
   
      this.pending_quotation(1, false);
    
  });
  }


  pending_quotation(pageno, exports ) {

    let data={
      page: pageno,
      status: this.selected.split(','),
      search: this.searchForm.value.search==''?'quotationno':this.searchForm.value.search,
      month: this.monthSelected,
      year: this.yearSelected,
      export: exports
    }
    
    this.spinner.show();
    this.quotation.pending_quotation(data)
      .subscribe((res:any) => {
      this.spinner.hide();

      if(exports){
        window.open(res);

        return;
      }  
        
     
      this.reps=res;
    },error=>{
      this.spinner.hide();
    });
  }


  // details(data){
  //   this.pending_details = data
  //   this.id = data['id'];
  //   this.router.navigateByUrl('/'+this.url+'/quotation-details/' + data['id']);
  // }


  // approvedDisapproveDetails(data){
  //   this.router.navigateByUrl('/'+this.url+'/quotation-details/' + data );
  // }


  async pendingQuote(quoteId){
    const { value: accept } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder:'Type your message here!',
      confirmButtonText:'Continue <i class="fa fa-arrow-right"></i>',
      inputValidator: (result) => {
        return !result && 'You need to input remarks'
      }
    });
    
    if (accept) {
      this.quotation.pendingQuote(quoteId,accept )
        .subscribe(res => {
   
        // this.reps.splice(this.reps.findIndex(x => x.id===quoteId), 1);
        this.pending_quotation(1,false);
        Swal.fire(
          'Success',
          res['msg'],
          'success'    
        );    
      },error=>{
        Swal.fire(
          'error',
          'Something went wrong!',
          'error'
        );
      });
    }
  }

  


  async cancelQuote(quoteId ){
    const { value: accept } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder:'Type your message here!',
      confirmButtonText:'Continue <i class="fa fa-arrow-right"></i>',
      inputValidator: (result) => {
        return !result && 'You need to input remarks'
      }
    });
    
    if (accept) {
      this.quotation.cancelQuote(quoteId,accept )
        .subscribe(res => {
        // this.reps.splice(this.reps.findIndex(x => x.id===quoteId), 1);
        this.pending_quotation(1,false);
        Swal.fire(
          'Success',
          res['msg'],
          'success'    
        );    
      },error=>{
        Swal.fire(
          'error',
          'Something went wrong!',
          'error'
        );
      });
    }
  }


  quotationPDF(quoteId){
    this.spinner.show();
    
    this.quotation.quotationPDF(quoteId)
    .subscribe((res:Blob) => {
     
      this.spinner.hide();
      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "quotationCopy.pdf";
      link.click();
    },error=>{
      this.spinner.hide();
    });
  }


  editQuote(quoteId, variantId){
    if(variantId == 1){
      this.router.navigateByUrl('/'+this.url+'/update-standard-fire/' +quoteId );
    }else if(variantId == 2){
      this.router.navigateByUrl('/'+this.url+'/update-comprehensive/' +quoteId  );
    }
  }


  getCoverage(data){
    if(data.variantid==2){
      return (+data.accessories.map((item)=>Number.parseInt(item.coverage)).reduce((acc, curr) => acc + curr, 0)) + (+data.vehicle.carValue.replace(',',''));
      
    }else if(data.variantid==1){
      return (+data.content.map((item)=>Number.parseInt(item.coverage)).reduce((acc, curr) => acc + curr, 0)) + (+data.building.buildingCoverage.toString().replace(/\,/g,''));
    }
  }

  download(id){
    this.quotation.download(id).subscribe(res => {  
      
      window.open(res['url'],'_blank').focus();
    },error=>{
      alert('Something went wrong.');
    }); 
  }


  approve(i,quotationno,quoteId){  
     
    Swal.mixin({
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload your profile picture'
      },
      // inputValidator: (value) => {
      //   if (!value) {
      //     return 'You need to write something!'
      //   }
      // },
      confirmButtonText: 'Next →',
      showCancelButton: true,
      progressSteps: ['1']
    }).queue([
      {
        title: 'Proof of Approval (optional)',
        // text: 'Upload proof of approval.'
      }

    ]).then((result) => {

      if (result.value[0]== null) {

        Swal.fire({
          title: 'No uploaded proof of attachment.',
          showCancelButton  : true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Approve now?',
        }).then((results) => {
      
            if (results.value) {
           
              let formData = new FormData();
              // formData.append('file',result.value[0], 'proof of approval');

              this.quotation.approval(formData, quoteId).subscribe((res:any) => {
                
                this.pending_quotation(1,false);
      
                Swal.fire(
                  'Success',
                   res.msg+' '+quotationno,
                  'success'
                );
              }); 
            }
          });
        return;
      }

        const reader = new FileReader()
        reader.onload = (e:any) => {
          Swal.fire({
            title: 'Your uploaded proof of approval.',
            imageUrl: e.target.result,
            imageAlt: 'The uploaded picture',
            showCancelButton  : true,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Approve now?',
          }).then((results) => {
              if (results.value) {

                console.log(result.value)

                let formData = new FormData();
                formData.set('file',result.value[0], 'proof_of_approval');
  
                this.quotation.approval(formData ,quoteId).subscribe((res:any) => {
                  
                  this.pending_quotation(1,false);
        
                  Swal.fire(
                    'Success',
                     res.msg+' '+quotationno,
                    'success'
                  );
                }); 
              }
            });
        }
        reader.readAsDataURL(result.value[0]);
      
    });
  }


  renewPolicy(policy){

    if(policy.variantid==56||policy.variantid==60){
      return this.router.navigate(['/'+this.url+'/fire-issue/'+policy.variantid+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    else if(policy.variantid==69 || policy.variantid==70){
      return this.router.navigate(['/'+this.url+'/surity-bond/'+policy.variantid+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    else if(policy.variantid==3){
      return this.router.navigate(['/'+this.url+'/policy-issuance'+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    else if(policy.variantid==2){
      return this.router.navigate(['/'+this.url+'/comprehensive'+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    else if(policy.variantid==1){
      return this.router.navigate(['/'+this.url+'/standard-fire'+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    this.router.navigate(['/'+this.url+'/CAC-products/'+policy.variantid+'/quoteId/'+policy.id+'/'+policy.agentNo]); 
  }



  downloadPolicy(quoteId){
    this.spinner.show();
    let data = {
      quotId: quoteId,
      email: 0,
      isDownload:1
    }

    this.cac.sendEmailPAdownload(data).subscribe((res:Blob)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "policy.pdf";
      link.click();
      this.spinner.hide();
      
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Oppsss',
        'Something went wrong.',
        'error'
      );
    });
  }







}
