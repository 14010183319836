import { Component, OnInit,ViewChild } from '@angular/core';
import { AuthService } from '../../../services-http/auth/auth.service';
import { UserService } from '../../../services-http/user/user.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NotificationService } from '../../../services-http/notification/notification.service'
import {environment} from '../../../../environments/environment';



@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss']
})
export class CashierComponent implements OnInit {
  @ViewChild('frame') frame: ModalDirective;

  user:any = JSON.parse(localStorage.getItem('currentUser'));
  userType:any;
  coopName:any;
  envi = environment

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService:UserService,
    public notification:NotificationService
  ) { }


  ngOnInit() {
    this.refresh();
  }


  logout() {
    this.authService.logout();
  }
  
   
  gotoProfileView() {
    this.router.navigateByUrl('/cashier/cac-profile/' + this.user['userId'] + '/view');
  }


  notificationRead(notification){
    this.notification.notificationRead(notification).subscribe(res =>{
      this.notification.getNotification(this.user.iloginId);
    });
  }

  refresh(){
    this.notification.getNotification(this.user.iloginId);
  }
}
