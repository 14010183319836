import { Component, OnInit,EventEmitter } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { ClaimsService } from '../../../services-http/claims/claims.service'
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';
import  Swal from 'sweetalert2';
import { Router,ActivatedRoute } from '@angular/router'
import { DatePipe } from '@angular/common'
import { debounceTime } from "rxjs/operators";


@Component({
  selector: 'app-coop-claims-submission',
  templateUrl: './coop-claims-submission.component.html',
  styleUrls: ['./coop-claims-submission.component.scss']
})
export class CoopClaimsSubmissionComponent implements OnInit {
  // file uploading
  formData     : FormData;
  files        : UploadFile[];
  uploadInput  : EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver     : boolean;

  claims_requirements:any=[]
  show:any =null;
  url:any;
  user:any = JSON.parse(localStorage.getItem('currentUser'));

  claimsInfo = this.fb.group({
    policyNo        :[''],
    product_type    :['',Validators.required],
    assuredName     :['',Validators.required],
    contactNo       :['',Validators.required],
    email           :['',Validators.required],
    address         :['',Validators.required],
    locationOfLoss  :[''],
    factsOfAccident :[''],
    diagnose        :[''],
    natureOfLoss    :[''],
    remarks         :[''],
  });

  constructor(
    private claims:ClaimsService,
    private fb: FormBuilder,
    private router: Router, 
    private datepipe: DatePipe,
    private route: ActivatedRoute
  ) { 
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;

    this.route.parent.url.subscribe((urlPath) => {
      this.url = urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit() {
    this.claimsInfo.get("policyNo").valueChanges.pipe(debounceTime(1000)).subscribe(x => {
      this.searchPolicyNum();
    });
  }

  submitClaims(){
    const files = new FormData();
   
    let data = {
      policyNo        :this.claimsInfo.value.policyNo,
      product_type    :this.claimsInfo.value.product_type,
      assuredName     :this.claimsInfo.value.assuredName,
      contactNo       :this.claimsInfo.value.contactNo ,
      email           :this.claimsInfo.value.email ,
      address         :this.claimsInfo.value.address,
      locationOfLoss  :this.claimsInfo.value.locationOfLoss,
      factsOfAccident :this.claimsInfo.value.factsOfAccident,
      diagnose        :this.claimsInfo.value.diagnose ,
      natureOfLoss    :this.claimsInfo.value.natureOfLoss,
      remarks         :this.claimsInfo.value.remarks,
      dateCreated     :this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
      coop_code       :this.user.coop_maincode,
      loginId         :this.user.iloginId
    }

    this.claims.submitClaims(data).subscribe(claims_id => {
      
      for(let index = 0; index < this.files.length; index++) {
        files.append('file', this.files[index].nativeFile); 
        this.claims.upload_files(files,claims_id).subscribe(res=>{
        
          this.router.navigate(['/cac/coop-claims-monitoring']);

          Swal.fire(
            'Alert',
            'Successfuly submit claims.',
            'success'
          );
        });
      }
    },error=>{
      Swal.fire(
        'Failed',
        'Something went wrong',
        'error'
      );
    });
  }

  // on uploading
  showFiles() {
    let files = '';
    for (let i = 0; i < this.files.length; i ++) {
      files += this.files[i].name;
      if (!(this.files.length - 1 === i)) {
         files += ',';
      }
    }
    return files;
  }

  // on uploading
  onUploadOutput(output: UploadOutput | any): void {
    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue') {
      // add file to array when added
      this.files.push(output.file); 
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
    this.showFiles();
  }


  searchPolicyNum(){
    this.claims.searchPolicyNum(this.claimsInfo.value.policyNo).subscribe((res:any) => {

      this.claimsInfo.patchValue({
        assuredName:res.assuredName,
        email:res.email,
        address:res.address,
        contactNo:res.contactNo
      });
      this.claims_requirements = res.requirement;

      this.show = this.claims_requirements.length==0?'0':'1';
    },error=>{
      let err = error.error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
      msg+=err[key];
      });

      Swal.fire(
        'Alert',
        msg,
        'error'
      );
    });
  }

}
