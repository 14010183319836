import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommissionPremiumSetupService {
  url = environment.url;

  constructor( private http: HttpClient) { }



  // finall commission
  displayCom(data){
    return this.http.post(this.url + '/commSetup/displayCom',data);
  }


  saveCom(data){
    return this.http.post(this.url + '/commSetup/saveCom', data);
  }

  delete_com(com_id){
    return this.http.get(this.url + '/commSetup/deleteCom/'+com_id);
  }


  create_comm(data){
    return this.http.post(this.url + '/commSetup/createComm',data);
  }

  get_Com_benefits(variant_id){
    return this.http.get(this.url + '/commSetup/get_Com_benefits/'+variant_id);
  }


  getSpecificCom(data){
    return this.http.post(this.url + '/getSpecificCom',data);

  }

  comPerProducts(data){
    return this.http.post(this.url + '/comPerProducts',data);


  }
  
}
