import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../../services-http/product/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.scss']
})
export class ProductManagementComponent implements OnInit {

  constructor(
    private prod:ProductService,
    private spinner: NgxSpinnerService
  ) { }

  product:any = [];

  ngOnInit() {
    this.getProduct();
  }

  getProduct(){
    setTimeout(() => this.spinner.show(), 0); 

    this.prod.getVariants().subscribe(res=>{
      this.product =res;
      this.spinner.hide()

    },error=>{
      this.spinner.hide()

      Swal.fire(
        'Oopps!',
        'Something went wrong.',
        'error'
      );
    });
  }


  update(val){
    this.spinner.show()

    this.prod.updateProductStatus(val).subscribe(res=>{
      this.spinner.hide()

      Swal.fire(
        'Success!',
        res['msg'],
        'success'
      );
    },error=>{
      this.spinner.hide()

      Swal.fire(
        'Oopps!',
        'Something went wrong.',
        'error'
      );
    });
  }

}
