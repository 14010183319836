import { Component, OnInit, ViewChild  } from '@angular/core';
import { PcrService } from '../../../services-http/pcr/pcr.service';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ModalDirective } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-pcr-detailed-collection-report',
  templateUrl: './pcr-detailed-collection-report.component.html',
  styleUrls: ['./pcr-detailed-collection-report.component.scss']
})
export class PcrDetailedCollectionReportComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  
  constructor(
    private PCR:PcrService, 
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  url:any = environment.url;
  reconReport:any = [];
  detailedReport:any = [];
  mydate = new Date();
  enddate = this.mydate.toISOString().substring(0,10);
  customize_date = new Date("2019-01-01");
  startdate = this.customize_date.toISOString().substring(0,10);
  payment_center:any = [];
  transaction_date:any;
  list:any = {};
  _id:any;
  date_deposit = this.enddate;
  math = Math
  actual_amount_deposited:number;
  total:any=0;
  recon:any = {}
  companycode:any=0;

  formdata = this.fb.group({
    paycenter : ['TRUEMONEY',Validators.required],
    from: [this.startdate,Validators.required],
    to  : [this.enddate,Validators.required]
  });

  
  ngOnInit() { 
    this.getPcrDetailedReport();
  }

  getPcrDetailedReport(){
    
    this.spinner.show();
    this.PCR.getPcrDetailedReport(this.formdata.value).subscribe((res:any)=> {
      this.detailedReport = res;
      this.spinner.hide();
    });
  }


  getPcrReconReport(date,id){
    this._id = id;
    this.transaction_date = date;

    let data = {
      date : this.transaction_date,
      payment_center : this.formdata.value.paycenter
    }

    this.PCR.getPcrReconReport(data).subscribe(res=> {
      this.reconReport = res;
      this.list = this.reconReport.list[0];
    });
  }


  showmodal(trans_creation,id, actual_collection){
    if(actual_collection == null){
      Swal.fire(
        'No payment',
        'there is no actual collection amount in this Transaction Date ' +trans_creation+ ' OR you might forgot to click the search button',
        'error'
      );
    }

    else{
      this.getPcrReconReport(trans_creation,id)
      this.basicModal.show();
    }
  }


  recon_now(){
    let dates={
      transaction_date: this.transaction_date,
      actual_deposited_date: this.date_deposit,
      actual_amount_deposited :this.actual_amount_deposited
    }

   let data ={
     'table' : this.reconReport.table,
     'list'  : this.reconReport.list,
     'dates' : dates
   }

    this.PCR.recon_now(data).subscribe(res=> {
      Swal.fire(
        'Success',
        'Data successfully saved',
        'success'
        );
      this.getPcrDetailedReport();
      this.basicModal.hide();      
    })  
  }
 


  recon_details(trans_creation){

    this.transaction_date = trans_creation;

    let date = {
      'trans_creation' : trans_creation,
      'print':false
    }
    this.PCR.recon_details(date).subscribe(res=> {
      this.recon=res;
    });  
  }


  generatePdf(){
    
    let data = {
      'trans_creation' : this.transaction_date,
      'print':true
    };

    this.PCR.recon_print(data).subscribe((res:Blob)=> {
      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "payment_recon.pdf";
      link.click();

    });  
  }



}
