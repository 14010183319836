import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsertypeService } from '../../../services-http/usertype/usertype.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GroupClientService } from '../../../services-http/group-client/group-client.service';
import { SlicePipe } from '@angular/common';
import { of } from 'rxjs';
import { UserService } from '../../../services-http/user/user.service';
import { SweetAlertService } from '../../../services-components/sweet-alert/sweet-alert.service';
import Swal from 'sweetalert2';
import { AgentApprovalService } from '../../../services-http/agent-approval/agent-approval.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective,ToastService  } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-admin-user-management-update',
  templateUrl: './admin-user-management-update.component.html',
  styleUrls: ['./admin-user-management-update.component.scss'],
  providers: [SlicePipe]
})
export class AdminUserManagementUpdateComponent implements OnInit {

  userId = 0;
  user:any = {};
  usertypes: any = [];
  userForm: FormGroup;
  selectedUserType = 0;
  selectedCoopCode = 0;
  showCLIMBSLabel = false;
  isShowCoopCode = false;
  isShowIfCoop = false;
  isShowUpdateBtnIfClimbsAdmin = true;
  isShowUpdateBtnIfCoop = false;
  userCoopInfoForm: FormGroup;
  groupClients: any = [];
  coop_results: any;
  coopNum = 0;
  isShowCoopName = false;

  currentDate = new Date();
  todate; 
  options:any = { opacity: 0 };


  user1 = {};
  url:any = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usertypeService: UsertypeService,
    private fb: FormBuilder,
    private groupClientService: GroupClientService,
    private slicePipe: SlicePipe,
    private userService: UserService,
    private sweetAlertService: SweetAlertService,
    private AgentApprovalSer:AgentApprovalService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastService
  ) { 
    this.route.parent.url.subscribe((urlPath) => {
      this.url = urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit() {
    this.userId = this.route.snapshot.params.id
    this.loadUser();  
    this.loadUserTypes();
    this.loadUserLoggedIn();
    
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      username: ['', [Validators.required, Validators.email]],
      usertype: ['', Validators.required],
      contact: ['', Validators.required],
      address: ['', Validators.required],
      product: ['', Validators.required],


      status: ['', ],
      coopcode: ['', ],
      coopCodeName: ['', ],
    });

    this.userCoopInfoForm = this.fb.group({
      coopMainCode: ['', Validators.required],
      coopName: ['']
    });

    let today = this.currentDate.getDate();
    let month = this.currentDate.getMonth() + 1;
    let year = this.currentDate.getFullYear();
    this.todate = year + '-' + month + '-' + today;
  }

  get inputName() { return this.userForm.get('name');}
  get inputUsername() { return this.userForm.get('username');}
  get inputPassword() { return this.userForm.get('password');}
  get inputUsertype() { return this.userForm.get('usertype');}
  get inputContact() { return this.userForm.get('contact');}
  get inputAddress() { return this.userForm.get('address');}



  goBack() {
    this.router.navigateByUrl('/'+this.url+'/users')
  }

  loadUserLoggedIn() {
    this.user1 = JSON.parse(localStorage.getItem('currentUser'));
  }

  loadUserTypes() {
    this.usertypeService.loadUserTypes().subscribe(res => {
      this.usertypes = res;
    });
  }

  loadUser() {
    this.spinner.show();
    this.userService.userDetails(this.userId).subscribe(res => {
      this.user = res;
      this.spinner.hide();

      this.userForm.get('name').setValue(this.user['pkid_name']);
      this.userForm.get('username').setValue(this.user['username']);
      this.userForm.get('contact').setValue(this.user['mobile_no']);
      this.userForm.get('address').setValue(this.user['address']);
      this.userForm.get('usertype').setValue(this.user['login_typeid']);
      this.userForm.get('coopcode').setValue(this.user['login_typeid']==5? this.user['agent_details'].cacgam_code:this.user['pkid_value'] );

      if (this.user['usertype'] == 1) {
        this.isShowCoopCode = true;
        this.showCLIMBSLabel = true;
        this.isShowUpdateBtnIfClimbsAdmin = true;
        let label = 'CLIMBS Life and General Insurance Cooperative'
        this.userForm.get('coopCodeName').setValue(label);
        this.selectedCoopCode = this.user['coop_maincode'];
      } else {
        this.showCLIMBSLabel = false;
        this.isShowIfCoop = true;
        this.isShowCoopCode = false;
        this.isShowUpdateBtnIfClimbsAdmin = false;
        this.isShowUpdateBtnIfCoop = true;
        this.coopNum = this.user['coop_maincode']
        this.userCoopInfoForm.get('coopMainCode').setValue(this.user['coop_maincode']);
        // this.userCoopInfoForm.get('coopName').setValue(this.user['username']);

        // this.loadGroupClientInfo();
        this.isShowCoopName = true;
      }

    },error=>{
      this.spinner.hide();
    });
  }

  onSelectedUserType() {
    this.selectedUserType = this.userForm.get('usertype').value;
    console.log(this.selectedUserType);

    if (this.selectedUserType == 1) {
      // console.log('CLIMBS Ka');
      this.selectedCoopCode = 3020;
      this.userForm.get('coopcode').setValue(this.selectedCoopCode);
      
      this.isShowCoopCode = true;
      let label = 'CLIMBS Life and General Insurance Cooperative'
      this.userForm.get('coopCodeName').setValue(label);
      this.showCLIMBSLabel = true;

      this.isShowUpdateBtnIfClimbsAdmin = true;
      this.isShowUpdateBtnIfCoop = false;
      this.isShowIfCoop = false;
    } else {
      this.showCLIMBSLabel = false;
      this.isShowIfCoop = true;
      this.isShowCoopCode = false;
      this.isShowUpdateBtnIfClimbsAdmin = false;
      this.isShowUpdateBtnIfCoop = true;
    }

  }

  getDataItems(term: string) {
    let data = {
      keyword: term
    }

    this.groupClientService.searchGroupClient(data).subscribe(res => {
      console.log(res);
      this.groupClients = res
    })

    return this.groupClients
  }

  searchEntries(term: string) {
    return of(this.slicePipe.transform(this.getDataItems(term).filter((data: any) => data['NAME'].toString().toLowerCase().includes(term.toString().toLowerCase())),0,20));
  }

  getFilteredCoopMainCode(keyVal) {
    console.log(keyVal)
    if (keyVal) {
      console.log('agi diri 1')
      this.coop_results = this.searchEntries(keyVal);
    } else {
      console.log('agi diri 2')
      let keyword = this.userCoopInfoForm.get('coopMainCode').value;
      // console.log(keyword)
      this.coop_results = this.searchEntries(keyword);
    }
    console.log(this.coop_results)

  }

  onChange(val) {
    let keyword = this.userCoopInfoForm.get('coopMainCode').value;

    if (keyword) {
      this.getFilteredCoopMainCode(keyword);
    }

    console.log(val)
    this.coopNum = val.split(' ---')[0];
    console.log('1. : ' + this.coopNum);

  }

  
  updateUserIfClimbsAdmin() {

    let data = {
      usertype: this.userForm.get('usertype').value,
      name: this.userForm.get('name').value,
      username: this.userForm.get('username').value,
      coop_maincode: this.selectedCoopCode,
      date_updated: this.todate,
      updated_by: this.user1['userId'],
      address: this.userForm.get('address').value,
      contact: this.userForm.get('contact').value,
    }
    
    this.userService.updateUser(this.userId, data).subscribe(res => {
      let type = 'success';
      let title = 'Successful';
      let text = 'User was successfully updated';
      let footer = '';
      this.sweetAlertService.showAlert(type, title, text, footer);
      this.router.navigateByUrl('/'+this.url+'/users');
    });
  }

  updateUserIfCoop() {

    let data = {
      usertype: this.userForm.get('usertype').value,
      name: this.userForm.get('name').value,
      username: this.userForm.get('username').value,
      coop_maincode:  this.userCoopInfoForm.get('coopMainCode').value,
      date_updated: this.todate,
      updated_by: this.user1['userId'],
      address: this.userForm.get('address').value,
      contact: this.userForm.get('contact').value,

    }
    // console.log(data)
    
    this.userService.updateUser(this.userId, data).subscribe(res => {

      let type = 'success';
      let title = 'Successful';
      let text = 'User was successfully updated';
      let footer = '';
      this.sweetAlertService.showAlert(type, title, text, footer);
      this.router.navigateByUrl('/'+this.url+'/users');
    });
  }

  activate(status) {
    Swal.fire({
      title: 'Are you sure?',
      text: "User will be active back",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, activate it!'
    }).then((result) => {
      if (result.value) {
        let data = {
          status: status,
          date_activated:this.todate
        }
        this.userService.approveOrDisapprove(this.userId, data).subscribe(res => {
            // this.router.navigateByUrl('/'+this.url+'/users');
            this.loadUser();
            Swal.fire(
              'Success!',
               res['msg'],
              'success'
            );
          },error=>{
            Swal.fire(
              'Ooops!',
              'Something went wrong!',
              'error'
            );
          });
      };
    });
  }

  resetPassword() {
    Swal.fire({
      title: 'Are you sure?',
      text: "The user password will be reset to default.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reset it!'
    }).then((result) => {
      if (result.value) {
        let data = {
          username: this.user.username,
          password:123456,
          password_confirmation:123456,
          userId:this.user.login_id       
        }
         
        this.userService.update_password(data).subscribe(res => {
          // this.router.navigateByUrl('/'+this.url+'/users');
          Swal.fire(
            'Success!',
            res['msg'],
            'success'
          );
        },error=>{
          let err=error.error;
          let msg='';
    
          Object.keys(err).forEach(function(key){
            msg+=err[key]+'<br/>';
          })
    
          Swal.fire(
            'Oppss',
            msg,
            'error'
          );
        });
          
       
      }
    });
  }

  updateProductAvail(p){

    let data = {
      variant_id : p.variantid,
      login_id : this.user.admin,
      delete : p.check
    }

    this.userService.addProductToUser(data).subscribe(res=>{
      
      this.toastrService.success(res['msg'], 'Success!', this.options);

    },error=>{
      console.log(error)
    });

  }

  // logout(){
  //   this.AgentApprovalSer.logout(this.userId).subscribe(res=>{
  //     Swal.fire(
  //       'Success',
  //       res['msg'],
  //       'success'
  //     );
  //   },error=>{
  //     Swal.fire(
  //       'Error',
  //       "Something went Wrong!",
  //       'error'
  //     );
  //   }
  //   )
  // }

}
