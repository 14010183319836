import { Component, OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { AuthService } from '../../../services-http/auth/auth.service';
import { UserService } from '../../../services-http/user/user.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NotificationService } from '../../../services-http/notification/notification.service'
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit,AfterViewInit {
  @ViewChild('frame') frame: ModalDirective;
  @ViewChild('basicModal') basicModal: ModalDirective;


  user:any = JSON.parse(localStorage.getItem('currentUser'));
  userType:any;
  coopName:any;
  envi = environment

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService:UserService,
    public notification:NotificationService
  ) { }


  ngOnInit() {
    // this.getCoopName();
    this.refresh();
  }

  ngAfterViewInit() {

    if(this.user.checkunpayed){
      this.basicModal.show();
    }
  }

  logout() {
    this.authService.logout();
  }

  navigate(p){

    if(p.variantid==3){
      return this.router.navigateByUrl('/agent/policy-issuance/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==1){
      return this.router.navigateByUrl('/agent/standard-fire/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==2){
      return this.router.navigateByUrl('/agent/comprehensive/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==56||p.variantid==60){
      return this.router.navigateByUrl('/agent/fire-issue/'+p.variantid+'/quoteId/0/'+this.user.iloginId);
    }

    this.router.navigateByUrl('/agent/CAC-products/'+p.variantid+'/quoteId/0/'+this.user.iloginId);

  }
  
   
  gotoProfileView() {
    this.router.navigateByUrl('/agent/cac-profile/' + this.user['userId'] + '/view');
  }

  // getCoopName(){
  //   this.userService.getCoopName(this.user['userId']).subscribe(res => {
  //     this.coopName = res;
  //   });
  // }

  notificationRead(notification){
    this.notification.notificationRead(notification).subscribe(res =>{
      this.notification.getNotification(this.user.iloginId);
    });
  }

  refresh(){
    this.notification.getNotification(this.user.iloginId);
  }

}
