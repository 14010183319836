import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  url = environment.url;
  constructor(private http: HttpClient) { }

  getFeedBack(){
    return this.http.get(this.url + '/get-feedback' );
  }

  feedbackAction(id){
    return this.http.get(this.url + '/feedback-action/' +id );
  }

  download_file(id){
    return this.http.get(this.url + '/download_file/' + id );
  }

  submitFeedback(formData){
    return this.http.post(this.url + '/submitFeedback' ,formData );
  }

}
