import { Component, OnInit } from '@angular/core';
import { IssuedPolicyCountService } from '../../../services-http/issued-policy-count/issued-policy-count.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-issued-policy-count-report',
  templateUrl: './issued-policy-count-report.component.html',
  styleUrls: ['./issued-policy-count-report.component.scss']
})
export class IssuedPolicyCountReportComponent implements OnInit {

  constructor( 
    private issuedPolicy: IssuedPolicyCountService,
    private spinner: NgxSpinnerService
  ) { }
    
  year:any='2020';
  data:any = [];
  headElements:any = ['No.','User Type', 'User Name', 'Basic Premium', 'Gross Premium', 'Policy Count','Date Last Issue'];
  
  ngOnInit() {
    this.getCountResult()
  }

  getCountResult(){
      this.spinner.show();

      this.issuedPolicy.policySummary(this.year,0).subscribe(res => {
        this.data = res;
        this.spinner.hide();
      },error=>{
        alert('Something went wrong!');
        this.spinner.hide();
      });
  }
  

  export(){
    this.spinner.show();
    this.issuedPolicy.policySummary(this.year,1).subscribe(res => {
       
      window.open(""+res)
      this.spinner.hide();
    },error=>{
      alert('Something went wrong!');
      this.spinner.hide();
    });
  }


  

}
