import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GroupClientService } from '../../../services-http/group-client/group-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-cac-coopinfo',
  templateUrl: './admin-cac-coopinfo.component.html',
  styleUrls: ['./admin-cac-coopinfo.component.scss']
})
export class AdminCacCoopinfoComponent implements OnInit {

  constructor( private fb: FormBuilder, private groupClientService: GroupClientService ) { }

  coopInfo:any = {};
  searchText:any = "CLIMBS Employees Cooperative";
  searchResult:any = [];
  searchListShow:boolean = false;

  profileForm = this.fb.group({
    Name:                ['',Validators.required],
    CoopNum:             ['',Validators.required],
    Address:             ['',Validators.required],
    TIN:                 ['',Validators.required],
    cda_registration_no: ['',Validators.required],
    ContactNum:          ['',Validators.required],
    EmailAd:             ['',Validators.required]
  });

  ngOnInit() { }

  search(){
    this.groupClientService.searchGroupClient({ search :this.searchText }).subscribe(res => {
      console.log(res)
      this.searchResult = res;  
      this.searchListShow = true;
    })
  }

  updateCoopInfo(){
    this.groupClientService.updateCoopInfo( this.profileForm.value, this.coopInfo.CoopNum ).subscribe(res => {
      this.searchResult = res; 
      Swal.fire({
        type: 'success',
        title: 'Success',
        text: 'Successfully saved!'
      })
    })  
  }

  closeSearchList(){
    this.searchListShow = false;
  }

  searchItem(searhItem){
    this.coopInfo = searhItem;
    this.searchText = this.coopInfo.Name;
    this.profileForm.patchValue(searhItem);
    this.searchListShow = false;

  }
  

}
