import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators,FormGroup } from '@angular/forms';
import { AdminRemittanceManagementService } from 'src/app/services-http/admin-remittance-management/admin-remittance-management.service';
import  Swal from 'sweetalert2';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common'
import { IMyOptions } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime } from "rxjs/operators";



@Component({
  selector: 'app-admin-remittance-management',
  templateUrl: './admin-remittance-management.component.html',
  styleUrls: ['./admin-remittance-management.component.scss']
})
export class AdminRemittanceManagementComponent implements OnInit {
  @ViewChild('frame') frame: ModalDirective;

  remittances:any = [];
  dateRange:Date[];
  coop_name:any = "";
  remitDetails:any = [];
  currentDate = new Date();
  refNum:any; 
  reference_no:any;
  searchRemittance:any ="";
  lsi:any = JSON.parse(localStorage.getItem('currentUser'));
  filter:any = [];

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };

  mydate = new Date();
  enddate = this.mydate.toISOString().substring(0,10);
  customize_date = new Date("2019-01-01");
  startdate = this.customize_date.toISOString().substring(0,10);

  filterForm = this.fb.group({
    date_from: [this.startdate,Validators.required],
    date_to  : [this.enddate,Validators.required],
    branch   : ['',Validators.required],
    product  : ['',Validators.required],
    coop     : ['',Validators.required],
    area     : ['',Validators.required],
    region   : ['',Validators.required]
  });

  headElements = ['','REFERENCE NO.','DATE UPLOAD', 'PORODUCT','NO. OF INSURED','MAIN CODE','COOP NO.', 'COOP NAME', 'BRANCH','REGION','GROSS PREM.', 'MGNT. FEE', 'REMITTED AMNT.','OR NO','OR DATE', 'FILE/S', 'REMARKS','ACTION'];


  area:any=[
    {label:'Luzon',value:'L'},
    {label:'Visayas',value:'V'},
    {label:'Mindanao',value:'M'}
  ];


  region:any=[
    {label:'Region 1',value:'1'},
    {label:'Region 2',value:'2'},
    {label:'Region 3',value:'3'},
    {label:'Region 4A',value:'4A'},
    {label:'Region 4B',value:'4B'},
    {label:'Region 5',value:'5'},
    {label:'Region 6',value:'6'},
    {label:'Region 7',value:'7'},
    {label:'Region 8',value:'8'},
    {label:'Region 9',value:'9'},
    {label:'Region 10',value:'10'},
    {label:'Region 11',value:'11'},
    {label:'Region 12',value:'12'},
    {label:'Region 13',value:'13'},
    {label:'ARMM',value:'ARMM'},
    {label:'CAR',value:'CAR'}
  ];

  // for upload
  uploaded:any;
  url=this.router.url;
  furl;
  // searchText= new FormControl('');
  filteredCoops:any =[];
  productList:any=[];
  branch:any=[];

  date = this.fb.group({
    from: ['', Validators.required],
    to  : ['', Validators.required],
  });

  search = new FormGroup({
    searchText: new FormControl('')
 
  });

  remittanceUpdate = this.fb.group({
    remittance_id  :['', Validators.required],
    reference_no   :[{value: '', disabled: true}, Validators.required],
    product        :[{value: '', disabled: true}, Validators.required],
    coop_no        :[{value: '', disabled: true}, Validators.required],
    coop_name      :[{value: '', disabled: true}, Validators.required],
    branch         :[{value: '', disabled: true}, Validators.required],
    date_uploaded  :[{value: '', disabled: true}, Validators.required],
    date_from      :['', Validators.required],
    date_to        :['', Validators.required],
    gross_premium  :['', Validators.required],
    coop_commission:['', Validators.required],
    remitted_amount:['', Validators.required],
    remarks        :['', Validators.required],
    noOfEnrollees  :['', Validators.required],
    policyno       :[''],
    or_no          :[''],
    or_date        :[''],
  });
  

  constructor(
    private remittance:AdminRemittanceManagementService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private router: Router
  ){ }

  ngOnInit(){
    this.furl=this.url.split('/')[1];
  
    this.filter = [
      {label: 'Coop No', value: 1},
      {label: 'Reference No', value: 2},
      {label: 'Gross Premium', value: 3},
    ];
 

    this.search.get('searchText').valueChanges.pipe(debounceTime(1000)).subscribe(res => {
    
      this.getRemittance(1);
      
    });

    this.getRemittance(1);
    this.product_list();
    this.get_branch();
  }


  paybills(remittance_id){
    Swal.fire({
      position         :'center',
      type             :'info',
      html             :'Pay it directly via your <a href="https://truemoney.com.ph/locator/" target="_blank">nearest True Money payment center.</a></br>'+
                        '<img src="assets/imgs/truemoney_logo.webp" height="100" width="100" alt="truemoney Logo">'
      ,
      showCancelButton : true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Pay via truemoney'
    }).then((result)=>{
      if (result.value) {

        let text_info = {
          remittance_id : remittance_id,
          userId        : this.lsi.userId
        }

        this.remittance.getReferenceNum(text_info).subscribe(res=>{
          this.reference_no = res;
            Swal.fire({
              title: ''+this.reference_no.referencenumber,
              html: 'This is your reference number use this to any truemoney payment center <br>',
              footer: 'Thank You.',
              showConfirmButton: false,
              showCloseButton: true,
            });
          
        },error=>{
          Swal.fire(
            'Oppss',
            'Something went wrong!',
            'error'
          );
        });
      }
    })
  }


  getRemittance(pageno){

    this.spinner.show();
    let coopNo = this.lsi.coop_maincode;
    let data:any ={
      date_from: this.filterForm.value.date_from,
      date_to  : this.filterForm.value.date_to,
      branch   : this.filterForm.value.branch==''?'branch':JSON.stringify(this.filterForm.value.branch),
      product  : this.filterForm.value.product==''?'product':JSON.stringify(this.filterForm.value.product),
      coop     : this.filterForm.value.coop==''?'coop_no':JSON.stringify(this.filterForm.value.coop),
      area     : this.filterForm.value.area==''?'area':JSON.stringify(this.filterForm.value.area),
      region   : this.filterForm.value.region==''?'region':JSON.stringify(this.filterForm.value.region),
      login_id : this.lsi.iloginId,
      usertype : this.lsi.usertype,
      page     : pageno,
      search   : this.search.value.searchText
    } 

    this.remittance.getRemittances(data, coopNo).subscribe(res=>{
      this.remittances = res;
    this.spinner.hide();

    },error=>{
      this.spinner.hide();
 
      Swal.fire(
        'Error!',
        'Something went wrong!.',
        'error'
      );
    });
  }
  

  remittance_details(remit){
    this.remittanceUpdate.patchValue(remit);
  }


  delete_remittance(remittance_id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      
      if (result.value == true) {
        this.remittance.delete_remittance(remittance_id).subscribe(res=>{
          this.getRemittance(1);
          this.frame.hide();
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        },error=>{
          this.frame.hide();
          Swal.fire(
            'Error!',
            'Something went wrong!.',
            'error'
          );
        });

      }else{
        return 
      }
    })
  }


  update_remittance(remittance_id){
    this.remittance.update_remittance(remittance_id, this.remittanceUpdate.value).subscribe(res=>{
      this.getRemittance(1);
      this.frame.hide();
      Swal.fire(
        'Success!',
        'Successfully updated remittance details.',
        'success'
      );
      },error=>{
        this.frame.hide();
        Swal.fire(
          'Oppss!',
          'Something went wrong.',
          'error'
        );
      });
  }

  generate_remittance(){
    this.remittance.generate_remittance_report(this.date.value).subscribe(res=>{
      // console.log(res)
    });
  }

  getUpload(remittance_id){
    this.remittance.getUpload(remittance_id).subscribe(res=>{
      this.uploaded = res
      for (let index = 0; index < this.uploaded.length; index++) {
        window.open(this.uploaded[index]);
      }
    })
  }



  
  filterBrands(event) {
    this.filteredCoops = [];
    for(let i = 0; i < this.remittances.filterByCoop.length; i++) {
        let brand:string = this.remittances.filterByCoop[i].coop_name;
        if(brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
            this.filteredCoops.push(brand);
        }
    }
    
  }

  exportRemittanceList(){
    this.spinner.show();

    let coopNo = this.lsi.coop_maincode;
    let data:any ={
      date_from: this.filterForm.value.date_from,
      date_to  : this.filterForm.value.date_to,
      branch   : this.filterForm.value.branch==''?'branch':JSON.stringify(this.filterForm.value.branch),
      product  : this.filterForm.value.product==''?'product':JSON.stringify(this.filterForm.value.product),
      coop     : this.filterForm.value.coop==''?'coop_no':JSON.stringify(this.filterForm.value.coop),
      area     : this.filterForm.value.area==''?'area':JSON.stringify(this.filterForm.value.area),
      region   : this.filterForm.value.region==''?'region':JSON.stringify(this.filterForm.value.region),
      login_id : this.lsi.iloginId,
      usertype : this.lsi.usertype,
      export   : 'true',
      search   : this.search.value.searchText
    } 

    this.remittance.getRemittances(data, coopNo).subscribe(res=>{
      // this.remittances = res;
      this.spinner.hide();
      window.open(res+'');
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Error!',
        'Something went wrong!.',
        'error'
      );
    });
  }
 
  product_list(){
    this.remittance.product_list().subscribe(res=>{
      this.productList=res;
    });
  }

 
  get_branch(){
    this.remittance.get_branch(this.lsi.coop_maincode).subscribe(res=>{ 
      this.branch=res;
    });

  }

}
