import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DatePipe } from '@angular/common';
  
export class validateEffectivityDate {
    static valEffDate(control: AbstractControl) : ValidationErrors | null {
        
        let now = new Date();
        let today = now.setDate(now.getDate() - 45);
        let pipe = new DatePipe('en-US');
        let transform = pipe.transform(today, 'yyyy-MM-dd');

        if(control.value < transform){
            return {valEffDate: true}
        }
  
        return null;
    }
}