import { Component, OnInit } from '@angular/core';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-branch-reports',
  templateUrl: './branch-reports.component.html',
  styleUrls: ['./branch-reports.component.scss']
})
export class BranchReportsComponent implements OnInit {

  constructor(
    private prodHis:ProductionHistoryService,
    private spinner: NgxSpinnerService
  ) { }

  headElements:any = ["No","Issue Date", "Coop No.", "Agent Name", "Product Variant", "Policy No.", "Basic Preium",  "Gross Premium", "Coll. fee(CAC)", "Coll. fee(Agent)", "Coll. fee total" ];

  coop_id:any = JSON.parse(localStorage.getItem('currentUser'));
   
  branch_policy:any =[];
  searchText:any = "";

  ngOnInit() {

    this.get_list();
  }

  get_list(){
    setTimeout(() => this.spinner.show(), 0);
    this.prodHis.get_branch_report(this.coop_id.coop_maincode).subscribe( res=> {
      this.branch_policy = res;
      this.spinner.hide()
    })
  }

}
