import { Component, OnInit,ViewChild } from '@angular/core';
import { RenewalNoticeService } from '../../../services-http/renewal-notice/renewal-notice.service';
import { environment } from 'src/environments/environment.prod';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-renewal-notice-list',
  templateUrl: './renewal-notice-list.component.html',
  styleUrls: ['./renewal-notice-list.component.scss']
})
export class RenewalNoticeListComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('basicModal1') basicModal1: ModalDirective;

  constructor(private renewal:RenewalNoticeService, private spinner: NgxSpinnerService) { }

  productOption:any = [
    { value:'1', label:'STANDARD FIRE'} ,        
    { value:'2', label:'COMPREHENSIVE '} ,       
    { value:'3', label:'CTPL'} ,           
    { value:'4', label:'KOOP AKSI'} ,        
    { value:'5', label:'FAMSI'}  ,                
    { value:'6', label:'3sure Life '} ,           
    { value:'7', label:'KABAYANi'}   ,            
    { value:'8', label:'MILLENNIALS PROTEK'},     
    { value:'9', label:'TODA PLAN'} ,             
   { value:'10', label: 'LYSA'}    ,               
   { value:'11', label: 'NATALIE '} ,              
   { value:'12', label: 'MyPARENTS PROTEK  '} ,    
   { value:'13', label: 'DISABLITY LIFE ENSURE '} ,
   { value:'55', label: 'ANGELICA      '} ,        
   { value:'56', label: 'ADVANTAGE'},              
   { value:'57', label: 'CHAIN '} ,                
   { value:'58', label: 'CFP '} ,                  
   { value:'59', label: 'CSEINS '} ,               
   { value:'60', label: 'FICA  '} ,                
   { value:'61', label: 'GADDDI-PLUS'},            
   { value:'62', label: 'GRTL-INFINITY'} ,        
   { value:'63', label: 'KEYMAN '} ,               
   { value:'64', label: 'PATEXT15 '} ,             
   { value:'65', label: 'SAI  '} ,                 
   { value:'66', label: 'TRAVEL-PA '} ,         
   { value:'67', label: 'GLAFI '} ,                
   { value:'68', label: 'FLP '} , 
  ];
  
  searchText:any='';
  url=environment.url;
  user:any = JSON.parse(localStorage.getItem('currentUser'));
  list:any=[];
  headElements:any=['Assured Name', 'Policy', 'Policy No.', 'Coop Name' , 'EffectiveDate', 'ExpiryDate', 'Action'];
  coopLists:any = []
  filterRenewal=new FormGroup({
    from: new FormControl('2019-01-01', Validators.required),
    to: new FormControl('2022-01-01', Validators.required),
    coopname: new FormControl('cac_coop_no'),
    product: new FormControl('variantid'),

  });



  renewalNoticeList(pageno){

    let data ={
      page:pageno,
      from:this.filterRenewal.value.from ,
      to:this.filterRenewal.value.to,
      product:this.filterRenewal.value.product,
      coopname:this.filterRenewal.value.coopname,
      searchKey: this.searchText,
      isDownload:false
      // searchKey: this.searchText==''?'policyno':this.searchText
    }
    
    setTimeout(() => this.spinner.show(), 0);
    this.renewal.renewalNoticeList( data).subscribe((res)=>{
      this.spinner.hide();
      this.list=res;

    },error=>{
      this.spinner.hide();
    });
  }


  download_bulk(pageno){
    let data ={
      page:pageno,
      from:this.filterRenewal.value.from ,
      to:this.filterRenewal.value.to,
      product:this.filterRenewal.value.product,
      coopname:this.filterRenewal.value.coopname,
      searchKey: this.searchText,
      isDownload:1
      // searchKey: this.searchText==''?'policyno':this.searchText
    }
    this.spinner.show();

    this.renewal.download_bulk(data).subscribe((res:Blob)=>{
      this.spinner.hide();
      this.basicModal.hide();
  
      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "renewal-notice.pdf";
      link.click();

    },error=>{
      this.spinner.hide();
      this.basicModal.hide();

    });
  }


  coopList(){
    this.renewal.coopList().subscribe(res=>{
      this.coopLists=res;
    });
  }

  sendRenewalNotice(policyno){
    this.spinner.show()
    this.renewal.sendRenewalNotice(policyno).subscribe(res=>{
      this.spinner.hide();
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
    },error=>{
      this.spinner.hide();

      Swal.fire(
        'Oppss',
        "Something went wrong.",
        'error'
      );
    });
  }


  download_renewal_notice(policyno){
    this.spinner.show();

    this.renewal.download_renewal_notice(policyno).subscribe((res:Blob)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "renewal-notice.pdf";
      link.click();
   
    },error=>{
      this.spinner.hide();
    });
  }


  ngOnInit() {

    // this.basicModal1.show();


    if(this.user.usertype==1||this.user.usertype==13 ){
      this.filterRenewal.controls['coopname'].setValidators([Validators.required]);
      this.coopList();

    }else{
      this.filterRenewal.controls['coopname'].setValue(this.user.coop_maincode);
    }

    this.renewalNoticeList(1);
  }

}
