import { Component, OnInit} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import  Swal from 'sweetalert2';
import { AdminRemittanceManagementService } from 'src/app/services-http/admin-remittance-management/admin-remittance-management.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime } from "rxjs/operators";


@Component({
  selector: 'app-admin-remittance-uploading',
  templateUrl: './admin-remittance-uploading.component.html',
  styleUrls: ['./admin-remittance-uploading.component.scss']
})
export class AdminRemittanceUploadingComponent implements OnInit {
  reference_no:any;
  isShow:boolean = false;
  coopList:any =[];
  productList:any=[];
  User = JSON.parse(localStorage.getItem('currentUser'));

  url=this.router.url;
  furl;
  branch:any=[];
  mydate = new Date();
  date = this.mydate.toISOString().substring(0,10);
  data = new FormData();
  productOption:any=[];



  profileForm = this.fb.group({
    search_coop    :[''],
    coop_no        :[''],
    coop_name      :[''],
    branch         :['', Validators.required],
    date_uploaded  :[''],
    product        :['', Validators.required],
    reference_no   :['', Validators.required],
    date_from      :['', Validators.required],
    date_to        :['', Validators.required],
    gross_premium  :['',[Validators.required, Validators.pattern('^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)*$')]],
    coop_commission:['',[Validators.required, Validators.pattern('^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)*$')]],
    remitted_amount:['',[Validators.required, Validators.pattern('^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)*$')]],
    file           :[''],
    remarks        :['', Validators.required],
    noOfEnrollees  :['',[Validators.required, Validators.pattern('^[0-9]*$')]],
    expiry_date    :['',[Validators.required]]

  });

  constructor(
    private fb: FormBuilder,
    private remittance:AdminRemittanceManagementService,
    private router: Router,
    private spinner: NgxSpinnerService,
    ) {
  }


  ngOnInit() { 
    this.furl=this.url.split('/')[1];


    if(this.User.coop_maincode !=3020){
   
      this.getCoopnum();
      this.get_branch();
      this.getProductListPerCoop();
    }

    

    this.profileForm.get("search_coop").valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      if(res){
        this.onEnter(res);
      }
    });

  }


  getCoopnum(){
    this.remittance.getCoopnum(this.User.coop_maincode=='3020'?this.profileForm.value.coop_no :this.User.coop_maincode).subscribe(res=>{
      this.profileForm.patchValue(res);
    });
  }
 

  get_branch(){
    this.remittance.get_branch(this.User.coop_maincode=='3020'?this.profileForm.value.coop_no :this.User.coop_maincode).subscribe(res=>{ 
      this.branch=res;
    });
  }


  // product_list(){
  //   this.remittance.product_list().subscribe(res=>{
      // this.productList=res;
  //   });
  // }





  upload_remittance(){
    let branchDetails= this.branch.branch.find(x=>x.Branch==this.profileForm.value.branch);
 
    this.spinner.show();
    this.data.append('detail[branch]',this.profileForm.value.branch);
    this.data.append('detail[coop_commission]',this.profileForm.value.coop_commission.replace(/\,/g,''));
    this.data.append('detail[coop_name]',this.profileForm.value.coop_name);
    this.data.append('detail[coop_no]',this.profileForm.value.coop_no);
    this.data.append('detail[date_from]',this.profileForm.value.date_from);
    this.data.append('detail[date_to]',this.profileForm.value.date_to);
    this.data.append('detail[date_uploaded]',this.date );
    this.data.append('detail[gross_premium]',this.profileForm.value.gross_premium.replace(/\,/g,''));
    this.data.append('detail[noOfEnrollees]',this.profileForm.value.noOfEnrollees);
    this.data.append('detail[product]',this.profileForm.value.product);
    this.data.append('detail[reference_no]',this.profileForm.value.reference_no);
    this.data.append('detail[remarks]',this.profileForm.value.remarks);
    this.data.append('detail[remitted_amount]',this.profileForm.value.remitted_amount.replace(/\,/g,''));
    this.data.append('detail[login_id]',this.User.iloginId);
    this.data.append('detail[expiry_date]',this.profileForm.value.expiry_date);
    this.data.append('detail[area]', branchDetails.Area);
    this.data.append('detail[region]',branchDetails.Region);
    this.data.append('detail[CoopNum]',branchDetails.CoopNum);


    this.remittance.upload_remittance_details(this.data)
      .subscribe(remittance_id=>{
      this.spinner.hide();

      Swal.fire({
        position  :'center',
        type      :'success',
        title     :'Remittance uploaded!.',
        html      :'You can pay it directly via your <a href="https://truemoney.com.ph/locator/" target="_blank">nearest True Money payment center.</a></br>'+
                          '<img src="assets/imgs/truemoney_logo.webp" height="100" width="100" alt="truemoney Logo">'
        ,
        showCancelButton: true,
        cancelButtonText: 'Not now',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Pay via truemoney'
      }).then((result)=>{
        this.spinner.hide();
        this.router.navigate(['/'+this.furl+'/adminRemittance']);

        if (result.value) {
          // let text_info = {
          //   remittance_id : remittance_id,
          //   userId: this.User.userId
          // }
        
          // this.remittance.getReferenceNum(text_info).subscribe(res=>{
          //   this.reference_no = res;
          //   this.profileForm.reset();

          //     Swal.fire({
          //       title: ''+this.reference_no.referencenumber,
          //       html: 'This is your reference number,  use this to any truemoney payment center. <br>',
          //       footer: 'Thank You.',
          //       showConfirmButton: true,
                
               
          //     }).then((result)=>{
          //       if (result.value) {
          //         this.router.navigate(['/'+this.furl+'/adminRemittance']);
          //       }
          //     })

          // });

        }else{
          
          // this.router.navigate(['/cac/adminRemittance']);
        }

      })
    })
  }


  delete(index){
    let file:any=this.data.getAll('file[]');
    file.splice(index,1);
    this.data.delete('file[]');

    file.forEach(item=>{
      this.data.append('file[]',item );
    });
  }


  onUploadOutput(event) {
    this.data.append('file[]', event.target.files[0], event.target.files[0].name);
  }


  onEnter(value) { 
    this.remittance.getCoop(value).subscribe(res=>{
      this.coopList = res;
      this.isShow = true;
    },error=>{
      this.isShow = false;
    });
  }


  patchCoopValue(coop){

    
    this.profileForm.patchValue({coop_no:coop.coop_no});
    this.isShow = false;

    this.getProductListPerCoop();
    this.get_branch();
    this.getCoopnum();


  }


  getProductListPerCoop(){
    this.remittance.getProductListPerCoop(this.User.coop_maincode=='3020'?this.profileForm.value.coop_no :this.User.coop_maincode).subscribe((res:any)=>{

      this.productList=res;
      this.productOption=res.filter(x=>x.coop_no);
    });
  }



  updateProductAssign(value){
    let data={
      product_id: value.Product_no,
      coop_no: this.User.coop_maincode=='3020'?this.profileForm.value.coop_no :this.User.coop_maincode,
      add:value.coop_no
    }
    
    this.remittance.updateProductAssign(data).subscribe((res:any)=>{
      this.getProductListPerCoop();
    },error=>{
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }

}
