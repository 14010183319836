export const environment = {
  production: true,
   url: 'https://coop-portal-api.climbs.coop', 
   whitelistedDomains: ['coop-portal-api.climbs.coop'],
   tagumAPI: 'https://ictweb.tagumcooperative.coop/echoserver/mba',
   token_tagum: 'f/XnEfAgDq+mTDewBcPR1w==',
   ibisaAPI: 'https://climbs.api.ibisa.network',
   token_ibisa: 'ZYAeIefgz6CMpUArdZ5i0hOWK9kd03',
   tech_support_number: '09177035419/09178019789',
   tech_support_email: 'it_support@climbs.coop'
};
