import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { DatePipe } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";
import { StepsModule } from 'primeng/steps';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToolbarModule } from 'primeng/toolbar';
import { CardModule } from 'primeng/card';
import { TabMenuModule } from 'primeng/tabmenu';
import { ChartModule } from 'primeng/chart';
import { SliderModule } from 'primeng/slider';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { MenubarModule } from 'primeng/menubar';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';

// Interceptors
import { ErrorInterceptor } from './services-http/http-interceptor/error.interceptor';
import { JwtInterceptor } from './services-http/http-interceptor/jwt.interceptor';
import { AuthGuardService } from './services-http/auth-guard/auth-guard.service';

// pipes
import { TruncatePipe } from './services-pipe/truncate/truncate.pipe';
import { SearchPipe } from './services-pipe/search/search.pipe';
import { SanitizeHtmlPipe } from './services-pipe/sanitize-html/sanitize-html.pipe';
import { SanitizeUrlService} from './services-pipe/sanitize-url/sanitize-url.service';

// components
import { WelcomeComponent } from './components/welcome/welcome.component';
import { WelcomeHomeComponent } from './components/welcome-home/welcome-home.component';
import { AdminComponent } from './components/admin/admin/admin.component';
import { AdminUserManagementComponent } from './components/shared/admin-user-management/admin-user-management.component';
import { AdminUserManagementUpdateComponent } from './components/shared/admin-user-management-update/admin-user-management-update.component';
import { CoopComponent } from './components/coop/coop/coop.component';
import { ProfileViewComponent } from './components/profile-view/profile-view.component';
// import { ProfileUpdateComponent } from './components/profile-update/profile-update.component';
// import { WelcomeHomeParamsComponent } from './components/welcome-home-params/welcome-home-params.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { CoopProductionHistoryComponent } from './components/coop/coop-production-history/coop-production-history.component';
import { AdminProductionHistoryComponent } from './components/admin/admin-production-history/admin-production-history.component';
import { AdminContactUsComponent } from './components/shared/admin-contact-us/admin-contact-us.component';
import { AdminFeedbackComponent } from './components/shared/admin-feedback/admin-feedback.component';
import { CoopClaimsSubmissionComponent } from './components/shared/coop-claims-submission/coop-claims-submission.component';
import { CoopClaimsMonitoringComponent } from './components/shared/coop-claims-monitoring/coop-claims-monitoring.component';
import { AdminCacCoopinfoComponent } from './components/shared/admin-cac-coopinfo/admin-cac-coopinfo.component';
import { AdminCacChartComponent } from './components/shared/admin-cac-chart/admin-cac-chart.component';
import { PcrDashboardComponent } from './components/shared/pcr-dashboard/pcr-dashboard.component';
import { PcrDetailedCollectionReportComponent } from './components/shared/pcr-detailed-collection-report/pcr-detailed-collection-report.component';
import { AdminRemittanceManagementComponent } from './components/shared/admin-remittance-management/admin-remittance-management.component';
import { AdminRemittanceUploadingComponent } from './components/shared/admin-remittance-uploading/admin-remittance-uploading.component';                        
import { AdminCacCashInApprovalComponent } from './components/shared/admin-cac-cash-in-approval/admin-cac-cash-in-approval.component';
import { CoopFAQComponent } from './components/shared/coop-faq/coop-faq.component';
import { GamComponent } from './components/gam/gam/gam.component';
import { GamProductionReportComponent } from './components/gam/gam-production-report/gam-production-report.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CacComponent } from './components/cac/cac/cac.component';
import { CacProductionReportComponent } from './components/cac/cac-production-report/cac-production-report.component';
import { CacProductionReportChartComponent } from './components/shared/cac-production-report-chart/cac-production-report-chart.component';
import { CacWalletComponent } from './components/shared/cac-wallet/cac-wallet.component';
import { AdminQuotationApprovalComponent } from './components/shared/admin-quotation-approval/admin-quotation-approval.component';
import { IssuedPolicyCountReportComponent } from './components/shared/issued-policy-count-report/issued-policy-count-report.component';
import { CacIssuedPolicyCountReportComponent } from './components/shared/cac-issued-policy-count-report/cac-issued-policy-count-report.component';
import { CTPLComponent } from './components/shared/CTPL/CTPL.component';
import { AdminQuotationDetailsComponent } from './components/shared/admin-quotation-details/admin-quotation-details.component';
import { IssuedPolicyComponent } from './components/shared/issued-policy/issued-policy.component';
import { SettingComponent } from './components/shared/setting/setting.component';
import { EndorsementComponent } from './components/shared/endorsement/endorsement.component';
import { ComprehensiveComponent } from './components/shared/comprehensive/comprehensive.component';
import { StandardFireComponent } from './components/shared/standard-fire/standard-fire.component';
import { IssuedPolicyQuotationComponent } from './components/shared/issued-policy-quotation/issued-policy-quotation.component';
import { CreateInsurtechAnnouncementComponent } from './components/shared/create-insurtech-announcement/create-insurtech-announcement.component';
import { FeedbackUsComponent } from './components/shared/feedback-us/feedback-us.component';
import { UpdateInsurtechVersionComponent } from './components/shared/update-insurtech-version/update-insurtech-version.component';
import { ProductManagementComponent } from './components/shared/product-management/product-management/product-management.component';
import { UnderAgentListComponent } from './components/shared/under-agent-list/under-agent-list.component';
import { CoopApiUserComponent } from './components/shared/coop-api-user/coop-api-user.component';
import { CacProductsComponent } from './components/shared/cac-products/cac-products.component';
import { UserCountComponent } from './components/shared/reports/user-count/user-count.component';
import { PolicyCountComponent } from './components/shared/reports/policy-count/policy-count.component';
import { NetpremiumSumComponent } from './components/shared/reports/netpremium-sum/netpremium-sum.component';
import { UserTransactingComponent } from './components/shared/reports/user-transacting/user-transacting.component';
import { EndorsementOfficerComponent } from './components/endorsement-officer/endorsement-officer/endorsement-officer.component';
import { DQCCenterComponent } from './components/DQC-center/dqc-center/dqc-center.component';
import { QuotationApprovalOfficerComponent } from './components/quotation-approval-officer/quotation-approval-officer/quotation-approval-officer.component';
import { CashinApprovalOfficerComponent } from './components/cashin-approval-officer/cashin-approval-officer/cashin-approval-officer.component';
import { ClaimsOfficerComponent } from './components/claims-officer/claims-officer/claims-officer.component';
import { UserManagementOfficerComponent } from './components/user-management-officer/user-management-officer/user-management-officer.component';
import { AgentComponent } from './components/agent/agent/agent.component';
import { RenewalNoticeListComponent } from './components/shared/renewal-notice-list/renewal-notice-list.component';
import { RenewalNoticeOfficerComponent } from './components/renewal-notice-officer/renewal-notice-officer/renewal-notice-officer/renewal-notice-officer.component';
import { BillingComponent } from './components/shared/billing-wallet/billing.component';
import { CreditLineApprovalComponent } from './components/shared/credit-line-appoval/credit-line-approval/credit-line-approval.component';
import { FireInsureComponent } from './components/shared/fire-insure/fire-insure.component';
import { PolicyExtensionComponent } from './components/shared/policy-extension/policy-extension.component';
import { HomepageComponent } from './components/shared/homepage/homepage.component';
import { CashierComponent  } from './components/cashier/cashier/cashier.component';
import { CheckPointsComponent } from './components/shared/check-points/check-points.component';
import { AboveRetentionLimitComponent } from './components/shared/above-retention-limit/above-retention-limit.component';
import { AgentProductionHistoryComponent } from './components/agent/agent-production-history/agent-production-history.component';
import { BillingPaymentHistoryComponent } from './components/shared/billing-payment-history/billing-payment-history.component';
import { BillingGenerateComponent } from './components/shared/billing-generate/billing-generate.component';
import { AdminUserCommissionComponent } from './components/shared/admin-user-commission/admin-user-commission.component';
import { BranchReportsComponent } from './components/shared/branch-reports/branch-reports.component';
import { CreditPaymentApprovalComponent } from './components/shared/credit-payment-approval/credit-payment-approval.component';
import { AdminBillingGenerateComponent } from './components/shared/admin-billing-generate/admin-billing-generate.component';
import { IbisaForecastComponent } from './components/shared/ibisa-forecast/ibisa-forecast.component';
import { ReportsComponent } from './components/reports/reports.component';
import { OrIssuanceComponent } from './components/shared/or-issuance/or-issuance.component';
import { ReporterComponent } from './components/reporter/reporter/reporter.component';
import { ProductionHistoryComponent } from './components/reporter/production-history/production-history.component';
import { AdminTransactionHistoryComponent } from './components/shared/admin-transaction-history/admin-transaction-history.component';
import { SurityBondComponent } from './components/shared/surity-bond/surity-bond.component';
import { GroupAccessComponent } from './components/shared/group-access/group-access.component';
import { MigrationToNonlifeComponent } from './components/shared/migration-to-nonlife/migration-to-nonlife.component';
import { WalletBalancePerUserComponent } from './components/shared/wallet-balance-per-user/wallet-balance-per-user.component';



const appRoutes: Routes = [
  { 
    path: 'report', 
    component: ReportsComponent, 
    children:[
      {
        path: 'policy-count-report',
        component: PolicyCountComponent,
      },
      {
        path: 'Cash-In-Approval',
        component: AdminCacCashInApprovalComponent,
      },
      {
        path: 'credit-payment',
        component: CreditPaymentApprovalComponent,
      },
      {
        path: 'user-transacting',
        component: UserTransactingComponent,
      },
      {
        path: 'sum-net-premium',
        component: NetpremiumSumComponent,
      },
      {
        path: 'user-count-report',
        component: UserCountComponent,
      },
      {
        path: 'isued-policy-summary',
        component: IssuedPolicyCountReportComponent,
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
      },
      {
        path: 'production-history',
        component: AdminProductionHistoryComponent,
      },  

      {
        path: 'adminRemittance',
        component: AdminRemittanceManagementComponent,
      },

    ]
  },
  


  { 
    path: 'welcome', 
    component: WelcomeComponent, 
    children: [
      {
        path: 'home',
        component: WelcomeHomeComponent
      },
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuardService],
    data: { userType: 1 },
    children: [
      {
        path: 'orIssuance',
        component: OrIssuanceComponent,
        canActivate: [AuthGuardService],
         data: { userType: 1 },
      },
      {
        path: 'users',
        component: AdminUserManagementComponent,
        canActivate: [AuthGuardService],
         data: { userType: 1 },
      },
      {
        path: 'users/:id/update',
        component: AdminUserManagementUpdateComponent,
        canActivate: [AuthGuardService],
         data: { userType: 1 },
      },
      {
        path: 'profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
         data: { userType: 1 },
      },
      {
        path: 'profileEdit',
        component: ProfileEditComponent,
        canActivate: [AuthGuardService],
         data: { userType: 1 },
      },
      {
        path: 'agent-feedback',
        component: AdminFeedbackComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'production-history',
        component: AdminProductionHistoryComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'cac-coopinfo',
        component: AdminCacCoopinfoComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'cac-chart',
        component: AdminCacChartComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'pcr-dashboard',
        component: PcrDashboardComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'pcr-detailed-report-recon',
        component: PcrDetailedCollectionReportComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'Cash-In-Approval',
        component: AdminCacCashInApprovalComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'quotation-approval',
        component: AdminQuotationApprovalComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'isued-policy-summary',
        component: IssuedPolicyCountReportComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'quotation-details/:id',
        component: AdminQuotationDetailsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'endorsement',
        component: EndorsementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'adminRemittance',
        component: AdminRemittanceManagementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'admin-remittance-uploading',
        component: AdminRemittanceUploadingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'create-insurtech-announcement',
        component: CreateInsurtechAnnouncementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'update-insurtech-version',
        component: UpdateInsurtechVersionComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'productManagement',
        component: ProductManagementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'coop-api-user',
        component: CoopApiUserComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },

      },
      {
        path: 'user-count-report',
        component: UserCountComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },

      },
      {
        path: 'policy-count-report',
        component: PolicyCountComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'sum-net-premium',
        component: NetpremiumSumComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'user-transacting',
        component: UserTransactingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'admin-claims-monitoring',
        component: CoopClaimsMonitoringComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      { 
        path: 'renewal-notice-list',
        component: RenewalNoticeListComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1},
      },
      { 
        path: 'credit-line',
        component: CreditLineApprovalComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'check-points',
        component: CheckPointsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'above-retention-limit',
        component: AboveRetentionLimitComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'user-commission/:code',
        component: AdminUserCommissionComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },

      {
        path: 'credit-payment',
        component: CreditPaymentApprovalComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },

      {
        path: 'admin-billing-payment',
        component: AdminBillingGenerateComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'admin-transaction-history',
        component: AdminTransactionHistoryComponent,
        canActivate: [AuthGuardService],
         data: { userType: 1 },
      },
      {
        path: 'fire-issue/:id/quoteId/:quoteId/:login_id',
        component: FireInsureComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      { 
        path: 'comprehensive/quoteId/:quoteId/:login_id',
        component: ComprehensiveComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      { 
        path: 'standard-fire/quoteId/:quoteId/:login_id',
        component: StandardFireComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'CAC-products/:id/quoteId/:quoteId/:login_id',
        component: CacProductsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 },
      },
      {
        path: 'group-access',
        component: GroupAccessComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 }
      },
      {
        path: 'migrate-to-nonlife',
        component: MigrationToNonlifeComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 }
      },
      {
        path: 'wallet-balance-per-user',
        component: WalletBalancePerUserComponent,
        canActivate: [AuthGuardService],
        data: { userType: 1 }
      }
     
   
    

    ]
  },
  {
    path: 'coop',
    component: CoopComponent,
    canActivate: [AuthGuardService],
    data: { userType: 6 },

    children: [
      {
        path: 'profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-production-history',
        component: CoopProductionHistoryComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-claims-submission',
        component: CoopClaimsSubmissionComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-claims-monitoring',
        component: CoopClaimsMonitoringComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-cac-chart',
        component: CacProductionReportChartComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-wallet',
        component: CacWalletComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'FAQ',
        component: CoopFAQComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-quotation-issued-policy-count-report',
        component: CacIssuedPolicyCountReportComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      { 
        path: 'policy-issuance/quoteId/:quoteId/:login_id',
        component: CTPLComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      { 
        path: 'policy-issued',
        component: IssuedPolicyComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      { 
        path: 'comprehensive/quoteId/:quoteId/:login_id',
        component: ComprehensiveComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      { 
        path: 'standard-fire/quoteId/:quoteId/:login_id',
        component: StandardFireComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'issued-policy-quotation',
        component: IssuedPolicyQuotationComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'admin-remittance-uploading',
        component: AdminRemittanceUploadingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'adminRemittance',
        component: AdminRemittanceManagementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-claims-submission',
        component: CoopClaimsSubmissionComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'coop-claims-monitoring',
        component: CoopClaimsMonitoringComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      { 
        path: 'renewal-notice-list',
        component: RenewalNoticeListComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6},
      },
      {
        path: 'endorsement',
        component: EndorsementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'billing',
        component: BillingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },

      },
      {
        path: 'under-agent-list',
        component: UnderAgentListComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'policy-extension',
        component: PolicyExtensionComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'billing-payment-history',
        component: BillingPaymentHistoryComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },

      {
        path: 'billing-generate',
        component: BillingGenerateComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'user-commission/:code/type/:type',
        component: AdminUserCommissionComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'fire-issue/:id/quoteId/:quoteId/:login_id',
        component: FireInsureComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },
      {
        path: 'CAC-products/:id/quoteId/:quoteId/:login_id',
        component: CacProductsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 6 },
      },

    ]
  },

  {
    path: 'gam',
    component: GamComponent,
    canActivate: [AuthGuardService],
    data: { userType: 3 },
    children: [
        { 
          path: 'gam-FAQ',
          component: CoopFAQComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'gam-Contact-us',
          component: AdminContactUsComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'gam-wallet',
          component: CacWalletComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'gam-profile/:id/view',
          component: ProfileViewComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'gam-production-report',
          component: GamProductionReportComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'gam-production-report-chart',
          component: CacProductionReportChartComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'gam-quotation-issued-policy-count-report',
          component: CacIssuedPolicyCountReportComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'policy-issuance/quoteId/:quoteId/:login_id',
          component: CTPLComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'setting',
          component: SettingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'policy-issued',
          component: IssuedPolicyComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'feedback-us',
          component: FeedbackUsComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'issued-policy-quotation',
          component: IssuedPolicyQuotationComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'comprehensive/quoteId/:quoteId/:login_id',
          component: ComprehensiveComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        { 
          path: 'standard-fire/quoteId/:quoteId/:login_id',
          component: StandardFireComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'admin-remittance-uploading',
          component: AdminRemittanceUploadingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'adminRemittance',
          component: AdminRemittanceManagementComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'coop-claims-submission',
          component: CoopClaimsSubmissionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
  
        },
        {
          path: 'coop-claims-monitoring',
          component: CoopClaimsMonitoringComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
  
        },
        { 
          path: 'renewal-notice-list',
          component: RenewalNoticeListComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3},
        },
        {
          path: 'endorsement',
          component: EndorsementComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'billing',
          component: BillingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'under-agent-list',
          component: UnderAgentListComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'policy-extension',
          component: PolicyExtensionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'homepage',
          component: HomepageComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'user-commission/:code/type/:type',
          component: AdminUserCommissionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'ibisa-forecast',
          component: IbisaForecastComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
        {
          path: 'fire-issue/:id/quoteId/:quoteId/:login_id',
          component: FireInsureComponent,
          canActivate: [AuthGuardService],
          data: { userType: 3 },
        },
      
    ]
  },  

  {
    path: 'cac',
    component: CacComponent,
    canActivate: [AuthGuardService],
    data: { userType: 4 },

    children: [
        { 
          path: 'cac-FAQ',
          component: CoopFAQComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'cac-Contact-us',
          component: AdminContactUsComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'cac-wallet',
          component: CacWalletComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'cac-profile/:id/view',
          component: ProfileViewComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'cac-production-report',
          component: CacProductionReportComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'cac-production-report-chart',
          component: CacProductionReportChartComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'cac-quotation-issued-policy-count-report',
          component: CacIssuedPolicyCountReportComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'policy-issuance/quoteId/:quoteId/:login_id',
          component: CTPLComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'setting',
          component: SettingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'policy-issued',
          component: IssuedPolicyComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
   
        {
          path: 'admin-remittance-uploading',
          component: AdminRemittanceUploadingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'adminRemittance',
          component: AdminRemittanceManagementComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'issued-policy-quotation',
          component: IssuedPolicyQuotationComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'feedback-us',
          component: FeedbackUsComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'under-agent-list',
          component: UnderAgentListComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'CAC-products/:id/quoteId/:quoteId/:login_id',
          component: CacProductsComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'coop-claims-submission',
          component: CoopClaimsSubmissionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'coop-claims-monitoring',
          component: CoopClaimsMonitoringComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'billing',
          component: BillingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
  
        },
        { 
          path: 'renewal-notice-list',
          component: RenewalNoticeListComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4},
        },
        {
          path: 'endorsement',
          component: EndorsementComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
         { 
          path: 'comprehensive/quoteId/:quoteId/:login_id',
          component: ComprehensiveComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        { 
          path: 'standard-fire/quoteId/:quoteId/:login_id',
          component: StandardFireComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'policy-extension',
          component: PolicyExtensionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'homepage',
          component: HomepageComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'billing-payment-history',
          component: BillingPaymentHistoryComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },

        {
          path: 'billing-generate',
          component: BillingGenerateComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'user-commission/:code/type/:type',
          component: AdminUserCommissionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'ibisa-forecast',
          component: IbisaForecastComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'fire-issue/:id/quoteId/:quoteId/:login_id',
          component: FireInsureComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
        {
          path: 'surity-bond/:id/quoteId/:quoteId/:login_id',
          component: SurityBondComponent,
          canActivate: [AuthGuardService],
          data: { userType: 4 },
        },
    ]
  }, 
  {
    path: 'endorsement-officer',
    component: EndorsementOfficerComponent,
    canActivate: [AuthGuardService],
    data: { userType: 7 },
    children: [
      {
        path: 'endorsement',
        component: EndorsementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 7 },
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 7 },
      },
      { 
        path: 'cac-Contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 7 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 7 },
      },
      { 
        path: 'cac-profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 7 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 7 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 7 },
      },
    ]
  },
  {
    path: 'DQC-officer',
    component: DQCCenterComponent,
    canActivate: [AuthGuardService],
    data: { userType: 8 },
    children: [
      {
        path: 'adminRemittance',
        component: AdminRemittanceManagementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 8 },
      },
      {
        path: 'admin-remittance-uploading',
        component: AdminRemittanceUploadingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 8 },
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 8 },
      },
      { 
        path: 'cac-Contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 8 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 8 },
      },
      { 
        path: 'cac-profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 8 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 8 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 8 },
      },
    ]
  },  
  {
    path: 'quotation-approval-officer',
    component: QuotationApprovalOfficerComponent,
    canActivate: [AuthGuardService],
    data: { userType: 9 },
    children: [
      {
        path: 'quotation-approval',
        component: AdminQuotationApprovalComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      { 
        path: 'cac-Contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      { 
        path: 'cac-profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      {
        path: 'quotation-details/:id',
        component: AdminQuotationDetailsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      {
        path: 'fire-issue/:id/quoteId/:quoteId/:login_id',
        component: FireInsureComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      { 
        path: 'comprehensive/quoteId/:quoteId/:login_id',
        component: ComprehensiveComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      { 
        path: 'standard-fire/quoteId/:quoteId/:login_id',
        component: StandardFireComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
      {
        path: 'CAC-products/:id/quoteId/:quoteId/:login_id',
        component: CacProductsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 9 },
      },
    ]
  },      
  {
    path: 'cashin-approval-officer',
    component: CashinApprovalOfficerComponent,
    canActivate: [AuthGuardService],
    data: { userType: 10 },
    children: [
      {
        path: 'Cash-In-Approval',
        component: AdminCacCashInApprovalComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      { 
        path: 'cac-Contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      { 
        path: 'cac-profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      { 
        path: 'credit-line',
        component: CreditLineApprovalComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      {
        path: 'check-points',
        component: CheckPointsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      {
        path: 'credit-payment',
        component: CreditPaymentApprovalComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      {
        path: 'billing-payment',
        component: AdminBillingGenerateComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      {
        path: 'production-history',
        component: AdminProductionHistoryComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      {
        path: 'users',
        component: AdminUserManagementComponent,
        canActivate: [AuthGuardService],
         data: { userType: 10 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
      {
        path: 'orIssuance',
        component: OrIssuanceComponent,
        canActivate: [AuthGuardService],
         data: { userType: 10 },
      },
      {
        path: 'admin-transaction-history',
        component: AdminTransactionHistoryComponent,
        canActivate: [AuthGuardService],
         data: { userType: 10 },
      },
      {
        path: 'wallet-balance-per-user',
        component: WalletBalancePerUserComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 }
      },
      {
        path: 'endorsement',
        component: EndorsementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 10 },
      },
    ]
  },   
  {
    path: 'claims-officer',
    component: ClaimsOfficerComponent,
    canActivate: [AuthGuardService],
    data: { userType: 11 },
    children: [
      {
        path: 'admin-claims-monitoring',
        component: CoopClaimsMonitoringComponent,
        canActivate: [AuthGuardService],
        data: { userType: 11 },
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 11 },
      },
      { 
        path: 'cac-Contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 11 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 11 },
      },
      { 
        path: 'cac-profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 11 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 11 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 11 },
      },
      
    ]
  },  
  {
    path: 'user-management-officer',
    component: UserManagementOfficerComponent,
    canActivate: [AuthGuardService],
    data: { userType: 12 },
    children: [
      // {
      //   path: 'agent-approval',
      //   component: AgentApprovalComponent,
      //   canActivate: [AuthGuardService],
      //   data: { userType: 12 },
      // },
      {
        path: 'users',
        component: AdminUserManagementComponent,
        canActivate: [AuthGuardService],
         data: { userType: 12 },
      },
      {
        path: 'coop-api-user',
        component: CoopApiUserComponent,
        canActivate: [AuthGuardService],
        data: { userType: 12 },

      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 12 },
      },
      { 
        path: 'cac-Contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 12 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 12 },
      },
      { 
        path: 'cac-profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 12 },
      },
      {
        path: 'users/:id/update',
        component: AdminUserManagementUpdateComponent,
        canActivate: [AuthGuardService],
         data: { userType: 12 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 12 },
      }, 
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 12 },
      },
      {
        path: 'user-commission/:code',
        component: AdminUserCommissionComponent,
        canActivate: [AuthGuardService],
        data: { userType: 12 },
      },
    ]
  },  
  {
    path: 'agent',
    component: AgentComponent,
    canActivate: [AuthGuardService],
    data: { userType: 5},
    children: [
        { 
          path: 'cac-FAQ',
          component: CoopFAQComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'cac-Contact-us',
          component: AdminContactUsComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'cac-wallet',
          component: CacWalletComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'cac-profile/:id/view',
          component: ProfileViewComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'agent-production-report',
          component: AgentProductionHistoryComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'cac-production-report-chart',
          component: CacProductionReportChartComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'cac-quotation-issued-policy-count-report',
          component: CacIssuedPolicyCountReportComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'policy-issuance/quoteId/:quoteId/:login_id',
          component: CTPLComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'setting',
          component: SettingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'policy-issued',
          component: IssuedPolicyComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'comprehensive/quoteId/:quoteId/:login_id',
          component: ComprehensiveComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        { 
          path: 'standard-fire/quoteId/:quoteId/:login_id',
          component: StandardFireComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        {
          path: 'issued-policy-quotation',
          component: IssuedPolicyQuotationComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        {
          path: 'feedback-us',
          component: FeedbackUsComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        {
          path: 'CAC-products/:id/quoteId/:quoteId/:login_id',
          component: CacProductsComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        {
          path: 'coop-claims-submission',
          component: CoopClaimsSubmissionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        {
          path: 'coop-claims-monitoring',
          component: CoopClaimsMonitoringComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5},
        },
        {
          path: 'admin-remittance-uploading',
          component: AdminRemittanceUploadingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },
        {
          path: 'adminRemittance',
          component: AdminRemittanceManagementComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },
        {
          path: 'policy-extension',
          component: PolicyExtensionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },
        {
          path: 'homepage',
          component: HomepageComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },

        {
          path: 'billing',
          component: BillingComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
  
        },
        {
          path: 'fire-issue/:id/quoteId/:quoteId/:login_id',
          component: FireInsureComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },
        {
          path: 'endorsement',
          component: EndorsementComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },

        {
          path: 'billing-payment-history',
          component: BillingPaymentHistoryComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },
  
        {
          path: 'billing-generate',
          component: BillingGenerateComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },
        {
          path: 'user-commission/:code/type/:type',
          component: AdminUserCommissionComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },
        {
          path: 'ibisa-forecast',
          component: IbisaForecastComponent,
          canActivate: [AuthGuardService],
          data: { userType: 5 },
        },
    ]
  }, 
  {
    path: 'renewalOfficer',
    component: RenewalNoticeOfficerComponent,
    canActivate: [AuthGuardService],
    data: { userType: 13},
    children: [
      { 
        path: 'renewal-notice-list',
        component: RenewalNoticeListComponent,
        canActivate: [AuthGuardService],
        data: { userType: 13},
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 13 },
      },
      { 
        path: 'cac-Contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 13 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 13 },
      },
      { 
        path: 'profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 13 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 13 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 13 },
      },
    ]
    
  },
  {
    path: 'cashier',
    component: CashierComponent,
    canActivate: [AuthGuardService],
    data: { userType: 14 },
    children: [
      {
        path: 'adminRemittance',
        component: AdminRemittanceManagementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 14 },
      },
      {
        path: 'admin-remittance-uploading',
        component: AdminRemittanceUploadingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 14 },
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 14 },
      },
      { 
        path: 'cac-Contact-us',
        component: AdminContactUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 14 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 14 },
      },
      { 
        path: 'cac-profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
        data: { userType: 14 },
      },
      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 14 },
      },
      {
        path: 'ibisa-forecast',
        component: IbisaForecastComponent,
        canActivate: [AuthGuardService],
        data: { userType: 14 },
      },
   
    ]
  }, 
  {
    path: 'reporter',
    component: ReporterComponent,
    canActivate: [AuthGuardService],
    data: { userType: 15 },
    children: [

      {
        path: 'homepage',
        component: HomepageComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      { 
        path: 'setting',
        component: SettingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      {
        path: 'feedback-us',
        component: FeedbackUsComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      {
        path: 'profile/:id/view',
        component: ProfileViewComponent,
        canActivate: [AuthGuardService],
         data: { userType: 15 },
      },
      {
        path: 'profileEdit',
        component: ProfileEditComponent,
        canActivate: [AuthGuardService],
         data: { userType: 15 },
      },
      {
        path: 'production-history',
        component: ProductionHistoryComponent,
        canActivate: [AuthGuardService],
         data: { userType: 15 },
      },
      { 
        path: 'FAQ',
        component: CoopFAQComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },

      {
        path: 'endorsement',
        component: EndorsementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      {
        path: 'adminRemittance',
        component: AdminRemittanceManagementComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      { 
        path: 'renewal-notice-list',
        component: RenewalNoticeListComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15},
      },
      {
        path: 'user-commission/:code/type/:type',
        component: AdminUserCommissionComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      {
        path: 'under-agent-list',
        component: UnderAgentListComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      {
        path: 'billing-payment-history',
        component: BillingPaymentHistoryComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      {
        path: 'billing-generate',
        component: BillingGenerateComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      {
        path: 'billing',
        component: BillingComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      { 
        path: 'gam-wallet',
        component: CacWalletComponent,
        canActivate: [AuthGuardService],
        data: { userType: 15 },
      },
      
    ] 
  
  }, 
  
  
  { 
    path: '',
    redirectTo: '/welcome/home',
    pathMatch: 'full',
  },
  { 
    path: '**',
    component: PageNotFoundComponent  
  },
  { 
    path: 'page-not-found',
    component: PageNotFoundComponent  
  },
];

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    WelcomeHomeComponent,
    AdminComponent,
    AdminUserManagementComponent,
    SearchPipe,
    AdminUserManagementUpdateComponent,
    CoopComponent,
    ProfileViewComponent,
    // ProfileUpdateComponent,
    // WelcomeHomeParamsComponent,
    SanitizeHtmlPipe,
    TruncatePipe,
    ProfileEditComponent,
    AdminRemittanceManagementComponent,
    CoopProductionHistoryComponent,
    AdminProductionHistoryComponent,
    AdminContactUsComponent,
    AdminFeedbackComponent,
    CoopClaimsSubmissionComponent,
    CoopClaimsMonitoringComponent,
    AdminCacCoopinfoComponent,
    AdminCacChartComponent,
    PcrDashboardComponent,
    PcrDetailedCollectionReportComponent,
    AdminRemittanceUploadingComponent,
    AdminCacCashInApprovalComponent,
    CoopFAQComponent,
    GamComponent,
    GamProductionReportComponent,
    PageNotFoundComponent,
    CacComponent,
    CacProductionReportComponent,
    CacProductionReportChartComponent,
    CacWalletComponent,
    AdminQuotationApprovalComponent,
    IssuedPolicyCountReportComponent,
    CacIssuedPolicyCountReportComponent,
    CTPLComponent,
    AdminQuotationDetailsComponent,
    IssuedPolicyComponent,
    SettingComponent,
    EndorsementComponent,
    ComprehensiveComponent,
    StandardFireComponent,
    IssuedPolicyQuotationComponent,
    CreateInsurtechAnnouncementComponent,
    FeedbackUsComponent,
    UpdateInsurtechVersionComponent,
    ProductManagementComponent,
    UnderAgentListComponent,
    CoopApiUserComponent,
    CacProductsComponent,
    UserCountComponent,
    UserCountComponent,
    PolicyCountComponent,
    NetpremiumSumComponent,
    UserTransactingComponent,
    EndorsementOfficerComponent,
    DQCCenterComponent,
    QuotationApprovalOfficerComponent,
    CashinApprovalOfficerComponent,
    ClaimsOfficerComponent,
    UserManagementOfficerComponent,
    AgentComponent,
    RenewalNoticeListComponent,
    RenewalNoticeOfficerComponent,
    BillingComponent,
    CreditLineApprovalComponent,
    FireInsureComponent,
    PolicyExtensionComponent,
    HomepageComponent,
    CashierComponent,
    CheckPointsComponent,
    AboveRetentionLimitComponent,
    SanitizeUrlService,
    AgentProductionHistoryComponent,
    BillingPaymentHistoryComponent,
    BillingGenerateComponent,
    AdminUserCommissionComponent,
    BranchReportsComponent,
    CreditPaymentApprovalComponent,
    AdminBillingGenerateComponent,
    IbisaForecastComponent,
    ReportsComponent,
    OrIssuanceComponent,
    ReporterComponent,
    ProductionHistoryComponent,
    AdminTransactionHistoryComponent,
    SurityBondComponent,
    GroupAccessComponent,
    MigrationToNonlifeComponent,
    WalletBalancePerUserComponent,


 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    FormsModule,
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key',
    }),
    RouterModule.forRoot(
      appRoutes, 
      {
        enableTracing: false, // <-- debugging purposes only
        useHash: true,
      }
    ),
    ReactiveFormsModule,
    NgxSpinnerModule,
    StepsModule,
    AccordionModule,
    ButtonModule,
    SelectButtonModule,
    ToolbarModule,
    CardModule,
    TabMenuModule,
    ChartModule,
    SliderModule,
    PaginatorModule,
    TableModule,
    MenubarModule,
    DropdownModule,
    CalendarModule,
    AutoCompleteModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap:      [ AppComponent ],
  schemas:        [ NO_ERRORS_SCHEMA ],
  entryComponents:[

  ],
})
export class AppModule { }
