import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  url = environment.url;
  
  constructor(
    private http: HttpClient
  ) { }
  

  pending_quotation(data){
    return this.http.post(`${this.url}/pending_quotation`,data);
  }

  approval(attachment, id){
    return this.http.post(`${this.url}/approval/`+id, attachment);
  }

  view_quotation(id){
    return this.http.get(`${this.url}/view_quotation/` +id);
  }

  // view_quotation1(id){
  //   return this.http.get(`${this.url}/view_quotation/` +id).toPromise();
  // }

  getBenefitRates(){
    return this.http.get(`${this.url}/policyIssue/getBenefitRates`);
    
  }

  getPaymentList(){
    return this.http.get(`${this.url}/policyIssue/getPaymentList`);
    
  }

  cancelQuote(quoteId,remarks){
    return this.http.get(`${this.url}/policyIssue/cancelQuote?remarks=`+remarks+'&quotId='+quoteId);
  }


  pendingQuote(quoteId,remarks){
    return this.http.get(`${this.url}/policyIssue/pendingQuote?remarks=`+remarks+'&quotId='+quoteId);
  }

  // save_new_compre_update(data){
  //   return this.http.post(`${this.url}/policyIssue/save_new_compre_update`, data  );

  // }


  syncCommissionPremiumByLoginType(loginId, productId) {
		return this.http.get(`${this.url}/policyIssue/getCommPremByLoginType?loginId=`+loginId+'&productId='+productId);
	}

  download(id){
		return this.http.get(`${this.url}/download/` + id);
  }


  quotationPDF(quoteId){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    
		return this.http.get(`${this.url}/quotationPDF/`+quoteId, httpOptions);
  }



}
