import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupClientService {
  url = environment.url;

  constructor(private http: HttpClient) {}

  searchGroupClient(searchText) {
    return this.http.post(this.url + '/groupclients/search', searchText).pipe(map(res => {
      return res;
    }));
  }

  updateCoopInfo(formData,coopNum){
    return this.http.post(this.url + '/groupclients/update/'+coopNum,formData )
  }

  lifeProductLine(coopNum){
    return this.http.get(this.url + '/lineproductline/'+coopNum )
  }

  nonlifeProductLine(coopNum){
    return this.http.get(this.url + '/nonlineproductline/'+coopNum )
  }

  loadGroupClient(userLoggedInData){
    return this.http.get(this.url + '/groupclients/'+userLoggedInData )
  }

  lifeDetailedReport(dateRange,option){
    return this.http.post(this.url + '/lifeDetailedReport/' + option,dateRange)
  }

  // nonlife reporting
  nonlifeDetailedReportPerMain(dateRange,option){
    return this.http.post(this.url + '/nonlifeDetailedReportPerMain/' + option,dateRange)
  }

  nonlifeDetailedReportPerBranch(dateRange,option){
    return this.http.post(this.url + '/nonlifeDetailedReportPerBranch/' + option,dateRange)
  }

  nonlifeDetailedReportPerMainProduct(dateRange,option){
    return this.http.post(this.url + '/nonlifeDetailedReportPerMainProduct/' + option,dateRange)
  }

  nonlifeDetailedReportPerBranchProduct(dateRange,option){
    return this.http.post(this.url + '/nonlifeDetailedReportPerBranchProduct/' + option,dateRange)
  }

  nonlifeDetailedReportDetailed(dateRange,option){
    return this.http.post(this.url + '/nonlifeDetailedReportDetailed/' + option,dateRange)
  }

  //life reporting
  lifeDetailedReportPerMain(dateRange,option){
    return this.http.post(this.url + '/lifeDetailedReportPerMain/' + option,dateRange)
  }
  
  lifeDetailedReportPerBranch(dateRange,option){
    return this.http.post(this.url + '/lifeDetailedReportPerBranch/' + option,dateRange)
  }
  
  lifeDetailedReportPerMainProduct(dateRange,option){
    return this.http.post(this.url + '/lifeDetailedReportPerMainProduct/' + option,dateRange)
  }
  
  lifeDetailedReportPerBranchProduct(dateRange,option){
    return this.http.post(this.url + '/lifeDetailedReportPerBranchProduct/' + option,dateRange)
  }
  
  lifeDetailedReportDetailed(dateRange,option){
    return this.http.post(this.url + '/lifeDetailedReportDetailed/' + option,dateRange)
  }

  //for exporting report
  exportExcel(dateRange,option){
    return this.http.post(this.url + '/exportExcel/' + option,dateRange)
  }

}
