import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import Swal from 'sweetalert2';
import { ModalDirective  } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router,ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { whitespaceValidation } from '../../../customValidation/whitespace.validation';
import { CacProductService } from '../../../services-http/cac-product/cac-product.service';
import { validateEffectivityDate } from '../../../customValidation/setEffectivityDate.validation';
import * as moment from 'moment';
import { QuotationService } from '../../../services-http/quotation/quotation.service';
import { debounceTime } from "rxjs/operators";
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-comprehensive',
  templateUrl: './comprehensive.component.html',
  styleUrls: ['./comprehensive.component.scss']
})
export class ComprehensiveComponent implements OnInit {
  @ViewChild('basicModal1') basicModal1: ModalDirective;
  @ViewChild('basicModal2') basicModal2: ModalDirective;
  @ViewChild('basicModal3') basicModal3: ModalDirective;
  @ViewChild('basicModal4') basicModal4: ModalDirective;
 

  formData = new FormData();
  allAccessories:any = [];
  show:boolean = true;
  classifications:any = []
  vehicleListing:any = [];
  loading:boolean = false;
  User = JSON.parse(localStorage.getItem('currentUser'));
  index:any=0;
  BIcoverage:any=[];
  APAcoverage:any=[];
  basicPremium:any=0;
  netPremium:any=0;
  waivedCommission:any = 0;
  total_calc_commission:any = 0;
  memberType:any = "memberAssociateMember";
  items: MenuItem[];
  types: SelectItem[];
  dialogIndex:number = 0;
  quoteId:any;
  url=this.router.url;
  furl;
  rateOd:any;
  surcharge:any=0;
  productInfo:any={points:{credit:0,regular:0}};
  carAge:any=0;
  noOfPassenger:any=0
  totalCoverage:any=0;
  NP_deductible:any=0;
  OD_deductible:any=0;
  posting_type:any=0;
  show1:any;
  attachment:any=[];
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  now1 = this.now.setDate(this.now.getDate() - 45);
  minDate:any = moment(this.now1).format('YYYY-MM-DD');
  quoteData:any;
  prev_policy_no:any ='';
  new_business:boolean = false; 
  searchListShow:boolean = false;
  searchitems:any={};
  searchResult:any = [];
  isLoading:boolean = false;
  referer:any='';
  login_id:any;
  indexpd;


  accessories = new FormGroup({
    amount: new FormControl('',[Validators.required, Validators.pattern(/^[0-9.,]*$/)] ),
    name: new FormControl('',Validators.required),
  })

  assuredDetailsMemberAssociate = new FormGroup({
    lastname: new FormControl('', Validators.required),
    firstname: new FormControl('', Validators.required),
    middlename: new FormControl('', Validators.required),
    suffix: new FormControl(''),
    gender: new FormControl('', Validators.required),
    contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(12)] ),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    address: new FormControl('', Validators.required),
    zipCode: new FormControl('', Validators.required),
    cid: new FormControl(''),
    branch:new FormControl(''),
    remarks: new FormControl(''),
    coop_no: new FormControl('')
  });


  assuredDetailsCorporate = new FormGroup({
    assuredName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    address: new FormControl('', Validators.required),
    contact: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(11), Validators.maxLength(12)] ),
    zipCode: new FormControl('', Validators.required),
    branch: new FormControl(''),
    cid: new FormControl(''),
    coop_no: new FormControl('')
  });


  vehicleDetails = new FormGroup({
    classification: new FormControl('', Validators.required),
    vehicle: new FormControl('', Validators.required),
    yearModel: new FormControl('', [Validators.required,Validators.pattern("^[0-9]*$")]),
    series: new FormControl('', Validators.required),
    make: new FormControl('', Validators.required),
    color: new FormControl('', Validators.required),
    plateNo: new FormControl('', [Validators.required,whitespaceValidation.cannotContainSpace]),
    bodyType: new FormControl('', Validators.required),
    MVFile: new FormControl('', [Validators.required, Validators.minLength(15), Validators.maxLength(15), whitespaceValidation.cannotContainSpace] ),
    chassis: new FormControl('', [Validators.required,whitespaceValidation.cannotContainSpace]),
    engineNo: new FormControl('', [Validators.required,whitespaceValidation.cannotContainSpace]),
    mortgaged: new FormControl("no", Validators.required),
    mortgagee: new FormControl(''),
    carValue: new FormControl('', [Validators.required, Validators.pattern(/^[0-9.,]*$/)]),
    renew: new FormControl(false, [Validators.required]),
    manila: new FormControl(0),
    prorated: new FormControl(false, [Validators.required]),
    effectivity_date: new FormControl(''),
    expiry_date: new FormControl('')
  });

 
  constructor(
    private IssuePolicy: IssuePolicyService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private cacProduct:CacProductService,
    private quotation: QuotationService,
  ) {
    route.params.subscribe(val => {
      this.quoteId=val.quoteId;
      this.login_id=val.login_id;

    });
  }

  nextPage(){
    this.dialogIndex++;
  }

  previousPage(){
    this.dialogIndex--;
  }


  ngOnInit() {

    this.furl=this.url.split('/')[1];
    this.items = [
      {label: 'Assured'},
      {label: 'Vehicle'},
      {label: 'Accessories'},
      {label: 'Benefit'},
      {label: 'Attachment'},
      {label: 'Computation'}
    ];

    this.getProductInfo();

    let now:any = new Date();

    this.vehicleDetails.patchValue({
      effectivity_date: moment(now).format('YYYY-MM-DD'),
      expiry_date: moment(now.setDate(now.getDate()+365)).format('YYYY-MM-DD')
    });



    this.vehicleDetails.get("effectivity_date").valueChanges.subscribe(x => {

      console.log(x, this.vehicleDetails.value.renew)
      if(this.vehicleDetails.value.renew){
      
        let ex:any = new Date(x);

        this.vehicleDetails.patchValue({
          expiry_date: moment(ex.setDate(ex.getDate()+365)).format('YYYY-MM-DD')
       
        })
        
        
      }
  
    });  




    this.vehicleDetails.get("renew").valueChanges.subscribe(x => {
      if(x){
        this.vehicleDetails.get("effectivity_date").setValidators([Validators.required, validateEffectivityDate.valEffDate]);
      }
      else{
       
        this.vehicleDetails.get("effectivity_date").clearValidators();
        this.vehicleDetails.get('effectivity_date').setErrors(null); 
      }
      this.vehicleDetails.get("effectivity_date").updateValueAndValidity();
    });  
   
    // tagum cid
    this.assuredDetailsMemberAssociate.get("cid").valueChanges
      .pipe(debounceTime(3000))
      .subscribe(res => {
        if(res){
          const Http = new XMLHttpRequest();
          const url= environment.tagumAPI+'/get-profile/'+res;
          Http.open("GET", url);
          Http.setRequestHeader( 'Content-Type', 'application/json');
          Http.setRequestHeader("Authorization", `Bearer ${environment.token_tagum}`);
          Http.send();
  
          Http.onreadystatechange = (e)=>{
            let assured=JSON.parse(Http.response);

            if(Http.status==200){
              this.assuredDetailsMemberAssociate
                .patchValue({
                  lastname: assured.Message.appData.lastname,
                  firstname: assured.Message.appData.firstname,
                  middlename: assured.Message.appData.middlename,
                  suffix: assured.Message.appData.suffix,
                  gender: assured.Message.appData.gender.toLowerCase(),
                  contact: assured.Message.appData.contactno,
                  email:assured.Message.appData.email,
                  address: assured.Message.appData.address,
                  zipCode: assured.Message.appData.zipcode
              });
            }
            else{
              this.assuredDetailsMemberAssociate.reset();
              Swal.fire(
                'Oppss',
                assured.Message +' ('+ res +')',
                'error'
              );
            }  
          }
        }
    });


    this.assuredDetailsMemberAssociate.get("coop_no").valueChanges
      .pipe(debounceTime(2000))
      .subscribe(res => {
        if(res && typeof(res)==='string'){
         this.search(res)
        }
    });

    this.assuredDetailsCorporate.get("coop_no").valueChanges
    .pipe(debounceTime(2000))
    .subscribe(res => {
      if(res && typeof(res)==='string'){
       this.search(res)
      }
    });
  }


  closeSearchList(){
    this.searchListShow = false;
  }


  searchItem(searhItem){

    this.searchListShow = false;
    this.searchitems = searhItem;
    this.assuredDetailsMemberAssociate.patchValue({coop_no: searhItem.CoopNum});
    this.assuredDetailsCorporate.patchValue({coop_no: searhItem.CoopNum});
    this.assuredDetailsCorporate.patchValue({assuredName: searhItem.Name});
  }


  search(res){
    this.isLoading = true;

    let data = {
      search:res
    }
        
    this.cacProduct.searchGroupClient(data).subscribe((res:any)=>{
      this.spinner.hide();
     
      this.isLoading = false; 
      this.searchListShow =true;
      this.searchResult = res;  
    },error=>{
      this.isLoading = false;
      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }


  async viewPolicyDetails(){
    this.quoteData = await this.quotation.view_quotation(this.quoteId).toPromise();

    this.new_business = this.quoteData[0].statusid==7? true: false;

    this.prev_policy_no = this.quoteData[0].policyno;

    this.memberType=this.quoteData[0].assured.assuredType;

    if(this.quoteData[0].assured.assuredType=="memberAssociateMember"){
      this.assuredDetailsMemberAssociate.patchValue({
        lastname  :this.quoteData[0].assured.lastName,
        firstname :this.quoteData[0].assured.firstName,
        middlename:this.quoteData[0].assured.middleName,
        suffix    :this.quoteData[0].assured.suffix,
        gender    :this.quoteData[0].gender,
        contact   :this.quoteData[0].contactNo,
        email     :this.quoteData[0].emailAddress,
        address   :this.quoteData[0].address,
        zipCode   :this.quoteData[0].zipCode, 
        coop_no   :+this.quoteData[0].coopNo     

      });
    }
    if(this.quoteData[0].assured.assuredType=="corporate"||this.quoteData[0].assured.assuredType=="cooperative"){
      this.assuredDetailsCorporate.patchValue({
        assuredName :this.quoteData[0].assured.assuredName,
        email       :this.quoteData[0].emailAddress,
        address     :this.quoteData[0].address,
        contact     :this.quoteData[0].contactNo,
        zipCode     :this.quoteData[0].zipCode, 
        coop_no     :+this.quoteData[0].coopNo 
      });
    }
    this.vehicleListing=this.productInfo.prod.find(x=>x.id==this.quoteData[0].vehicle.productNo);

    this.vehicleDetails.patchValue({
      yearModel        :this.quoteData[0].vehicle.yearModel,
      series           :this.quoteData[0].vehicle.model,
      make             :this.quoteData[0].vehicle.make,
      color            :this.quoteData[0].vehicle.color,
      plateNo          :this.quoteData[0].vehicle.plateNo,
      bodyType         :this.quoteData[0].vehicle.bodyType,
      MVFile           :this.quoteData[0].vehicle.mvFileNo,
      chassis          :this.quoteData[0].vehicle.chassisNo,
      engineNo         :this.quoteData[0].vehicle.engineNo,
      carValue         :this.quoteData[0].vehicle.carValue,
      classification   :this.quoteData[0].vehicle.productNo,
      vehicle          :this.quoteData[0].vehicle.optionNo
    });



    this.quoteData[0].accessories.forEach(row => {
      this.allAccessories.push({
        name: row.accessoriesName,
        amount: row.coverage
      });
    });


    this.quoteData[0].upload.forEach(row => {

      this.attachment.push({
        'url': row.file_url,
        'file': row.file_url,
        'label':row.filelabel
      });
    });


  }
  

  checkCarAge(){
    
    let carAges:any= (new Date()).getFullYear() - this.vehicleDetails.value.yearModel;

    if(carAges<=3){
      this.carAge= 1;
    }
    else{
      this.carAge= 5;
    }
  }

  
  getProductInfo(){
    let data={
      variantId: 2,
      login_id : this.quoteId>0? this.login_id: this.User.iloginId,
      planNo   : 5
    }
    this.IssuePolicy.getProductInfo(data).subscribe((res:any)=>{

      if(this.quoteId>0){
        this.viewPolicyDetails();
      }
      this.productInfo=res;
      if(this.productInfo.canSave=="0"){
        Swal.fire(
          'Alert',
          'This product '+this.productInfo.varlongname+' is currently disabled.',
          'warning'
        );
      }
    },error=>{
      Swal.fire(
        'Oppss',
        'Something went wrong.',
        'error'
      );
    });
  }


  get lastname() { return this.assuredDetailsMemberAssociate.get('lastname'); }
  get firstname() { return this.assuredDetailsMemberAssociate.get('firstname'); }
  get middlename() { return this.assuredDetailsMemberAssociate.get('middlename'); }
  get suffix() { return this.assuredDetailsMemberAssociate.get('suffix'); }
  get gender() { return this.assuredDetailsMemberAssociate.get('gender'); }
  get contact() { return this.assuredDetailsMemberAssociate.get('contact'); }
  get email() { return this.assuredDetailsMemberAssociate.get('email'); }
  get address() { return this.assuredDetailsMemberAssociate.get('address'); }
  get zipCode() { return this.assuredDetailsMemberAssociate.get('zipCode'); }
  get cid() { return this.assuredDetailsMemberAssociate.get('cid'); }
  get branch() { return this.assuredDetailsMemberAssociate.get('branch'); }
  get assuredName() { return this.assuredDetailsCorporate.get('assuredName'); }
  get email1() { return this.assuredDetailsCorporate.get('email'); }
  get address1() { return this.assuredDetailsCorporate.get('address'); }
  get contact1() { return this.assuredDetailsCorporate.get('contact'); }
  get zipCode1() { return this.assuredDetailsCorporate.get('zipCode'); }
  get branch1() { return this.assuredDetailsCorporate.get('branch'); }
  get cid1() { return this.assuredDetailsCorporate.get('cid'); }
  get coop_no() { return this.assuredDetailsMemberAssociate.get('coop_no'); }
  get coop_no1() { return this.assuredDetailsCorporate.get('coop_no'); }

  get classification() { return this.vehicleDetails.get('classification'); }
  get vehicle() { return this.vehicleDetails.get('vehicle'); }
  get yearModel() { return this.vehicleDetails.get('yearModel'); }
  get series() { return this.vehicleDetails.get('series'); }
  get make() { return this.vehicleDetails.get('make'); }
  get color() { return this.vehicleDetails.get('color'); }
  get plateNo() { return this.vehicleDetails.get('plateNo'); }
  get bodyType() { return this.vehicleDetails.get('bodyType'); }
  get MVFile() { return this.vehicleDetails.get('MVFile'); }
  get chassis() { return this.vehicleDetails.get('chassis'); }
  get engineNo() { return this.vehicleDetails.get('engineNo'); }
  get carValue() { return this.vehicleDetails.get('carValue'); }
  get renew() { return this.vehicleDetails.get('renew'); }
  get effectivity_date() { return this.vehicleDetails.get('effectivity_date'); }
  get mortgaged() { return this.vehicleDetails.get('mortgaged'); }
  get mortgagee() { return this.vehicleDetails.get('mortgagee'); }
  get remarks() { return this.assuredDetailsMemberAssociate.get('remarks'); }
  get manila() { return this.vehicleDetails.get('manila'); }
  get expiry_date() { return this.vehicleDetails.get('expiry_date'); }
  get prorated() { return this.vehicleDetails.get('prorated'); }
  



  addAccessories(){
    this.allAccessories.push({name:this.accessories.value.name, amount:this.accessories.value.amount.replace(/,/g, '')})
    this.show = true;
  }

  removeAccessories(i){
    this.allAccessories.splice(i, 1);
  }


  vehicleList(id){
    this.vehicleListing=this.productInfo.prod.find(x=>x.id==id);
  }

 
  benefitCompute(){
    this.totalCoverage = +this.vehicleDetails.value.carValue.replace(/,/g, '') +this.allAccessories.reduce((sumAccessories, current) => +sumAccessories + +current.amount.replace(/,/g, ''), 0);

    this.productInfo.benefit.forEach(element => {
      element['checked']=element.id==10?true:false;
      element['rate']=0;
      element['coverage']=0;
      element['premium']=0;
      element['noPassenger']=0;
    });
    this.basicModal4.show();
  }


  availBenefits(value){

    if(value.id==10){
      this.OwnDamage();
    }
    else if(value.id==12){
      this.bodilyIinjury(value.benefit_rate_values);
    }
    else if(value.id==13){
      this.selectedPropertyDamage();
    }
    else if(value.id==14){
      this.autoPA();
    }
    else if(value.id==15){
      this.naturalPeril();
    }
  }



   computeNoOfDays(){
    var date1 = new Date(this.effectivity_date.value);
    var date2 = new Date(this.expiry_date.value);
    var Time = date1.getTime() - date2.getTime(); 

    let noofdays = Time / (1000 * 3600 * 24);

    return Math.abs(noofdays);
  }


  premium(benefitId, premium){

    if(benefitId == 97 ){

      if(this.surcharge==0){
        return 0;
      }
      else {
        return (premium * this.computeNoOfDays())/365 *0.20;
      }
    }


    else if(benefitId == 10){
      if(this.surcharge==0){
        return (premium * this.computeNoOfDays())/365;
      }
      else {
        return (premium * this.computeNoOfDays())/365;
      }
    }


    else if(benefitId == 12){
      if(this.surcharge==0){
        return (premium * this.computeNoOfDays())/365;
      }
      else {
        return  (premium * this.computeNoOfDays())/365 + (premium * this.computeNoOfDays())/365 *0.20;
      }
    }

 
    else if(benefitId == 13){
      if(this.surcharge==0){
        return (premium * this.computeNoOfDays())/365;
      }
      else {
        return (premium * this.computeNoOfDays())/365 + (premium * this.computeNoOfDays())/365 *0.20;
      }
    }


    else if(benefitId == 15){
      return (((premium * this.computeNoOfDays())/365) / 100) * this.totalCoverage;
    }


    else if(benefitId == 14){
      return (((premium * this.computeNoOfDays())/365));
    }
  }

  
  async OwnDamage(){
    let com:any=this.vehicleListing.commission.find(x => x.benefit_name_id =='10'&& (this.rateOd>=x.premium_rate_min&&this.rateOd<=x.premium_rate_max) );
    if(!com){
      Swal.fire(
        'Oopps',
        'Out of range.',
        'warning'
      );
      return;
    }

    let ODPremium:any = this.totalCoverage * (this.rateOd/100);

    this.productInfo.benefit.forEach(element => {
      if(element.id==10||element.id==97){
        element['rate'] = this.rateOd;
        element['coverage'] = element.id==97?0: this.totalCoverage;
        element['premium'] = this.premium(element.id, ODPremium);
        element['com_rate']=(element['premium']* this.computeNoOfDays())/365 * +com.comm_rate_max/100;
        element['benefitNo']=element.id;

        if(element.id==97){
          element['checked'] =this.surcharge==0? false: true;
        }
      }
    });
    this.basicModal4.hide();
  }


  naturalPeril(){
    let com:any=this.vehicleListing.commission.find(x => x.benefit_name_id =='15') ;

    Swal.fire({
      title : "Natural Perils",
      input : 'text',
      html  : "<p>Input rate " +com.premium_rate_min+ " up to " +com.premium_rate_max+"</p>",
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
      allowOutsideClick:false,
      inputValidator: function(result:any) {
        if (!result) {
          return 'You need to input something!';  
        }
       
        if(result < +com.premium_rate_min){
          return 'inputed value is lower than the min rate';
        }

        if(result > +com.premium_rate_max){
          return 'inputed value is higher than the max rate';
        }
       
      }
    }).then((result) => {
      if(result.dismiss){
        // this.NP = false;
        return;
      }    

      this.productInfo.benefit.forEach(element => {
        if(element.id==15){
          element['rate'] = result.value;
          element['coverage'] = this.totalCoverage;
          element['premium'] = this.premium(element.id, result.value);
          element['com_rate'] = (element['premium']*this.computeNoOfDays())/365 * +(com.comm_rate_max/100);
          element['benefitNo']=element.id;
        }
      });
    })
  }




  bodilyIinjury(BI){
    this.basicModal1.show();
   
    this.BIcoverage = BI.filter(x =>x.manilaArea== (this.classification.value==8? this.manila.value:"") && x.productNo==this.classification.value && x.optionNo==this.vehicle.value && x.coverageFrom <= (this.totalCoverage/2));
  }

  

  selectedBodilyInjury(index){
   
    this.indexpd=index;
    this.productInfo.benefit.forEach(element => {
      if(element.id==12){
        element['rate'] = 'standard';
        element['coverage'] = this.BIcoverage[index].coverageFrom;
        element['premium'] = this.premium(element.id, this.BIcoverage[index].premiumRate);
        element['com_rate'] = (element['premium']*this.computeNoOfDays())/365 * +(this.vehicleListing.commission.find(x=>x.benefit_name_id==element.id).comm_rate_max/100);
        element['benefitNo']=element.id;
      }
    });
  }


  selectedPropertyDamage(){
    let pd:any =this.productInfo.benefit.find(x => x.id=='13').benefit_rate_values.find(x=>x.productNo == this.classification.value && x.optionNo==this.vehicle.value && x.coverageFrom<=(this.totalCoverage/2) && x.coverageFrom == this.BIcoverage[this.indexpd].coverageFrom);
    this.productInfo.benefit.forEach(element => {
      if(element.id==13){
        element['rate'] = 'standard';
        element['coverage'] = pd.coverageFrom;
        element['premium'] = this.premium(element.id, pd.premiumRate);
        element['com_rate'] = (element['premium']* this.computeNoOfDays())/365 * +(this.vehicleListing.commission.find(x=>x.benefit_name_id==element.id).comm_rate_max/100);
        element['benefitNo']=element.id;
      }
    });
  }


  autoPA(){
    this.APAcoverage =this.productInfo.benefit.find(x => x.id=='14').benefit_rate_values.filter(x=>x.productNo == this.classification.value && x.optionNo==this.vehicle.value && x.coverageFrom <= this.totalCoverage);
    this.basicModal2.show();
    this.show1=1;
  }


  async selectedAutoPA(index){
    const { value: number } = await Swal.fire({
      title:'Number Of Passenger (Max: 50.)',
      input:'number',
      allowOutsideClick:false,
      inputValidator: function(result) {
        if (!result) {
          return 'You need to input number of Passenger!';  
        }
      }
    });

    if(number){
      this.noOfPassenger=number;
      this.productInfo.benefit.forEach(element => {
        if(element.id==14){
          element['rate'] = 'standard';
          element['coverage'] = this.APAcoverage[index].coverageFrom;
          element['premium'] = this.premium(element.id, (this.APAcoverage[index].premiumRate * number));
          element['noPassenger'] = number;
          element['com_rate']=(element['premium']* this.computeNoOfDays())/365 * +(this.vehicleListing.commission.find(x=>x.benefit_name_id==element.id).comm_rate_max/100);
          element['benefitNo']=element.id;
        }
      });
    }

    this.basicModal2.hide();
  }


  compute(){

    Swal.fire({
      title: "<strong><u>NOTE!</u></strong>",
      html: `If the property is under insured this is subject for approval to head office. Click the get quotation instead.`,
    });

    let totalPrem:any=0;
    let other_payment:any=0;
    let total_commission:any=0;

    this.productInfo.benefit.forEach(element => {
      // deductible
      if (this.classification.value==5 && element.id==10 ) {
        let sI = (element.coverage) * .01;
        element['deductible']=sI>3000? sI: 3000;
      }
      else if (this.classification.value==6 && element.id==10) {
        let sI = (element.coverage) * .005;
        element['deductible']=sI>2000? sI: 2000;
      }
      else if (this.classification.value==7 && element.id==10) {
        let sI = (element.coverage) * .01;
        element['deductible']=sI>500? sI: 500;
      }
      else if (this.classification.value==8 && element.id==10) {
        let sI = (element.coverage) * .0125;
       
        if(this.vehicle.value == 6){
          element['deductible']=sI>1000? sI: 1000;
        }
        else{
        element['deductible']=sI>4000? sI: 4000;

        }
      }
      if (element.id==15 && element.checked) {
        let sI = (element.coverage) * .01;
        element['deductible']=sI>10000? sI: 10000;
      }
    
      // get total premium and commision
      if(element.checked){
        totalPrem+= +element.premium;
        total_commission+= +element.com_rate;

        if(element.id==15){
          this.NP_deductible=element.deductible;
        }
        else if(element.id==10){
          this.OD_deductible=element.deductible;
        }
      }
    });
    
    // get other payment
    this.productInfo.payment_list_rates.forEach(element => {
      other_payment += element.paymentListNo==5||element.paymentListNo==2? totalPrem * (element.rate/100) :+element.rate;
    });

    this.basicPremium= +totalPrem;
    this.total_calc_commission= +total_commission;
    this.netPremium= +other_payment+ +this.basicPremium;

  }


  netCommision(){
    if(this.waivedCommission > this.total_calc_commission ){
      this.waivedCommission=0;
      Swal.fire(
        'Note!',
        'waived commission max is ' +this.total_calc_commission,
        'warning'
      );
    }
    else{
      this.basicModal3.hide();
    } 
  }


  onFileSelected(event, name) {

    if(this.attachment.find(x=>x.label==name)){
      Swal.fire(
        'Alert',
        'Already uploaded '+name+ ' ,remove it first if you want to replace.',
        'error'
      );
      return; 
    }

    // if(this.quoteId > 0 ){
    //   Swal.fire(
    //     'Alert',
    //     'No need to upload attachment',
    //     'error'
    //   );
    //   return; 
    // }

    var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		
		reader.onload = () => {
      this.attachment.push({
        'url':reader.result,
        'file':event.target.files[0],
        'label':name
      });
		}
  }


  save(isQuote){
    this.spinner.show();

    if(this.quoteId==0){
    
      this.attachment.forEach((row) => {
        this.formData.append('requirements[]', row['file'], row['label']);
      });
    }

    this.formData.set('assured[referer]', this.referer);
    this.formData.set('assured[assuredName]', this.memberType == "memberAssociateMember"? this.firstname.value +' '+ this.middlename.value +' '+ this.lastname.value: this.assuredName.value);
    this.formData.set('assured[createdBy]', this.quoteId>0? this.quoteData[0].agentNo: this.User.iloginId);
    this.formData.set('assured[firstName]', this.memberType == "memberAssociateMember"?this.firstname.value:"");
    this.formData.set('assured[lastName]', this.memberType == "memberAssociateMember"?this.lastname.value:"");
    this.formData.set('assured[middleName]', this.memberType == "memberAssociateMember"?this.middlename.value:"");
    this.formData.set('assured[suffix]', this.memberType == "memberAssociateMember"?this.suffix.value:"");
    this.formData.set('assured[address]', this.memberType == "memberAssociateMember"?this.address.value:this.address1.value);
    this.formData.set('assured[contact]', this.memberType == "memberAssociateMember"?this.contact.value:this.contact1.value);
    this.formData.set('assured[email]', this.memberType == "memberAssociateMember"?this.email.value:this.email1.value);
    this.formData.set('assured[assuredType]', this.memberType);
    this.formData.set('assured[gender]', this.memberType == "memberAssociateMember"?this.gender.value:"");
    this.formData.set('assured[coopNo]', this.quoteId>0? this.quoteData[0].coopNo: (this.assuredDetailsCorporate.value.coop_no? this.assuredDetailsCorporate.value.coop_no: this.User.coop_maincode));
    this.formData.set('assured[zipCode]', this.memberType == "memberAssociateMember"?this.zipCode.value:this.zipCode1.value);
    this.formData.set('assured[cid]', this.memberType == "memberAssociateMember"?this.cid.value: "");
    this.formData.set('assured[isQuote]', isQuote);
    this.formData.set('assured[branch]', this.memberType == "memberAssociateMember"?this.branch.value: this.branch1.value);
    this.formData.set('assured[issuedFrom]', 'Web');
    this.formData.set('assured[remarks]', this.remarks.value);
    this.formData.set('assured[prev_policy_no]', this.prev_policy_no);
    this.formData.set('assured[new_business]', this.new_business==true?'2' :'1');
    this.formData.set('paymentHeader[grossPremium]', this.basicPremium);
    this.formData.set('paymentHeader[netPremium]', this.netPremium);
    this.formData.set('paymentHeader[coverage]', +this.carValue.value.replace(/,/g, '') + this.allAccessories.reduce((sumAccessories, current) => +sumAccessories + +current.amount.replace(/,/g, ''), 0) )
    this.formData.set('assured[posting_type]', this.posting_type);
    this.formData.set('paymentHeader[waiveComm]', this.waivedCommission);
    this.formData.set('vehicle[productNo]', this.classification.value);
    this.formData.set('vehicle[optionNo]', this.vehicle.value);
    this.formData.set('vehicle[yearModel]', this.yearModel.value);
    this.formData.set('vehicle[model]', this.series.value);
    this.formData.set('vehicle[make]', this.make.value);
    this.formData.set('vehicle[color]', this.color.value);
    this.formData.set('vehicle[plateNo]', this.plateNo.value);
    this.formData.set('vehicle[bodyType]', this.bodyType.value);
    this.formData.set('vehicle[mvFileNo]', this.MVFile.value);
    this.formData.set('vehicle[chassisNo]', this.chassis.value);
    this.formData.set('vehicle[engineNo]', this.engineNo.value);
    this.formData.set('vehicle[mortgaged]', this.mortgaged.value);
    this.formData.set('vehicle[mortgagee]', this.mortgagee.value);
    this.formData.set('vehicle[carValue]', this.carValue.value.replace(/,/g, ''));
    this.formData.set('vehicle[lateReg]', this.renew.value);
    this.formData.set('assured[variantId]', '2');
    this.formData.set('assured[effectivityDate]', this.effectivity_date.value);
    this.formData.set('assured[expiryDate]', this.expiry_date.value);
    this.formData.set('vehicle[numOfPassenger]', '0');
    this.formData.set('vehicleAccessories', JSON.stringify(this.allAccessories));
    this.formData.set('paymentOther', JSON.stringify(this.productInfo.payment_list_rates));
    this.formData.set('benefit', JSON.stringify(this.productInfo.benefit));
    this.formData.set('assured[id]', this.quoteId>0 && this.new_business==false ? this.quoteData[0].assuredListNo: null);
    this.formData.set('assured[quotationno]',this.quoteId>0 && this.new_business==false ? this.quoteData[0].id: null);
    this.formData.set('assured[policy]', this.productInfo.varshortname);

    this.IssuePolicy.issuePolicy(this.formData).subscribe((res:any)=>{
      this.spinner.hide();

      Swal.fire(
        'Success!',
        res['msg'],
        'success'
      );
      if(this.User.usertype==1){
        this.router.navigate(['/admin/quotation-approval']);
      }

      else if(res.data.policyno){
        this.router.navigate(['/'+this.furl+'/policy-issued']);
      }else{
        this.router.navigate(['/'+this.furl+'/issued-policy-quotation']);
    
      }
    
    },error=>{
      this.spinner.hide();

      this.formData.delete('requirements[]');

      let err=error.status == 0?{msg: 'Something went wrong.'}: error.error ;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })
  
      Swal.fire(
        'Error',
        msg,
        'error'
      );

      if(error.error.success){
        this.router.navigate(['/'+this.furl+'/issued-policy-quotation']);
      }
    });
  }



}



