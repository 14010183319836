import { Component, OnInit,ViewChild } from '@angular/core';
import { NotificationService } from '../../../services-http/notification/notification.service';
import { AuthService } from '../../../services-http/auth/auth.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router'; 


@Component({
  selector: 'app-reporter',
  templateUrl: './reporter.component.html',
  styleUrls: ['./reporter.component.scss']
})
export class ReporterComponent implements OnInit {
  @ViewChild('frame') frame: ModalDirective;
  user:any = JSON.parse(localStorage.getItem('currentUser'));

  constructor(  
    public notification:NotificationService, 
    private authService: AuthService,    
    private router: Router
  ){ }


  ngOnInit() {
    this.notification.getNotification(this.user.iloginId);
  }

  logout() {
    this.authService.logout();
  }


  gotoProfileView() {
    this.router.navigateByUrl('/reporter/profile/' + this.user['userId'] + '/view');
  }


  notificationRead(notification){
    this.notification.notificationRead(notification).subscribe(res =>{
      this.notification.getNotification(this.user.iloginId);
    });
  }

  refresh(){
    this.notification.getNotification(this.user.iloginId);
  }

}
