import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  url = environment.url;
  data: any;
  public notifications: Observable<any>;

  constructor(
		private http: HttpClient, 

  ) { }


  getNotification(loginId){
    return this.http.get(`${this.url}/getNotification/` +loginId).subscribe(res =>{
      // console.log(res)
      let data:any = res;
      let count:any = data.filter(x => x.status == 1 );

      this.notifications = new Observable( observer=> {
        observer.next({
          res : data,
          count: count.length
        });
        observer.complete();
      })
    })
  }


  notificationRead(notification_id){
    return this.http.get(`${this.url}/notificationRead/` +notification_id);
  }


}
