import { Component, OnInit,ViewChild } from '@angular/core';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { ModalDirective } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { CacProductService } from '../../../services-http/cac-product/cac-product.service';


@Component({
  selector: 'app-admin-production-history',
  templateUrl: './admin-production-history.component.html',
  styleUrls: ['./admin-production-history.component.scss']
})
export class AdminProductionHistoryComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;

  history:any =[];
  data:any = [];
  searchText:any= '';
  email:any='';
  quoteId:any;
  coops:any =[];

   //initialize date in filter list
   mydate = new Date();
   enddate = this.mydate.toISOString().substring(0,10);
   customize_date = new Date("2019-01-01");
   startdate = this.customize_date.toISOString().substring(0,10);
   export:any=false;
   paymentStatus:any=[
    {label: 'ALL PAYMENT', value:2 },
    {label: 'PAID', value:1 },
    {label: 'UNPAID', value:0 }
   ]  
 
   filterForm = this.fb.group({
     date_from: [this.startdate,Validators.required],
     date_to  : [this.enddate,Validators.required],
     agent    : ['agent_login_id', Validators.required],
     product  : ['variantid', Validators.required],
     coops    : ['cac_coop_no', Validators.required],
     paymentStatus: 2
   });

   public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };

   agents:any =[];
   productlist:any = [];



  constructor(
    private prodhistory:ProductionHistoryService , 
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private issuedpolicy:IssuePolicyService,
    private cac:CacProductService

   ) { }

  ngOnInit() {
    this.getProduct();
    this.get_agents();
    this.get_coop();

    this.getPolicy(1,false);

  }

  getPolicy(pageno,xport){
    var agent = this.filterForm.value.agent.split('/');
    var product = this.filterForm.value.product.split('/');

    this.spinner.show();
    let data = {
      date_from     : this.filterForm.value.date_from,
      date_to       : this.filterForm.value.date_to,
      agent         : agent[0],
      product       : product[0],
      searchText    : this.searchText,
      page          : pageno,
      export        : xport,
      paymentStatus : this.filterForm.value.paymentStatus,
      coops         : this.filterForm.value.coops
    }

    this.prodhistory.adminGetHistory(data).subscribe((res:any)=>{  
      
      if(xport){
        window.open(res);
      }else{
        this.history=res;

        // this.history.forEach(row => {
        //   row['ddfdfdf'] = 'jjhhuh';
          
        //   // row.cash_in_payment.forEach(row1 => {

        //   //   if(row1.date_approved < row.insertdate) {
        //   //     row['ddfdfdf'] = 'jjhhuh'
        //   //   }

        //   // });
        // });

      }
      this.spinner.hide();
    },error=>{
    this.spinner.hide();
    });
  }

  get_coop(){
    let data ={
      // loginId: 'cac_login_id'
    }
     
    this.prodhistory.get_coop(data).subscribe((res:any)=>{  
      this.coops=[{label:'ALL COOP', value: 'cac_coop_no'}]

     

      res.forEach(row => {
        this.coops.push(row);
      });

     

    });
  }

  get_agents(){
    let data ={
      loginId: 'cac_login_id'
    }
     
    this.prodhistory.get_agents_coop(data).subscribe(res=>{  
      this.agents = res;
    });
  }

  getProduct(){
    let data ={
      loginId: 'cac_login_id'
    }
    this.prodhistory.getProductlist(data).subscribe(res=>{
      this.productlist = res;
    });
  }


  

  sendEmail(){
    this.spinner.show();
    let data = {
      quotId: this.quoteId,
      email: this.email
    }

    this.cac.sendEmailPA(data).subscribe(res=>{
      this.spinner.hide()
      this.basicModal.hide();
      Swal.fire(
        'Success',
        'Email successfully sent!',
        'success'
      );  
    },error=>{
      this.spinner.hide()

      Swal.fire(
        'Oppss',
        'Something went wrong!',
        'error'
      );
    });
  }


  download(id){
    this.spinner.show();

    this.prodhistory.endorsementPrint(id).subscribe((res:Blob)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "endorsement-letter.pdf";
      link.click();
    },error=>{
      this.spinner.hide();
    });
  }



}
