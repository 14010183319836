import { Component, OnInit,ViewChild } from '@angular/core';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment} from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { ModalDirective  } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-credit-payment-approval',
  templateUrl: './credit-payment-approval.component.html',
  styleUrls: ['./credit-payment-approval.component.scss']
})
export class CreditPaymentApprovalComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };

  CashIn:any=[];
  detail:any =[];
  searchText:any='';
  user = JSON.parse(localStorage.getItem("currentUser"));
  url =environment.url;
  date_from:any;
  date_to:any;
  type:any='list';
  area:any = this.user.area;


  constructor(
    private productHis: ProductionHistoryService,
    private spinner: NgxSpinnerService
   ) { }


  ngOnInit() {
    this.credit_payment_approval(1)
   
  } 

  credit_payment_approval(pageno){
    setTimeout(() => this.spinner.show(), 0);

    let data={
      page:pageno,
      sender_name:this.searchText,
      reference_no:this.searchText==''?'reference_no':this.searchText,
      area:this.area
    }
    this.productHis.credit_payment_approval(data).subscribe(res => {
      this.CashIn=res;
      this.spinner.hide();
    },error=>{
      this.spinner.hide();
    });
  }


  approval(cash){
    this.spinner.show()
    let data = {
      cashin_id : cash.id,
      user_id : this.user.userId
    } 
    this.productHis.approve_cashin( data ).subscribe(res => {
      // let index=this.CashIn.data.findIndex(x=>x.id==cash.id);
      // this.CashIn.data[index].status = 'Approved';

      this.credit_payment_approval(this.CashIn.current_page);

      this.spinner.hide();

    },error=>{
      this.spinner.hide();
    });
  } 


  display_cashIn_image(file){
    this.productHis.display_cashIn_image({file:file}).subscribe(res=>{
        window.open(res['url'], '_blank').focus();
    },error=>{
      alert('Something went wrong.');
    });
  }


  async disapprove_cashIn(cash){
    const { value: accept } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder:'Type your message here!',
      confirmButtonText:'Continue <i class="fa fa-arrow-right"></i>',
      inputValidator: (result) => {
        return !result && 'You need to input remarks'
      }
    });
    
    if (accept) {
      this.productHis.disapprove_cashIn({id:cash.id, remarks:accept,loginId: this.user.iloginId }).subscribe(res => {

        // let index=this.CashIn.data.findIndex(x=>x.id==cash.id);
        // this.CashIn.data[index].status = "Disapproved";

        this.credit_payment_approval(this.CashIn.current_page)

        Swal.fire(
          'Success',
          res['msg'],
          'success'    
        );    
      },error=>{
        Swal.fire(
          'error',
          'Something went wrong!',
          'error'
        );
      });
    }
  }

  print_paid_bill(bill_id){
    this.spinner.show();
    this.productHis.print_paid_bill(bill_id).subscribe((res:any) => {
      this.spinner.hide();
      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "paid-bill-statement.pdf";
      link.click();


    },error=>{
      this.spinner.hide();
      Swal.fire(
        'error',
        'Something went wrong!',
        'error'
      );
    });
  }

  


  export(){
    this.spinner.show();

    let data={
      posting_type: 1,
      date_from: this.date_from,
      date_to: this.date_to,
      type_or_report: this.type
    }

    this.productHis.cashInReport(data).subscribe((res:any) => {
      this.spinner.hide();
      this.basicModal.hide();
      
      window.open(res);

    },error=>{
      Swal.fire(
        'error',
        'Something went wrong!',
        'error'
      );
    });
  }

}
