import { Component,OnInit,ViewChild } from '@angular/core';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service'
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { CacProductService } from '../../../services-http/cac-product/cac-product.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-coop-production-history',
  templateUrl: './coop-production-history.component.html',
  styleUrls: ['./coop-production-history.component.scss']
})
export class CoopProductionHistoryComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;

  constructor(
    private prodhistory:ProductionHistoryService, 
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private cac:CacProductService
    ){}


  history:any = [];
  searchText:any ='';
  agents:any =[];
  productlist:any = [];
  email:any='';
  quoteId:any;

 
  //initialize date in filter list
  mydate = new Date();
  enddate = this.mydate.toISOString().substring(0,10);
  customize_date = new Date("2019-01-01");
  startdate = this.customize_date.toISOString().substring(0,10);
  user = JSON.parse(localStorage.getItem('currentUser'));


  filterForm = this.fb.group({
    date_from: [this.startdate,Validators.required],
    date_to  : [this.enddate,Validators.required],
    agent    : ['agent_login_id',Validators.required],
    product  : ['variantid',Validators.required]

  });

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };


  getPolicy(pageno){
    this.spinner.show();

   
    let name;
    let prod;
    var agent = this.filterForm.value.agent.split('/');

    var product = this.filterForm.value.product.split('/');

    if(agent[1] == null){
      name = "ALL"
    }
    else{
      name = agent[1]
    }

    if(product[1] == null){
      prod = "ALL"
    }
    else{
      prod = product[1] 
    }

    let data = {
      date_from   : this.filterForm.value.date_from,
      date_to     : this.filterForm.value.date_to,
      agent       : agent[0],
      agent_name  : name,
      product     : product[0],
      product_name: prod,
      page        : pageno,
      searchText  : this.searchText
    }

    
    this.prodhistory.getHistory(data, this.user.iloginId).subscribe(res=>{  
      this.history = res;
      this.spinner.hide();

    },error=>{
      this.spinner.hide();
    })
  }

  get_agents(){
    let data ={
      loginId: this.user.iloginId
    }
     
    this.prodhistory.get_agents_coop(data).subscribe(res=>{  
      this.agents=res;
    });
  }

  getProduct(){

    let data ={
      loginId: this.user.iloginId
    }
    this.prodhistory.getProductlist(data).subscribe(res=>{
      this.productlist = res;
    });
  }


  export(){
    this.spinner.show();

    let name;
    let prod;
    var agent = this.filterForm.value.agent.split('/');

    var product = this.filterForm.value.product.split('/');

    if(agent[1] == null){
      name = "ALL"
    }
    else{
      name = agent[1]
    }

    if(product[1] == null ){
      prod = "ALL"
    }
    else{
      prod = product[1] 
    }

    let data = {
      date_from : this.filterForm.value.date_from,
      date_to   : this.filterForm.value.date_to,
      agent     : agent[0],
      agent_name: name,
      product   : product[0],
      product_name: prod,
      searchText  : this.searchText
    }
 
    this.prodhistory.export(data, this.user.iloginId).subscribe(res=>{  
      window.open(''+res)

      this.spinner.hide();
    },error=>{
      this.spinner.hide();
    });
  }



  parcer(cac_fee, agent_fee){
    return parseFloat(cac_fee)  + parseFloat(agent_fee);
  }


  sendEmail(){
    this.spinner.show()
    let data = {
      quotId: this.quoteId,
      email: this.email
    }

    this.cac.sendEmailPA(data).subscribe(res=>{
      this.spinner.hide()
      this.basicModal.hide();
      Swal.fire(
        'Success',
        'Email successfully sent!',
        'success'
      );  
    },error=>{
      this.spinner.hide()

      Swal.fire(
        'Oppss',
        'Something went wrong!',
        'error'
      );
    });
  }


  ngOnInit() {

    this.getProduct();
    this.get_agents();
    this.getPolicy(1);
  }

  

}
