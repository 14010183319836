import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services-http/auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-group-access',
  templateUrl: './group-access.component.html',
  styleUrls: ['./group-access.component.scss']
})
export class GroupAccessComponent implements OnInit {
  userType:any = [];
  modules:any = [];
  access:any =[];
  selectedAccess:any = "";
  user:any = JSON.parse(localStorage.getItem('currentUser'));

  formData = new FormGroup({
    typeid: new FormControl('',Validators.required ),
    status: new FormControl('',Validators.required),
    modules: new FormControl('',Validators.required)
  })


  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.groupaccess().subscribe(res=>{
     
      this.userType = res;
      // this.formData.patchValue({typeid:""});
    }),error=>{

    }
  }


  chooseName(event){
    let groupaccess = this.userType.data.find(x=> x.typeid == event);

    this.formData.patchValue({
      status: groupaccess.status
    });

    this.access = groupaccess.group_access;
  }



  deleteupdateGroupAccess(id){
    let usertype =  this.userType.data.find(x => x.typeid == this.formData.value.typeid).group_access.find(x => x.id == id);

    let data = {
      id : usertype.group_module_id,
      action : 1
    }
    this.authService.addupdateGroupAccess(data, this.user.usertype ).subscribe(res=>{
     
      Swal.fire(
        'Yeheey',
        res['msg'],
        'success'
      );
    }),error=>{

    }
  }



  addupdateGroupAccess(id){
    let module = this.userType.group_module.find(x => x.id == id);

    let data = {
      id : module.id,
      access_name : module.access_name,
      status : module.status,
      action : 0
    }

    this.authService.addupdateGroupAccess(data, this.user.usertype).subscribe(res=>{
    
      Swal.fire(
        'Yeheey',
        res['msg'],
        'success'
      );
    }),error=>{

    }
  }

  

}
