import { Component, OnInit,ViewChild } from '@angular/core';
import { BillingService } from '../../../../services-http/billing/billing.service';
import { ModalDirective  } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { debounceTime } from "rxjs/operators";
import  Swal from 'sweetalert2';


@Component({
  selector: 'app-credit-line-approval',
  templateUrl: './credit-line-approval.component.html',
  styleUrls: ['./credit-line-approval.component.scss']
})
export class CreditLineApprovalComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('basicModal1') basicModal1: ModalDirective;


  headElements = ['Login ID','Sender Name','Username', 'Contact No','Amount', 'Date','Approver',];
  searchText:any='';
  user = JSON.parse(localStorage.getItem("currentUser"));
  amount:any=0;
  list:any=[];
  chashinInfo:any={};
  searchListShow:boolean=false;
  searchResult:any=[];


  creditLineForm = new FormGroup({
    search_user: new FormControl('',Validators.required),
    sender_name: new FormControl('',Validators.required),
    mobile_no: new FormControl('',Validators.required),
    amount_sent: new FormControl('',[Validators.required, Validators.pattern(/^[0-9.]*$/)]),
    login_id: new FormControl('',Validators.required),
    email_add: new FormControl('',Validators.required),

  });

  constructor(
    private bill:BillingService, 
    private spinner: NgxSpinnerService
  ) { }
  
  ngOnInit() {
    this.get_list(1,false);

    this.creditLineForm.get("search_user").valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      if(res){
        this.search();
      }
    });
  }

  search(){
    this.spinner.show();

    this.bill.searchCoopInfo(this.creditLineForm.get("search_user").value).subscribe(res=>{
      this.searchResult=res;
      this.searchListShow=true;
      this.spinner.hide();

    })
  }


  searchItem(searhItem){
    this.creditLineForm.patchValue({sender_name: searhItem.pkid_name, mobile_no:searhItem.mobile_no,login_id:searhItem.login_id,email_add:searhItem.username })
    this.searchListShow = false;
  }


  get_list(page,exports){
    
    this.bill.credit_cashin_list(page,this.searchText,exports).subscribe((res:any)=>{

      if(exports==true){
        window.open(res);
      }
      
      else{
        this.list=res;
      }

    },error=>{
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      );
    });
  }

  reload_credit_line(){
    this.spinner.show();
    let data={
      amount:this.amount,
      loginId:this.user.userId,
      id:this.chashinInfo.id
    }
    this.bill.reload_credit_line(data).subscribe(res=>{
      this.basicModal.hide()
      this.get_list(1,false);
      this.amount=0;
      this.spinner.hide();
    });
  }


  save(){
    this.spinner.show();

    this.bill.reload_by_officer(this.creditLineForm.value).subscribe(res=>{
      this.spinner.hide();

      this.basicModal1.hide();
      this.creditLineForm.reset();
      this.searchListShow=false;
      this.get_list(1,false)
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      )
    },error=>{
      this.spinner.hide();

      this.basicModal1.hide();
      Swal.fire(
        'Error',
        'Something went wrong!',
        'error'
      )
    })
  }



}
