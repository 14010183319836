import { Component, OnInit } from '@angular/core';
import { FeedbackService} from '../../../services-http/feedback/feedback.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-admin-feedback',
  templateUrl: './admin-feedback.component.html',
  styleUrls: ['./admin-feedback.component.scss']
})
export class AdminFeedbackComponent implements OnInit {

  constructor(
    private feedbacksrvc:FeedbackService,
    private spinner: NgxSpinnerService
    
    ) { }
  feedback:any = [];
  loadings:boolean = true;
  timeout:boolean = false;
  searchFeedback:any;
  image:any;


  ngOnInit() {
    
    // setTimeout(() => {
    //   this.loadings = false;
    //   this.timeout = true;
    // }, 15000);

    this.getFeedBack();
  }

  getFeedBack(){
    setTimeout(() => this.spinner.show(), 0);

    this.feedbacksrvc.getFeedBack().subscribe(res => {
      this.feedback = res;
      this.spinner.hide()
    })
  }

  feedbackAction(id){
    let i = this.feedback.findIndex(x => x.id === id);
    this.feedbacksrvc.feedbackAction(id).subscribe(res => {
      if(res){
        this.feedback[i].status = 1;
      }
    })
  }

  download_file(id){
    
    this.feedbacksrvc.download_file(id).subscribe(res => {
     this.image = res
  
      for (let i = 0; i < this.image.length; i++) {
        window.open(this.image[i].file_url)
      }

    })
  }

  refresh(){
    this.getFeedBack();
  }

}
