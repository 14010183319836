import { Component, OnInit ,ViewChild} from '@angular/core';
import { BillingService } from '../../../services-http/billing/billing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective  } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators} from '@angular/forms';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})

export class BillingComponent implements OnInit {
  // @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('basicModal1') basicModal1: ModalDirective;
  @ViewChild('content') content: ModalDirective;



  constructor( 
    private bill:BillingService,
    private spinner: NgxSpinnerService, 
    private fb: FormBuilder, 
    private productHis:ProductionHistoryService,
  ) { }


  headElements=['Policy','Gross Premium','Net Premium','Gam Collection','Coop Collection','Total']
  user:any = JSON.parse(localStorage.getItem('currentUser'));
  bill_list:any=[];
  points:any=0;
  transaction_history:any={data:[] };
  val:any = 'transaction_type';
  remarks:any;
  searchText:any;
  isShow:boolean = true;
  sent_to:any= [];
  timeLeft: number = 5;
  OTP:number;
  random_number:any;
  buttonshow:boolean=true;
  interval;
  max = 100000;
  min = 999999;
  date_from:any;
  date_to:any;


  profileForm = this.fb.group({
    // recipient:  ['', Validators.required],
    agent:  ['', Validators.required],
    amount: ['',[Validators.required,  Validators.pattern(/^[0-9.]*$/)  ]]
  });

  // recipients:any =[
    // {label: 'Branch', value:'branch'},
  //   {label: 'Agent/ Branch', value:'agent'}
  // ];


  get amount() { return this.profileForm.get('amount');}
  
  
  ngOnInit() { 
    this.credit_points();
    this.get_agents();
    this.credit_transaction_history(1);
  }


  // choose_recipient(){
  //   if(this.profileForm.value.recipient == 'agent'){
  //     this.get_agents();
  //   }
  //   else if(this.profileForm.value.recipient == 'branch'){
  //     this.get_branch();
  //   }
  // }

  get_agents(){
    // let data ={
    //   points: this.user.iloginId
    // }
  
    this.productHis.get_agents(this.user.iloginId).subscribe(res=>{ 
      this.sent_to = res;
    });
  }


  // get_branch(){
  //   let data ={
  //     coop_no: this.user.coop_maincode
  //   }
  
  //   this.productHis.get_branch(data).subscribe(res=>{ 
  //      this.sent_to = res;
  //   });

  // }


 

  


  // request_credit_cashIn(){
  //   this.spinner.show();
    
  //   let data={
  //     channel_type:3,
  //     sender_name:this.user.name,
  //     amount_sent:0,
  //     mobile_no:this.user.contact_no,
  //     email_add:this.user.username,
  //     login_id:this.user.iloginId,
  //     posting_type:1
  //   }

  //   this.bill.request_credit_cashIn(data).subscribe(res=>{
  //     this.spinner.hide();
  //     this.basicModal.hide();
  //     Swal.fire(
  //       'Info',
  //       res['msg'],
  //       'success'
  //     );
  //   },error=>{
  //     this.spinner.hide();

  //   }
  //   )
  // }
 
  

 


  credit_points(){
    this.spinner.show();
    this.bill.credit_points().subscribe(res=>{
      this.spinner.hide();
      this.points= res['points'];
    },error=>{
      this.spinner.hide();
    });
  }

  credit_transaction_history(page){

      this.spinner.show();

      this.bill.credit_transaction_history(page,this.val).subscribe(res=>{
        this.spinner.hide();
        this.transaction_history=res;
      },error=>{
        this.spinner.hide();
      });
  }


  export_trans_history(){
    this.spinner.show();

    let data ={
      date_from: this.date_from,
      date_to: this.date_to,
      trans_type: this.val,
      posting_type: 1
    }

    this.bill.export_trans_history(data).subscribe((res:any)=>{
      this.spinner.hide();
      window.open(res);
    },error=>{
      this.spinner.hide();
    });
  }

 
 

  // pay_credit_bill(){
  //   this.spinner.show();
  //   this.formData.append('channel_type', this.payForm.value.channel_type);
  //   this.formData.append('channel_name', this.payForm.value.channel_name);
  //   this.formData.append('channel_branch', this.payForm.value.channel_branch);
  //   this.formData.append('sender_name', this.user.name);
  //   this.formData.append('amount_sent', this.payForm.value.amount_sent);
  //   this.formData.append('mobile_no', this.user.contact_no);
  //   this.formData.append('email_add', this.user.username);
  //   this.formData.append('reference_no', this.payForm.value.reference_no);
  //   this.formData.append('login_id', this.user.iloginId);
  //   this.formData.append('bill_id', this.bill_id);
  //   this.formData.append('posting_type', '1');

  //   this.bill.pay_credit_bill(this.formData).subscribe(res=>{
  //     this.spinner.hide();
  //     this.frame.hide();
  //     this.payForm.reset();
  //     this.img_url='';
  //     this.billing_list();
    

  //     Swal.fire(
  //       'Info!',
  //       res['msg'],
  //       'success'
  //     );
  //   },error=>{
  //     this.spinner.hide();
  //   });

  // }









  randomizer(){
    this.random_number = Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
  }

 
  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        // this.timeLeft = 5;
        this.randomizer();
        clearInterval(this.interval);
        this.buttonshow = false;
      }
    },60000)
  }


  send(){
    this.timeLeft = 5;
    this.randomizer();
    this.startTimer();

    let data = {
      random_number :this.random_number,
      mobile_number: this.user.contact_no,
      amount: +this.profileForm.value.amount,
      posting_type: 1,
      login_id: this.user.iloginId
    };
   
 
    this.productHis.send_otp(data).subscribe(()=>{
      this.isShow = false;
    },error=>{
      let err = error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key];
      });
        
      Swal.fire(
        'Oopps',
        msg,
        'error'
      );
    });
  }


  submit(){
    let data = {
      amount        : this.profileForm.value.amount,
      from_login_id : this.user.iloginId,
      to_login_id   : this.profileForm.value.agent,
      otpcode       : this.OTP,
      posting_type  : 1
    }
  
    if(this.random_number != this.OTP){
      Swal.fire(
        'Error',
        'OTP does not matched!',
        'error'
      );
      return;
    }
   
    this.productHis.submit_amount(data).subscribe(res=>{
      this.basicModal1.hide();

      this.randomizer();
      this.points = +this.points - +this.profileForm.value.amount;
      clearInterval(this.interval);
      this.buttonshow = true;
      this.isShow = true;

      Swal.fire(
        'Success',
        'Successfully Transfer Funds!',
        'success'
      );
    },
    error=>{
      this.basicModal1.hide();
    });
    

   
  }
}
