import { Component, OnInit } from '@angular/core';
import { CreateInsurtechAnnouncementService } from '../../../services-http/create-insurtech-announcement/create-insurtech-announcement.service'
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-update-insurtech-version',
  templateUrl: './update-insurtech-version.component.html',
  styleUrls: ['./update-insurtech-version.component.scss']
})
export class UpdateInsurtechVersionComponent implements OnInit {

  constructor(private announcement:CreateInsurtechAnnouncementService,   private spinner: NgxSpinnerService) { }
  ios:any;
  android:any;
  version:any=[];

  ngOnInit() {
    this.getVersion();
  }

  updateVersion(){
    this.spinner.show()

    let data = {
      ios:this.ios,
      android:this.android
    }
    this.announcement.updateVersion(data).subscribe(res=>{

      this.spinner.hide()
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      )

    })

  }


  getVersion(){
    this.announcement.getVersion().subscribe(res=>{
      // console.log(res)
      this.version =res;
      this.android = this.version.data[0].version;
      this.ios = this.version.data[0].version_ios;

    })
  }

}
