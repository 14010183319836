
import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuotationService } from '../../../services-http/quotation/quotation.service';
import { ToastService,ModalDirective  } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
// import * as jsPDF from 'jspdf';

// import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-admin-quotation-details',
  templateUrl: './admin-quotation-details.component.html',
  styleUrls: ['./admin-quotation-details.component.scss']
})
export class AdminQuotationDetailsComponent implements OnInit {
  @ViewChild('basicModal1') basicModal1: ModalDirective;
  @ViewChild('basicModal2') basicModal2: ModalDirective;
  @ViewChild('basicModal3') basicModal3: ModalDirective;
  @ViewChild('basicModal4') basicModal4: ModalDirective;

  user = JSON.parse(localStorage.getItem('currentUser'));
  id:number = this.route.snapshot.params['id'];

  mydate = new Date();

  remarks:any = "";  

  quoteDetails:any = []; 
  url:any='';

  constructor(
    private route: ActivatedRoute,
    private quotation: QuotationService,
    private toastrService: ToastService,
    private router:Router,
    private spinner: NgxSpinnerService

  ) {
    this.route.parent.url.subscribe((urlPath) => {
      this.url = urlPath[urlPath.length - 1].path;
    });
   }

 

  ngOnInit() {
    this.get_quotation_details();
  }


  get_quotation_details(){
      setTimeout(() => this.spinner.show(), 0);
      this.quotation.view_quotation(this.id).subscribe(res => {
        this.quoteDetails = res;
        // console.log(this.quoteDetails)

        this.spinner.hide();
      },error=>{
        this.router.navigate(['/admin/quotation-approval']);
        Swal.fire(
          'error!',
          'Something went wrong!',
          'error'
        )
      }
    );
  }



  approve(){  
    Swal.fire({
      title             : 'Are you sure?',
      text              : "You won't be able to revert this!",
      showCancelButton  : true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor : '#d33',
      confirmButtonText : 'Yes, approve it!'
    }).then((result) => {
      if (result.value) {
        let formData = new FormData();

        this.quotation.approval(formData, this.id).subscribe(res => {
          Swal.fire(
            'Approved Successfully!',
            'Agent was successfully notify via SMS with Quotation #  '+this.quoteDetails[0].quotationno,
            'success'
          )
          this.router.navigate(['/admin/quotation-approval']);
        }); 
      }
    })
  }


  // dissapprove(){
   
  //   let data = {
  //     remarks         : this.remarks,
  //     quotId          : this.quoteDetails[0].quotationno,
  //     statusid        : 5,
  //     disapprovedBy   : this.user.userId,
  //     disapprovedDate : this.mydate,
  //     coverage        : this.quoteDetails[0].paymentheader.coverage,
  //     contactNo       : this.quoteDetails[0].agent.mobile_no
  //   }
    
  //   Swal.fire({
      
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, dissapprove it!'

  //     }).then((result) => {
    
  //       if (result.value) {
            
  //         this.toastrService.info("Sending SMS...");
        
  //         this.quotation.approval(this.id, data).subscribe(res => {
  //           if(res){
              
  //             Swal.fire(
  //               'Dissapproved Successful! ',
  //               'Agent was successfully notify via SMS with Quotation #  '+this.quoteDetails[0].quotationno,
  //               'success'
  //             )
            
  //             this.router.navigate(['/admin/quotation-approval']);
  //           }
  //         }); 
  //       }
  //     })

  //   }


download(id){
  this.quotation.download( id ).subscribe(res => {  
    window.open(''+ res, '_blank' );
  }); 
}

  



}
