import { Component, OnInit, ViewChild } from '@angular/core';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CacProductService} from '../../../services-http/cac-product/cac-product.service';
 

@Component({
  selector: 'app-issued-policy',
  templateUrl: './issued-policy.component.html',
  styleUrls: ['./issued-policy.component.scss']
})
export class IssuedPolicyComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;

  constructor(
    private issuedpolicy:IssuePolicyService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cac:CacProductService
    ) { }
  users = JSON.parse(localStorage.getItem('currentUser'));
  IssuedPolicy:any = [];
  email:any;
  quoteId:any;
  filter:string ='policy';
  searchText:any = "";
  optionsSelect:any = [];

  url=this.router.url;
  furl;
  branches:any=[];



  monthOption:any = [
    { value: 'MONTH(pol_issued_date)', label: 'All month' } ,
    { value: '1', label: 'January' } ,
    { value: '2', label: 'February' } ,
    { value: '3', label: 'March' } ,
    { value: '4', label: 'April' } ,
    { value: '5', label: 'May' } ,
    { value: '6', label: 'June' } ,
    { value: '7', label: 'July' } ,
    { value: '8', label: 'August' } ,
    { value: '9', label: 'September' } ,
    { value: '10', label: 'October' } ,
    { value: '11', label: 'November' } ,
    { value: '12', label: 'December' } ,
  ];

  // productOption:any = [
  //   { value:'variantid', label:'All product'}       
  // ];

  productOption:any = [];


           

  monthSelected:any='MONTH(pol_issued_date)';
  productSelected:any='variantid';
  branch:any=this.users.iloginId;

  ngOnInit() {
    this.furl=this.url.split('/')[1];

    this.email = this.users['username'];
    this.getIssuedPolicy(1);
    this.selectBranch();
    this.getProduct();
  }

  getProduct(){
    this.spinner.show();
    this.issuedpolicy.getProductlist().subscribe(res=>{

      this.productOption=res;
      Array.prototype.push.apply(this.productOption, [{ value:'variantid', label:'All product'} ]);

      this.spinner.hide();
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Oppss',
        'Something went wrong!',
        'error'
      );
      
    });
  }


  getIssuedPolicy(pageno){

    this.spinner.show()
    let data ={
      page:pageno,
      month:this.monthSelected,
      product:this.productSelected,
      searchKey: this.searchText,
      branch:this.branch
    }

    this.issuedpolicy.issuedPolicy(this.users['iloginId'],data ).subscribe(res=>{
      this.IssuedPolicy = res;
      this.spinner.hide();
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Oppss',
        'Something went wrong!',
        'error'
      );
      
    });
  }

  sendEmail(){
    this.spinner.show()
    let data = {
      quotId: this.quoteId,
      email: this.email
    }

    this.cac.sendEmailPA(data).subscribe(res=>{
      this.spinner.hide()
      this.basicModal.hide();
      Swal.fire(
        'Success',
        'Email successfully sent!',
        'success'
      );  
    },error=>{
      this.spinner.hide()

      Swal.fire(
        'Oppss',
        'Something went wrong!',
        'error'
      );
    });
  }


  getQuoteId(quotId){
    this.quoteId = quotId;
  }
 
  downloadPolicy(quoteId){
    this.spinner.show();
    let data = {
      quotId: quoteId,
      email: 0,
      isDownload:1
    }

    this.cac.sendEmailPAdownload(data).subscribe((res:Blob)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "policy.pdf";
      link.click();
      this.spinner.hide();
      
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Oppsss',
        'Something went wrong.',
        'error'
      );
    });
  }


  selectBranch(){
    this.issuedpolicy.selectBranch(this.users.iloginId).subscribe(res=>{
      this.branches = res;
    },error=>{
      console.log(error)
    });
  }


  renewPolicy(policy){

    if(policy.variantid==56||policy.variantid==60){
      return this.router.navigate(['/'+this.furl+'/fire-issue/'+policy.variantid+'/quoteId/'+policy.quote_referenceid+'/'+policy.agent_login_id]);
    }

    else if(policy.variantid==69|| policy.variantid==70){
      return this.router.navigate(['/'+this.furl+'/surity-bond/'+policy.variantid+'/quoteId/'+policy.quote_referenceid+'/'+policy.agent_login_id]);
    }

    else if(policy.variantid==3){
      return this.router.navigate(['/'+this.furl+'/policy-issuance'+'/quoteId/'+policy.quote_referenceid+'/'+policy.agent_login_id]);
    }

    else if(policy.variantid==2){
      return this.router.navigate(['/'+this.furl+'/comprehensive'+'/quoteId/'+policy.quote_referenceid+'/'+policy.agent_login_id]);
    }

    else if(policy.variantid==1){
      return this.router.navigate(['/'+this.furl+'/standard-fire'+'/quoteId/'+policy.quote_referenceid+'/'+policy.agent_login_id]);
    }

    this.router.navigate(['/'+this.furl+'/CAC-products/'+policy.variantid+'/quoteId/'+policy.quote_referenceid+'/'+policy.agent_login_id]); 
  }

}
