import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BillingService } from '../../../services-http/billing/billing.service';
import { environment } from 'src/environments/environment';
import { ProductionHistoryService } from 'src/app/services-http/production-history/production-history.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-billing-payment-history',
  templateUrl: './billing-payment-history.component.html',
  styleUrls: ['./billing-payment-history.component.scss']
})
export class BillingPaymentHistoryComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService, 
    private bill:BillingService,
    private prod:ProductionHistoryService
  ) { }

  ngOnInit() {
    this.billing_list();
  }

  val:any;
  bill_list:any=[];
  searchText:any;
  remarks:any;
  url = environment.url;



  billing_list(){
    setTimeout(() => this.spinner.show(), 0); 

    this.bill.billing_list().subscribe(res=>{
      this.spinner.hide();
      this.bill_list=res;
    });
  }

  print_paid_bill(bill_id){

    this.spinner.show()
    this.prod.print_paid_bill(bill_id).subscribe((res:any)=>{
      this.spinner.hide()

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "paid-bill-statement.pdf";
      link.click();
    
    },error=>{
      this.spinner.hide()

      Swal.fire(
        'error',
        'Something went wrong!',
        'error'
      );
    });
  }

}
