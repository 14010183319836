import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateInsurtechAnnouncementService {
  url = environment.url; 
  


  constructor(private http: HttpClient) { }

  createAnnouncement(data){
    return this.http.post(this.url + '/createAnnouncement',data );
  }
  updateVersion(data){
    return this.http.post(this.url + '/updateVersion',data );
  }
  getVersion(){
    return this.http.get(this.url + '/getVersion' );
  }
}
