import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = environment.url;

  constructor(
    private http: HttpClient,
  ) { }

  loadUsers(pageno, searchKey, exports) {
    return this.http.get(this.url + '/users?page='+pageno+'&searchKey='+searchKey+'&export='+exports).pipe(map(res => {
      return res;
    }));
  }

  loadUser(id) {
    return this.http.get(this.url + '/users/' + id).pipe(map(res => {
      return res;
    }));
  }

  userDetails(loginId){
    return this.http.get(this.url + '/userDetails/'+loginId ).pipe(map(res => {
      return res;
    }));
  }

  saveUser(credentials) {
    return this.http.post(this.url + '/users', credentials).pipe(map(res => {
      return res;
    }));
  }

  updateUser(id, credentials) {
    return this.http.put(this.url + '/users/' + id, credentials).pipe(map(res => {
      return res;
    }));
  }

  // resetPassword(id, credentials) {
  //   return this.http.post(this.url + '/resetPassword/' + id, credentials).pipe(map(res => {
  //     return res;
  //   }));
  // } 

  loadUserTotalCount() {
    return this.http.get(this.url + '/users_count').pipe(map(res => {
      return res;
    }));
  }

  getCoopName(user_id){
    return this.http.get(this.url + '/getCoopName/' +user_id ).pipe(map(res => {
      return res;
    }));
  }

  update_password(data){
    return this.http.post(this.url + '/update_password/password' ,data).pipe(map(res => {
      return res;
    }));
  }

  async refresh_token(data){
    return  await this.http.post(`${environment.url}/api/auth/refreshToken`, data).toPromise();
  }

  getAgentUnderLoginId(loginId){
    return this.http.get(this.url + '/User/getAgentUnderLoginId/' + loginId);
  }

  getCoopAPIUser(){
    return this.http.get(this.url + '/User/getCoopAPIUser');

  }

  changeStatus(id, status){
    return this.http.get(this.url + '/User/changeCoopAPIStatus/'+id+'/status/'+status  );

  }

  approveOrDisapprove(loginId,data){
    return this.http.post(this.url + '/approveOrDisapprove/'+loginId,data  );

  }

  updateUserInfo(loginId,data){
    return this.http.put(this.url + '/users/'+loginId,data  );
  }

  addProductToUser(data){
    return this.http.post(this.url + '/addProductToUser', data);

  }

}
