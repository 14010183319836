import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CacProductService {

  url = environment.url; 

  constructor(private http: HttpClient) { }

  getProductInfo(id){
    return this.http.get(this.url + '/PAproductInfo/variantId/'+id );
  }

  // PAsave(data){
  //   return this.http.post(this.url + '/PAproductInfo/PAsave', data );
  // }

  sendEmailPA(data){
    return this.http.post(this.url + '/email',data);
  }

  sendEmailPAdownload(data){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    return this.http.post(this.url + '/email',data,httpOptions);
  }

  update_retension_status(policyno){
    return this.http.get(this.url + '/update-retension-status/'+policyno);
  }

  PAsaveFile(file, quoteId){
    return this.http.post(this.url + '/PAsaveFile/'+quoteId, file);
  }


  searchGroupClient(data){
    return this.http.post(this.url + '/groupclients/search', data);

  }

}

