import { Component, OnInit } from '@angular/core';
import { OrIssuanceService } from '../../../services-http/orIssuance/or-issuance.service';
import { ToastService  } from 'ng-uikit-pro-standard';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-or-issuance',
  templateUrl: './or-issuance.component.html',
  styleUrls: ['./or-issuance.component.scss']
})

export class OrIssuanceComponent implements OnInit {
  constructor(
    private or:OrIssuanceService, 
    private toastrService: ToastService , 
    private productHis: ProductionHistoryService,
    private spinner: NgxSpinnerService
    ) { }


  searchForm = new FormGroup({
    search: new FormControl('')
  });


  elements:any =[];
  data:any=[];
  posting_type:any=0;
  search:any='';
  headElements = ['User Type','Name/ Coop Name', 'Area','Region', 'Particular','Date Paid','PR/Bill No.','Amount','Action'];
  or_no:any;
  date:any;
  options:any = { opacity: 0 };
  area:any = '';
  orIssuanceAreas:any =[];
  classification:any ='2'
  l = new Date();
  dateto = this.l.toISOString().substring(0,10);
  f = new Date("2013-01-01");
  datefrom = this.f.toISOString().substring(0,10);


  ngOnInit() {
    this.display(1,false);

    this.orIssuanceArea();

    this.searchForm.get('search').valueChanges.pipe(debounceTime(2000)).subscribe(res => {
   
      this.display(1,false);
    });
  }


  display(page, expor){
    let data = {
      search: this.searchForm.value.search,
      posting_type:this.posting_type,
      page:page,
      area:this.area,
      datefrom:this.datefrom,
      dateto:this.dateto,
      export: expor,
      classification: this.classification
    }
  
    this.spinner.show();

    this.or.display(data).subscribe((res:any)=>{

      if(!expor){
      this.data=[];
      this.spinner.hide();
  
      this.data = res;
      return; 
      }

      window.open(res);
      this.spinner.hide();

    },error=>{
      this.spinner.hide();
    });
  }




  saveOR(pr_no, index){

    let data = {
      date: this.date,
      or_no: this.or_no,
      posting_type: this.posting_type,
      bill_id:pr_no
    }

    this.or.saveOR(data).subscribe(res=>{
      this.toastrService.success(res['msg'], 'Success!', this.options);

      this.display(1,false);

      this.or_no='';
      this.date='';

    },error=>{
      console.log(error)
    });
  }



  print_paid_bill(bill_id){
    this.spinner.show();
    this.productHis.print_paid_bill(bill_id).subscribe((res:any) => {
      this.spinner.hide();
      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "paid-bill-statement.pdf";
      link.click();

    },error=>{
      console.log(error)
    });
  }



  orIssuanceArea(){
    this.or.orIssuanceArea().subscribe(res=>{
      this.orIssuanceAreas= res;
    },error=>{
      console.log(error)
    });
  }


  pay_all(e, i){
    
    this.data.data[i].policies.forEach(row => {
      // row['checked']= e.checked==true? true:false;

      if(e.checked&&row.or_no==''){
        row['checked']=true;
      }
      else if(!e.checked&&row.or_no==''){
        row['checked']=false;
      }
      
    });

  }

  

}
