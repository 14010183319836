import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { UserService } from '../../services-http/user/user.service';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {

  user:any = {};
  userId = 0;
  coop:any = {};

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.params.id
    this.loadUserProfile();
  }

  goBack(){
    let coop_maincode = this.user['coop_maincode'];
    
    if(coop_maincode == '3020' || coop_maincode == 3020) {
      this.router.navigateByUrl('/admin/dashboard');
    }  
    else{
      this.router.navigateByUrl('/coop/dashboard');
    }
  }

  loadUserProfile() {
    this.userService.loadUser(this.userId).subscribe(res => {
      this.user = res;
    });
  }

}
