import { Component, OnInit,ViewChild } from '@angular/core';
import { PolicyExtensionService } from '../../../services-http/policy-extension/policy-extension.service';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { QuotationService } from '../../../services-http/quotation/quotation.service';

@Component({
  selector: 'app-policy-extension',
  templateUrl: './policy-extension.component.html',
  styleUrls: ['./policy-extension.component.scss']
})
export class PolicyExtensionComponent implements OnInit {
  @ViewChild('basicModal') basicModal!: ModalDirective;

  constructor(
      private policyExtension:PolicyExtensionService,    
      private fb: FormBuilder,
      private spinner: NgxSpinnerService,
      private datePipe: DatePipe,
      private quotation: QuotationService,
    ) { }

  headElements = ['','Quotation', 'Policy No', 'Product','Assured Name','Contact No.','Gross Premium','Net Premium','Effectivity Date', 'Expiry Date', 'Action'];
  headElements1 = ['Created Date','Policy No', 'Product','Assured Name','Address','Contact No.','Gross Premium','Net Premium','Effectivity Date', 'Expiry Date', 'Action'];
 
  searchText:any ='';
  details:any={};
  dialogIndex:any=0;

  policyExtensionList:any=[];
  basicprem:any=0;
  totalPrem:any=0;

  show:boolean=false;
  posting_type:any='';
  clicked:boolean=false;
  users = JSON.parse(localStorage.getItem('currentUser'));
  noOfDays:any=0;
  waived:any=0;

  lgt:any=0
  misc_fee:any=0
  doc_stamp:any=0
  vat:any=0
  comp_fee:any=0
  d_tech:any=0
  fst:any=0

  extendedList:any=[];
  points:any=[];



  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };


  filterForm = this.fb.group({
    date_to  : ['',Validators.required],
    date_from  : ['',Validators.required],
  });


  ngOnInit() {
    this.getpolicyExtensionList(1);
    this.checkpoints();
  }


  nextPage(){
    if(this.noOfDays<1){
      Swal.fire(
        'Oopss',
        'The inputed expiry date is less than the effective date.',
        'warning'
      );
      return
    }
    this.dialogIndex++;  
  }

  previousPage(){
    this.dialogIndex--;
  }


  getpolicyExtensionList(pageno){
    this.spinner.show();

    this.policyExtension.getpolicyExtensionList(pageno,this.searchText,this.users.iloginId ).subscribe(res=>{
      this.policyExtensionList=res;
      this.spinner.hide();

    },error=>{
      this.spinner.hide();
    });
  }

  total_waived_com:any=0;

  patch(details){
    this.details=details;

    // let date:any= (new Date()).getTime() + (60*60*24*1000);

    // console.log(this.details)
    let date:any= (new Date()).getTime();
   
    this.filterForm.patchValue({'date_from': this.datePipe.transform(date, 'yyyy-MM-dd')  });
    // this.getCommissionRate();

  }

      

  calculate(){
    var date1 = new Date(this.filterForm.value.date_to); 
    var date2 = new Date(this.filterForm.value.date_from); 
  
    var Time = date1.getTime() - date2.getTime(); 
    this.noOfDays= Time / (1000 * 3600 * 24); //Diference in Days

    this.basicprem=(this.details.paymentheader.grossPremium * this.noOfDays)/365;
 
    // get total commissioning
    if(this.users.iloginId=='5'){
      this.total_waived_com= this.total_waived_com=this.details.transaction.find(x=>x.transaction_type==5).credit/this.details.paymentheader.grossPremium * this.basicprem;
    }
    else{
      this.total_waived_com= this.total_waived_com=this.details.transaction.find(x=>x.transaction_type==6).credit/this.details.paymentheader.grossPremium * this.basicprem;
    }

    this.lgt       = this.details.payment_rate.filter(x=> x.paymentListNo =='5')[0].rate/100 *this.basicprem;
    this.misc_fee  = this.details.payment_rate.filter(x=> x.paymentListNo =='3')[0].rate;
    this.doc_stamp = this.details.payment_rate.filter(x=> x.paymentListNo =='1')[0].rate/100 *this.basicprem;
    this.vat       = this.details.payment_rate.filter(x=> x.paymentListNo =='4')[0].rate;
    this.comp_fee  = this.details.payment_rate.filter(x=> x.paymentListNo =='7')[0].rate;
    this.d_tech    = this.details.variantid==2? this.details.payment_rate.filter(x=> x.paymentListNo =='6')[0].rate:0;
    this.fst       = this.details.variantid==1? this.details.payment_rate.filter(x=> x.paymentListNo =='2')[0].rate/100 *this.basicprem:0;
  
    this.totalPrem = +this.basicprem+ +this.misc_fee + +this.doc_stamp + +this.vat + +this.comp_fee + +this.d_tech + +this.fst + +this.lgt;
  
  }


  savePolicyExtension(){
    this.clicked=true;
  
    this.spinner.show();
    let data:any={
      date_from:this.filterForm.value.date_from,
      date_to:this.filterForm.value.date_to,
      basic_prem:this.basicprem,
      total_prem:this.totalPrem,
      remarks:"extend policy",
      memo:"this is a policy extension",
      policyNo:this.details.policyno,
      endorsement_no: this.details.policyno+'-'+(+this.details.endorsement_count + 1),
      type:1,
      login_id:this.users.iloginId,
      variantId:this.details.variantid,
      posting_type:this.posting_type,
      email:this.users.username,
      waived:this.waived,
      paymentheader_old:this.details.paymentheader,
      transaction_old:this.details.transaction,
      // other charges
      lgt: this.lgt,
      misc_fee :this.misc_fee,
      doc_stamp :this.doc_stamp,
      vat: this.vat,
      comp_fee :this.comp_fee,
      d_tech :this.d_tech,
      fst: this.fst,
      waive_comm: this.total_waived_com
    }

     this.policyExtension.policyExtensionSave(data).subscribe((res)=>{
      this.basicModal.hide();
      this.clicked=false;
      this.spinner.hide();

      this.show=true;

      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );

    },error=>{
      this.clicked=false;
      this.spinner.hide();
   
      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Error',
        msg,
        'error'
      );
    });
  }



  policyExtendedList(pageno){
   
    this.policyExtension.policyExtendedList(this.users.iloginId,pageno,this.searchText).subscribe((res)=>{
    this.extendedList=res;
  
    },error=>{
      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Error',
        msg,
        'error'
      );
     
    });
  }


  policyExtendedListDownload(data){
    this.spinner.show();

    this.policyExtension.policyExtendedListDownload(data.endorsement_no).subscribe((res:Blob)=>{
     
        var blob = new Blob([res], {type: 'application/pdf'});
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "policy-extension-letter.pdf";
        link.click();
        this.spinner.hide();

      },error=>{
        this.spinner.hide();
      });
  }


  checkpoints(){
    this.policyExtension.checkpoints(this.users.iloginId).subscribe(res=>{
      this.points=res;
    })
  }


  // getCommissionRate(){
  //   this.quotation.syncCommissionPremiumByLoginType(this.users.iloginId, this.details.variantid ).subscribe(res=>{
   
  //     console.log(res);
  //   });
  // }





}
