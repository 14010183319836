import { Component, OnInit } from '@angular/core';
import { AdminReportsService } from '../../../services-http/admin-reports/admin-reports.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-migration-to-nonlife',
  templateUrl: './migration-to-nonlife.component.html',
  styleUrls: ['./migration-to-nonlife.component.scss']
})
export class MigrationToNonlifeComponent implements OnInit {
  data:any={data:[]};
  type:any = 'PM';
  export:boolean = false;

  constructor(
    private report: AdminReportsService, 
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {

    this.migrate_from_insurtech_to_nonlife(1);
  }

 
  migrate_from_insurtech_to_nonlife(page){
    this.spinner.show();

    let data = {
      type: this.type,
      page: page,
      export: false
    }

    this.report.migrate_from_insurtech_to_nonlife(data).subscribe((res:any) => {
      this.data = res;

      this.spinner.hide();
    },error=>{
      this.spinner.hide();
    });
  }



  migrate_from_insurtech_to_nonlife_export(page){
    this.spinner.show();

    let data = {
      type: this.type,
      page: page,
      export: true
    }

    this.report.migrate_from_insurtech_to_nonlife(data).subscribe((res:any) => {
      window.open(res)

      this.spinner.hide();
    },error=>{
      this.spinner.hide();
    });
  }

}
