import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrIssuanceService {
  url = environment.url;

  

  constructor(private http: HttpClient) { }

  display(data){

    return this.http.post(this.url + '/displayForOR?page='+data.page, data);
  }

  saveOR(data){

    return this.http.post(this.url + '/saveOR', data);
  }

  orIssuanceArea(){
    return this.http.get(this.url + '/orIssuanceArea');

  }

}
