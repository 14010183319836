import { Component, OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { AuthService } from '../../../services-http/auth/auth.service';
import { UserService } from '../../../services-http/user/user.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services-http/notification/notification.service'
import { ModalDirective } from 'ng-uikit-pro-standard';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-coop',
  templateUrl: './coop.component.html',
  styleUrls: ['./coop.component.scss']
})
export class CoopComponent implements OnInit,AfterViewInit {
  @ViewChild('basicModal') basicModal: ModalDirective;

  userType:any;
  coopName:any;
  user = JSON.parse(localStorage.getItem('currentUser'));
  envi = environment



  constructor(
    private authService: AuthService,
    private router: Router,
    private userService:UserService,
    public notification:NotificationService

  ) { }

  ngOnInit() {
    this.notification.getNotification(this.user.iloginId);
  }

  ngAfterViewInit() {

    if(this.user.checkunpayed){
      this.basicModal.show();
    }
  }

  navigate(p){
    if(p.variantid==3){
      return this.router.navigateByUrl('/coop/policy-issuance/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==1){
      return this.router.navigateByUrl('/coop/standard-fire/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==2){
      return this.router.navigateByUrl('/coop/comprehensive/quoteId/0/'+this.user.iloginId);
    }
    else if(p.variantid==56||p.variantid==60){
      return this.router.navigateByUrl('/coop/fire-issue/'+p.variantid+'/quoteId/0/'+this.user.iloginId);
    }

    this.router.navigateByUrl('/coop/CAC-products/'+p.variantid+'/quoteId/0/'+this.user.iloginId);
  }


  logout() {
    this.authService.logout();
  }
  
   
  gotoProfileView() {
    this.router.navigateByUrl('/coop/profile/' + this.user['userId'] + '/view');
  }


  notificationRead(notification){
    this.notification.notificationRead(notification).subscribe(res =>{
      this.notification.getNotification(this.user.iloginId);
    })
  }

  refresh(){
    this.notification.getNotification(this.user.iloginId);
  }

}
