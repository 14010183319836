import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IssuedPolicyCountService {
  url = environment.url; 

  constructor(private http: HttpClient) { }

  getCoopName(){
    return this.http.get(this.url + '/getCoopName' );
  }

  policySummary(year,exports){
    return this.http.get(this.url + '/api/policySummary?year='+year+'&export='+exports );
  }

  getProduct(coop_no){
    return this.http.get(this.url + '/getProduct/' +coop_no );
  }


  getProductResult(loginId, data){
    return this.http.post(this.url + '/getProductResult/'+loginId ,data  );
  }

  // getProductResultExport(loginId,data){
  //   return this.http.post(this.url + '/getProductResultExport/'+loginId ,data  );
  // }

  getGrossResult(coop_no,data){
    return this.http.post(this.url + '/getGrossResult/'+coop_no ,data  );
  }

  export(){
    return this.http.get(this.url + '/exports'  );
  }


}
