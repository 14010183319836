import { Component, OnInit } from '@angular/core';
import { IssuedPolicyCountService } from '../../../services-http/issued-policy-count/issued-policy-count.service';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-cac-issued-policy-count-report',
  templateUrl: './cac-issued-policy-count-report.component.html',
  styleUrls: ['./cac-issued-policy-count-report.component.scss']
})
export class CacIssuedPolicyCountReportComponent implements OnInit {

  selected:any; 
  customize_date = new Date("2019-01-01");
  from:any= this.customize_date.toISOString().substring(0, 10);

  mydate = new Date();
  to:any=this.mydate.toISOString().substring(0,10);

  product:any = [];

  optionsSelect:any = [];
  User:any = JSON.parse(localStorage.getItem('currentUser'));

  constructor( private issuedPolicy: IssuedPolicyCountService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.issuedPolicy.getProduct(this.User.coop_maincode).subscribe(res => {
      this.optionsSelect =res ;
    })
  }

  getProductResult(){
    this.spinner.show();

    if(this.selected){
  
      let data = {
        from: this.from,
        to: this.to,
        product: this.selected,
        export:0
      }

      this.issuedPolicy.getProductResult(this.User.iloginId, data).subscribe(res => {
        this.product = res;
        this.spinner.hide();

      })
    }
  }


  getProductResultExport(){
    this.spinner.show();
    if(this.selected){

      let data = {
        from: this.from,
        to: this.to,
        product: this.selected,
        export:1
      }

      this.issuedPolicy.getProductResult(this.User.iloginId, data).subscribe(res => {
    
        window.open(""+res )
        this.spinner.hide();

      })
    }

  }

}
