import { Component, OnInit,EventEmitter } from '@angular/core';
import { FeedbackService } from '../../../services-http/feedback/feedback.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-feedback-us',
  templateUrl: './feedback-us.component.html',
  styleUrls: ['./feedback-us.component.scss']
})
export class FeedbackUsComponent implements OnInit {

  formData = new FormData();
  message:any = "";
  User = JSON.parse(localStorage.getItem('currentUser'));


  constructor(private feedback:FeedbackService, private spinner: NgxSpinnerService,) { }


  ngOnInit() {
  }


  onFileSelected(event) {
    if(  this.formData.getAll('file').length !=0 ){
      alert('oopps. If you want to replace, remove previous screenshot!')
      return
    }
    this.formData.append('file', event.target.files[0], event.target.files[0].name);
    // console.log(this.formData.getAll('file'))
  }

  onFileDelete(){
    this.formData.delete('file');
  }

  startUpload(){
    this.spinner.show();

    let data ={
      message:this.message,
      loginId: this.User.iloginId
    }
    this.formData.append('data', JSON.stringify(data) );
   
    this.feedback.submitFeedback(this.formData).subscribe(res =>{
      this.spinner.hide();
      Swal.fire(
        'Success!',
        'Successfully saved feedback. Thank you.',
        'success'
      )

      this.message ='';
      this.onFileDelete();
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'failed!',
        'Failed to saved feedback!',
        'error'
      )
    }
    )
    

  }

}
