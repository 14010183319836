import { Component, OnInit } from '@angular/core';
import { PcrService } from '../../../services-http/pcr/pcr.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-pcr-dashboard',
  templateUrl: './pcr-dashboard.component.html',
  styleUrls: ['./pcr-dashboard.component.scss']
})
export class PcrDashboardComponent implements OnInit {

  constructor(
    private PCR:PcrService,
    private spinner: NgxSpinnerService
  ) { }

  collection_report:any = [];


  ngOnInit() {
    this.getPcrCollectionReport();
  }

  getPcrCollectionReport(){
    this.spinner.show();
    this.PCR.getPcrCollectionReport().subscribe(res=> {
      this.collection_report = res;
      this.spinner.hide();
    },error=>{
      this.spinner.hide();

    });
  }


 

}
