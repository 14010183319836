import { Component, OnInit,ViewChild } from '@angular/core';
import { CommissionPremiumSetupService } from '../../../services-http/commission-premium-setup/commission-premium-setup.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { ModalDirective,ToastService  } from 'ng-uikit-pro-standard';



@Component({
  selector: 'app-admin-user-commission',
  templateUrl: './admin-user-commission.component.html',
  styleUrls: ['./admin-user-commission.component.scss']
})
export class AdminUserCommissionComponent implements OnInit {
  @ViewChild('frame') frame: ModalDirective;
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('basicModal1') basicModal1: ModalDirective;

  constructor(
    private com:CommissionPremiumSetupService,
    private route: ActivatedRoute,
    private toastrService: ToastService
  ) { }

  code:number = this.route.snapshot.params['code'];
  // type:number = this.route.snapshot.params['type'];
  commission:any=[];
  user = JSON.parse(localStorage.getItem('currentUser'));
  benefit:any;
  headElements = ['','User Type', 'Product', 'coop code'];
  active: number;
  prod:any=[]; 
  options:any = { opacity: 0 };

  comPerProduct:any = []; 


  updateCommissionForm = new FormGroup({
    cac_code: new FormControl('', Validators.required),
    primary_id: new FormControl('', Validators.required),
    variantid: new FormControl('', Validators.required),
    product_id: new FormControl('', Validators.required),
    premium_rate_min: new FormControl('', Validators.required),
    premium_rate_max: new FormControl('', Validators.required),
    comm_rate_max: new FormControl('', Validators.required),
    benefit_name_id: new FormControl('', Validators.required),
    eff_date: new FormControl('', Validators.required),
    end_date: new FormControl('', Validators.required),
    com_id: new FormControl('', Validators.required)
  });


  compP:any;
  comPerProducts(type){
    let data = {
      variantid:this.commission[this.active].variantid,
      code:this.code,
      primary_id :type
    }

    this.com.comPerProducts(data).subscribe(res=>{
      this.compP =res;
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    })

  }


  onClick(index: number) {
    this.active = index;

    this.prod =this.commission[this.active];

    this.updateCommissionForm.patchValue({
      product_id: '',
      premium_rate_min: '',
      premium_rate_max: '',
      comm_rate_max: '',
      benefit_name_id: '',
      eff_date: '',
      end_date: '',
      com_id: '',
      primary_id: '',
      variantid:this.commission[this.active].varshortname,
      cac_code:this.code,
    });
  }



  onclickUser(p){

    this.updateCommissionForm.patchValue({
      primary_id: p.usertype.typeid +'-'+ p.usertype.typename,
      product_id:this.prod.variantid==1||this.prod.variantid==2||this.prod.variantid==3?'': p.product_id,
      premium_rate_min: this.prod.variantid==1||this.prod.variantid==2||this.prod.variantid==3?'': p.premium_rate_min,
      premium_rate_max: this.prod.variantid==1||this.prod.variantid==2||this.prod.variantid==3?'': p.premium_rate_max,
      comm_rate_max: this.prod.variantid==1||this.prod.variantid==2||this.prod.variantid==3?'': p.comm_rate_max,
      eff_date: this.prod.variantid==1||this.prod.variantid==2||this.prod.variantid==3?'': p.eff_date,
      end_date: this.prod.variantid==1||this.prod.variantid==2||this.prod.variantid==3?'': p.end_date,

      benefit_name_id:0,
      com_id: p.prodcomm_id
    });


    this.comPerProducts(p.usertype.typeid)
  }
  
  

  ngOnInit() {
    this.display();

    this.updateCommissionForm.get("variantid").valueChanges.subscribe(x => {
      this.updateCommissionForm.get("com_id").clearValidators();
      this.updateCommissionForm.get("benefit_name_id").clearValidators();

     
      if(x=='COMPREHENSIVE'||x=="STANDARD FIRE"||x=='CTPL'){
        this.updateCommissionForm.get("com_id").setValidators([Validators.required]);
        this.updateCommissionForm.get("benefit_name_id").setValidators([Validators.required]);
      }
      this.updateCommissionForm.updateValueAndValidity();

    });
  }


  display(){
    let data ={
      code: this.code,
      type: this.user.usertype==1 || this.user.usertype==8 || this.user.usertype==12?  [2,3,4,5,6]: [this.user.usertype]
    }

    this.com.displayCom(data).subscribe(res=>{
      this.commission = res;
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }


  getSpecificCom(){

    this.updateCommissionForm.patchValue({
      premium_rate_min: '',
      premium_rate_max: '',
      comm_rate_max: '',
      com_id:''
    });

    let data ={
      primary_id: (this.updateCommissionForm.value.primary_id).substring(0, 1),
      product_id: this.updateCommissionForm.value.product_id,
      benefit_name_id: this.updateCommissionForm.value.benefit_name_id,
      variantid: this.commission[this.active].variantid,
      cac_code: this.code,
    }

    this.com.getSpecificCom(data).subscribe(res=>{
      this.benefit = res;
  
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }


  getBenefit(){
    let x = this.benefit.find(x=>x.prodcomm_id == this.updateCommissionForm.value.com_id);

    this.updateCommissionForm.patchValue({
      premium_rate_min: x.premium_rate_min,
      premium_rate_max: x.premium_rate_max,
      comm_rate_max: x.comm_rate_max,
      eff_date: x.eff_date,
      end_date: x.end_date,
    });
  }



  save(){
    let data = {
      prodcomm_id: +this.updateCommissionForm.value.com_id,
      premium_rate_min: this.updateCommissionForm.value.premium_rate_min,
      premium_rate_max: this.updateCommissionForm.value.premium_rate_max,
      comm_rate_max: this.updateCommissionForm.value.comm_rate_max,
      eff_date: this.updateCommissionForm.value.eff_date,
      end_date: this.updateCommissionForm.value.end_date
    }

    this.com.saveCom(data)
      .subscribe(res=>{
      this.toastrService.success(res['msg'], 'Success!', this.options);

      this.updateCommissionForm.patchValue({
        premium_rate_min: '',
        premium_rate_max: '',
        comm_rate_max: '',
        benefit_name_id: '',
        eff_date: '',
        end_date: '',
        com_id: ''
      });

    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }



  delete_com(){

    this.com.delete_com(this.updateCommissionForm.value.com_id).subscribe(res=>{
      this.frame.hide();

      this.toastrService.success(res['msg'], 'Success!', this.options);

      this.updateCommissionForm.patchValue({
        product_id: '',
        premium_rate_min: '',
        premium_rate_max: '',
        comm_rate_max: '',
        benefit_name_id: '',
        eff_date: '',
        end_date: '',
        com_id: ''
      });

    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }




  create_comm(){
    let data={
      premium_rate_min: this.updateCommissionForm.value.premium_rate_min,
      premium_rate_max: this.updateCommissionForm.value.premium_rate_max,
      comm_rate_min: 0,
      comm_rate_max: this.updateCommissionForm.value.comm_rate_max,
      benefit_name_id: this.updateCommissionForm.value.benefit_name_id == ''?0:this.updateCommissionForm.value.benefit_name_id,
      prodcomm_type: '2', 
      product_id: this.updateCommissionForm.value.product_id, 
      eff_date: new Date(), 
      end_date: '2025-01-01', 
      coverage_from: '0', 
      coverage_to: '100000', 
      cac_code: this.code, 
      variantid: this.commission[this.active].variantid,
      primary_id: (this.updateCommissionForm.value.primary_id).substring(0, 1)
    };

    this.com.create_comm(data).subscribe(res=>{

      this.toastrService.success(res['msg'], 'Success!', this.options);

      this.updateCommissionForm.patchValue({
        product_id: '',
        premium_rate_min: '',
        premium_rate_max: '',
        comm_rate_max: '',
        benefit_name_id: '',
        eff_date: '',
        end_date: '',
        com_id: ''
      });
    },error=>{
      Swal.fire(
        'Oopps',
        'Something went wrong.',
        'error'
      );
    });
  }



}
