import { Component, OnInit } from '@angular/core';
import { AdminReportsService } from '../../../../services-http/admin-reports/admin-reports.service';
import {MenuItem} from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-user-transacting',
  templateUrl: './user-transacting.component.html',
  styleUrls: ['./user-transacting.component.scss']
})
export class UserTransactingComponent implements OnInit {


  data:any = [];
  dataChart = [];
  items: MenuItem[];
  activeItem: MenuItem;
  chart:any =[];
  headElements = ['YEAR','Total User w/ Transactions','January', 'February ', 'March', 'April','May','June','July','August','September','October','November','December'];
  todaysMonth = (new Date().getMonth() + 1).toString() ;
  show:boolean = true;
  options = {
    responsive: false,
    maintainAspectRatio: false
  };
  
  constructor(
    private report: AdminReportsService,
    private spinner: NgxSpinnerService
    ) { }


  ngOnInit() {
    setTimeout(() => this.spinner.show(), 0); 

    this.items = [
      {label: 'Statistics', icon: 'pi pi-table',command: e =>  this.show = true  },
      {label: 'Chart', icon: 'pi pi-chart-bar', command: e =>  this.show = false },
    ];

    this.activeItem = this.items[0]
    this.chart = [];

    this.report. UserTransacting().subscribe(res=>{
      
      this.data =res;
      this.spinner.hide();
      for (let index = 0; index < this.data.length; index++) {

        let data:any = {
          label: this.data[index].years,
          data:[
            this.data[index].jan,
            this.data[index].feb,
            this.data[index].march,
            this.data[index].april,
            this.data[index].may,
            this.data[index].june,
            this.data[index].july,
            this.data[index].august,
            this.data[index].sept,
            this.data[index].octo,
            this.data[index].nov,
            this.data[index].decem,
          ],
          fill:false,
          borderColor: this.generateColor()
        }
      
        this.dataChart.push(data);

       
      }

      this.chart = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'],
        datasets: this.dataChart
      }

    })
  }


  generateColor(){
    let color:any = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    return color;
  }


}
