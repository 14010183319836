import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminRemittanceManagementService {
  url = environment.url;

  constructor(private http: HttpClient) { }

  upload_remittance_details(remittance_details){
    return this.http.post(this.url + '/uploadRemittance_details', remittance_details);
  }

  upload_remittance(remittance_data,remit_id){
    return this.http.post(this.url + '/uploadRemittance/'+remit_id, remittance_data);
  }

  getRemittances(filter,coopno ){
    return this.http.post(this.url + '/getRemittances/'+coopno, filter);
  }

  getCoopnum(coopNum){
    return this.http.get(this.url + '/getCoopnum/'+coopNum );
  }

  getProduct(coopnum){
    return this.http.get(this.url + '/getProduct/'+coopnum  );
  }

  delete_remittance(remittance_id){
    return this.http.get(this.url + '/delete_remittance/'+remittance_id  );
  }

  generate_remittance_report(date){
    return this.http.post(this.url + '/generate_remittance_report',date );
  }

  update_remittance(remittance_id, remittance_update){
    return this.http.post(this.url + '/update_remittance/'+remittance_id ,remittance_update );
  }

  // coop side
  getCoopRemittances(coopCode){
    return this.http.get(this.url + '/getCoopRemittances/'+coopCode );
  }

  getCoopInfo(coopCode){
    return this.http.get(this.url + '/getCoopInfo/'+coopCode );
  }

  getUpload(remittance_id){
    return this.http.get(this.url + '/getUpload/' + remittance_id );

  }

  getReferenceNum(text_info){
    return this.http.post(this.url + '/getReferenceNum',text_info );
    
  }


  product_list(){
    return this.http.get(this.url + '/product_list' );

  }

  get_branch(coopno){
    return this.http.get(this.url + '/remittance_branch/'+coopno );

  }

  getProductListPerCoop(coop_no){
    return this.http.get(this.url + '/getProductListPerCoop/'+coop_no );
  }


  updateProductAssign(data){
    return this.http.post(this.url + '/updateProductAssign',data );
  }

  getCoop(coop_name){
    return this.http.get(this.url + '/getCoop/'+coop_name );
  }


}
