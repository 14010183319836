import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services-http/user/user.service';
import { FormControl,FormGroup } from '@angular/forms';
import {formatDate} from '@angular/common';
import { SweetAlertService } from  '../../services-components/sweet-alert/sweet-alert.service'

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {
  user:any = {};
  User = JSON.parse(localStorage.getItem('currentUser'));
  username =new FormControl('');
  name =new FormControl('');

  constructor(private userService:UserService, private sweetAlertService:SweetAlertService) { }

  ngOnInit() {
    this.userService.loadUser(this.User.userId).subscribe(res => { 
      this.user = res
      this.name.setValue(this.user.pkid_name)
      this.username.setValue(this.user.username)
    })
  }

  updateUser(){
    let data = {
      username : this.username.value,
      name: this.name.value,
      date_updated: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
      coop_maincode: this.User.coop_maincode,
      usertype: this.User.usertype,
      address: this.User.address,
      contact: this.User.contact_no
    }
    this.userService.updateUser(this.User.userId,data).subscribe(res=>{
      // console.log(res)
      let type = 'success';
      let title = 'Successful';
      let text = 'Your information successfully saved!';
      let footer = '';
      this.sweetAlertService.showAlert(type, title, text, footer);
    })


  }

}
