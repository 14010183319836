import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  url = environment.url; 

  constructor(private http: HttpClient) { }

  submitClaims(claimsInfo){
    return this.http.post(this.url + '/submitClaims',claimsInfo );
  }

  monitoringClaims(coop_maincode){
    return this.http.get(this.url + '/monitoringClaims/'+coop_maincode);
  }

  monitoringAdminClaims(){
    return this.http.get(this.url + '/monitoringAdminClaims');
  }

  upload_files(files,claims_id){
    return this.http.post(this.url + '/uploadFiles/'+claims_id,files );
  }

  filedownload(claims_id){
    return this.http.get(this.url + '/filedownload/'+claims_id );
  }
  
  searchPolicyNum(policyNum){
    return this.http.get(this.url + '/searchPolicyNum/'+policyNum );
  }

  removeClaims(claims_id){
    return this.http.get(this.url + '/removeClaims/'+claims_id);

  }


  
}
