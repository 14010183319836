import { Component, OnInit,ViewChild } from '@angular/core';
import { IssuePolicyService } from '../../../services-http/issue-policy/issue-policy.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { BillingService } from '../../../services-http/billing/billing.service';
import { Router,ActivatedRoute } from '@angular/router';
import { CacProductService} from '../../../services-http/cac-product/cac-product.service';


@Component({
  selector: 'app-issued-policy-quotation',
  templateUrl: './issued-policy-quotation.component.html',
  styleUrls: ['./issued-policy-quotation.component.scss']
})
export class IssuedPolicyQuotationComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('basicModal1') basicModal1: ModalDirective;
  @ViewChild('frame') frame: ModalDirective;


  constructor(
    private issuedpolicy:IssuePolicyService,
    private spinner: NgxSpinnerService,
    private productHis:ProductionHistoryService,
    private bill:BillingService,
    private route: ActivatedRoute,
    private router: Router,
    private cac:CacProductService,

  ) { }

  users = JSON.parse(localStorage.getItem('currentUser'));
  IssuedPolicy:any = [];
  email:any;
  quoteId:any;
  filter:string ='policy';
  searchText:any = "";
  optionsSelect:any = [];
  quoteDetail:any;
  points:number = 0;
  url=this.router.url;
  furl;
  points_credit:number=0;
  posting_type:any='';
  clicked:boolean=false;
  math = Math;
  branch: any=this.users.iloginId;
  branches:any =[];


  ngOnInit() {
    this.furl=this.url.split('/')[1];

    this.email = this.users['username'];
    this.getIssuedPolicy();
    this.selectBranch();
  }

  getIssuedPolicy(){

    setTimeout(() => this.spinner.show(), 0);
    this.issuedpolicy.issuedPolicyQuote(this.users['iloginId'],{agentNo: this.branch}).subscribe(res=>{
      this.spinner.hide();
      this.IssuedPolicy=res;

    },error=>{
      this.spinner.hide();
    });
  }


  totalCommission(b){

    let cac = b.reduce((sumAccessories, current) => +sumAccessories + +current.comm_cac, 0);
    let agent = b.reduce((sumAccessories, current) => +sumAccessories + +current.commission, 0);
    let gam = b.reduce((sumAccessories, current) => +sumAccessories + +current.comm_gam, 0);

    return  {agent:agent, other: this.users.usertype==3?gam: this.users.usertype==4||this.users.usertype==6? cac: 0.00 }
  }


  sendEmailQuotation(){
    this.spinner.show();
    let data:any={
      quoteId: this.quoteId,
      email:  this.email,
      text: 0
    }

    this.issuedpolicy.sendEmailQuotation(data).subscribe(res=>{
      if(res){
        this.spinner.hide()
        this.basicModal.hide();
        Swal.fire(
          'Success',
          'Email successfully sent!',
          'success'
        );
      }
    })
  }


  getQuoteId(quotId){
    this.quoteId = quotId;
  }


  cancelQuote(quoteId, i){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!'
    }).then((result) => {
      if (result.value) {
       
        this.spinner.show();
        this.issuedpolicy.cancelQuote( quoteId ).subscribe(res=>{
          let index = this.IssuedPolicy.findIndex(x => x.id === quoteId);
          this.IssuedPolicy.splice(index, 1)
          this.spinner.hide();
        },error=>{
          this.spinner.hide()
    
          let err=error.error;
          let msg='';
    
          Object.keys(err).forEach(function(key){
            msg+=err[key]+'<br/>';
          });
    
          Swal.fire(
            'Error',
            msg,
            'error'
          );
    
        });
      }
    })
  }


  // quotationDetails(quoteId){
  //   this.spinner.show();
  //   this.issuedpolicy.quotationDetails(quoteId).subscribe(res=>{

  //     this.quoteDetail = res;
  //     this.spinner.hide();
  //   },error=>{
  //   this.spinner.show();

  //   })
  // }



  selectBranch(){
    this.issuedpolicy.selectBranch(this.users.iloginId).subscribe(res=>{
  
      this.branches =res
    },error=>{
      console.log(error)
    });
  }




  applyforPolicy(){
    this.clicked=true;
    this.spinner.show();

    let data = {
      quotationno: this.quoteDetail.quotationno,
      email:this.users.username,
      posting_type:this.posting_type,
      netPremium: this.quoteDetail.paymentheader.netPremium,
      agentId: this.quoteDetail.agentNo,
      variantId: this.quoteDetail.variantid,
      waive_comm:this.quoteDetail.paymentheader.waiveComm,
      assuredName:this.quoteDetail.assured.assuredName
    }
    
    this.issuedpolicy.applyforPolicy(data).subscribe(res=>{
      this.clicked=false;
      this.spinner.hide()
      this.frame.hide();
      this.router.navigate(['/'+this.furl+'/policy-issued']);
   
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
      // this.cac.sendEmailPA({quotId:this.quoteDetail[0].id, email:0}).subscribe(res=>{
      
      // },error=>{
      //   this.spinner.hide()
      // });  

    },error=>{
      this.clicked=false;
      this.spinner.hide()

      let err=error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key]+'<br/>';
      })

      Swal.fire(
        'Error',
        msg,
        'error'
      );

    })
  }


  Download_quote(quoteId){
    this.spinner.show();

    this.issuedpolicy.print_quote_wo_saving({quoteId:quoteId}).subscribe((res:any)=>{
      this.spinner.hide();

      var blob = new Blob([res], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "quotation.pdf";
      link.click();

    },error=>{
      this.spinner.hide();
    });
    
  }



  renewPolicy(policy){

    if(policy.variantid==56||policy.variantid==60){
      return this.router.navigate(['/'+this.furl+'/fire-issue/'+policy.variantid+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    else if(policy.variantid==69|| policy.variantid==70){
      return this.router.navigate(['/'+this.furl+'/surity-bond/'+policy.variantid+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    else if(policy.variantid==3){
      return this.router.navigate(['/'+this.furl+'/policy-issuance'+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    else if(policy.variantid==2){
      return this.router.navigate(['/'+this.furl+'/comprehensive'+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    else if(policy.variantid==1){
      return this.router.navigate(['/'+this.furl+'/standard-fire'+'/quoteId/'+policy.id+'/'+policy.agentNo]);
    }

    this.router.navigate(['/'+this.furl+'/CAC-products/'+policy.variantid+'/quoteId/'+policy.id+'/'+policy.agentNo]); 
  }




  
}
