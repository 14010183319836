import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PolicyExtensionService {
  url = environment.url;

   constructor(
    private http: HttpClient,
  ) { }



  getpolicyExtensionList(pageno,search,loginId) {
    return this.http.get(this.url + '/policyExtension?page='+pageno+'&search='+search+'&loginId='+loginId ).pipe(map(res => {
      return res;
    }));
  }


  policyExtensionSave(data){
    return this.http.post(this.url + '/policyExtensionSave',data);
  }


  policyExtendedList(loginId,pageno,searchText){
    return this.http.get(this.url + '/policyExtendedList?loginId='+loginId+'&page='+pageno+'&search='+searchText);
  }


  policyExtendedListDownload(policyNo){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    
    return this.http.get(this.url + '/policyExtendedListDownload/'+policyNo,httpOptions);

  }

  checkpoints(loginId){
    return this.http.get(this.url + '/getPoints/'+loginId);
  }



}
