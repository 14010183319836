import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RenewalNoticeService {

  url = environment.url;
  
  constructor(
    private http: HttpClient
  ) { }


  renewalNoticeList( filterRenewal){
    return this.http.post(`${this.url}/renewalNoticeList`, filterRenewal);
  }

  download_bulk( filterRenewal){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };

    return this.http.post(`${this.url}/renewalNoticeList`, filterRenewal, httpOptions );
  }

  coopList(){
    return this.http.get(`${this.url}/coopList`);
  }

  sendRenewalNotice(policyno){
    return this.http.get(`${this.url}/sendRenewalNotice/`+policyno);
  }

  download_renewal_notice(policyno){
    const httpOptions = {
      responseType: ('blob' as 'json')
    };
    
    return this.http.get(this.url + '/RenewalNotice/' +policyno, httpOptions );
  }

}
