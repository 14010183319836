import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  showAlert(atype, atitle, atext, afooter) {
    Swal.fire({
      type: atype,
      title: atitle,
      text: atext,
      footer: afooter
    })
  }

}
