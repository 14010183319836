import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services-http/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PolicyExtensionService } from '../../../services-http/policy-extension/policy-extension.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-under-agent-list',
  templateUrl: './under-agent-list.component.html',
  styleUrls: ['./under-agent-list.component.scss']
})
export class UnderAgentListComponent implements OnInit {

  constructor(
    private user:UserService,
    private spinner: NgxSpinnerService,  
    private policyExtension:PolicyExtensionService, 
    private router: Router,   
    ) { }

  agents:any = [];
  users = JSON.parse(localStorage.getItem('currentUser'));
  searchText:any ='';
  points:any={};
  url=this.router.url;
  furl;

  ngOnInit() {
    this.furl=this.url.split('/')[1];

    setTimeout(() => this.spinner.show(), 0);

    this.user.getAgentUnderLoginId(this.users.usertype==15?this.users.agentUnder : this.users.iloginId)
      .subscribe(res=>{
        this.agents =res;
        this.spinner.hide();
    });
    this.checkpoints();
  }


  checkpoints(){
    this.policyExtension.checkpoints(this.users.usertype==15?this.users.agentUnder : this.users.iloginId)
      .subscribe(res=>{
        this.points=res;
    });
  }


  redirect(val){
    if(val==1){
      this.router.navigate(['/'+this.furl+'/billing']);
    }
    else if(val==0){
      this.router.navigate(['/'+this.furl+'/cac-wallet']);
    }
  }

}
