import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services-http/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-wallet-balance-per-user',
  templateUrl: './wallet-balance-per-user.component.html',
  styleUrls: ['./wallet-balance-per-user.component.scss']
})
export class WalletBalancePerUserComponent implements OnInit {

  constructor(   
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() { 
    this.loadUsers(1,0);
  }

  searchUserText:any = '';
  users:any = [];
  walletType:any=0;


  loadUsers(pageno,exp) {
    this.userService.loadUsers(pageno, this.searchUserText,exp).subscribe((res:any) => {

      if(exp==0){
        this.users=res;
      }

      else{
        window.open(res);
      }

    },error=>{
      this.spinner.hide();
    });
  }
}
