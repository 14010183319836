import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError,BehaviorSubject } from 'rxjs';
import { filter, take, catchError,switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService} from '../../services-http/user/user.service';
import { AuthService } from'../../services-http/auth/auth.service';
// import { ToastService } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2/src/sweetalert2.js'
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenInProgress:boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
   

    constructor(
        private router: Router,
        private user:UserService,
        private auth:AuthService,
        private spinner: NgxSpinnerService
        // private toastrService: ToastService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
            }else if (error instanceof HttpErrorResponse && error.status === 406) {
                this.spinner.hide();
                this.auth.logout();
            }else if (error instanceof HttpErrorResponse && error.status === 0) {
                this.spinner.hide();
                Swal.fire(
                    'Warning',
                    'No Internet connection, Please try again later !',
                    'warning'
                );
            }
            return throwError(error);
        }))
    }


     handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            let  token:any = JSON.parse(localStorage.getItem('currentUser'))
            console.log("refreshing...")
       
             this.user.refresh_token({token: token.refresh_token}).then(res=>{
                localStorage.setItem('currentUser', JSON.stringify(res));  
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(true);     
                return next.handle(this.injectAuthToken(request)); 
            },error=>{
                this.refreshTokenInProgress = false;
            });
        } 

        return this.refreshTokenSubject.pipe(
            filter(value => value != null),
            take(3),
            switchMap(res => {
                return next.handle(this.injectAuthToken(request));
            })
        );   
        
    }


    injectAuthToken(request: HttpRequest<any>) {
        let token:any = JSON.parse(localStorage.getItem('currentUser'))
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token.access_token}`
            }
        });
    }


    
}
